import { useEffect, useState } from "react";
import { Icon } from "../Icon";

const Sort = ({
  sort = 'desc',
  sortOptions = [],
  sortOption = 'createdAt',
  onChange = () => {},
  page = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    if (localStorage.getItem(page)) {
      onChange(JSON.parse(localStorage.getItem(page)))
    }
  }, [page, onChange])

  return (
    <div
      className="filterBlockSortValue prFlexRow"
    >
        <div
          className={sort=='asc'?'norm':'pervert'}
          onClick={() => onChange({id: sortOption, sort: sort == 'asc' ? 'desc' : 'asc'})}
        >
          <Icon type="filterArrow" />
        </div>
        <div
          onClick={() =>  {
            onChange({id: sortOption, sort: sort == 'asc' ? 'desc' : 'asc'})
          }}
        >
          {sortOptions.find( option => option.id == sortOption)?.value}
        </div>
        <div
          className="pointer" 
          onClick={toggleMenu}
        >
          <Icon type="selectArrow"/>
        </div>
        {isOpen && (
          <ul className={`multiselectOptions box-shadow`}>
            {
              sortOptions.length > 0 && sortOptions.map(option => (
                <li
                  key={option.id}
                  className="multiselectOption"
                  onClick={() => {
                    onChange({id: option.id, sort: sort == 'asc' ? 'desc' : 'asc'})
                    setIsOpen(false);
                  }}
                >
                  {option.value}
                </li>
              ))
            }
          </ul>
      )}
    </div>
  )
}

export default Sort;