import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Hint from "../../components/Hint/Hint";
import { Icon } from "../../components/Icon";
import WarningModal from "../../components/WarningModal/WarningModal";
import { addDictionariesItem, deleteDictionariesItem, editDictionariesItem, getFullList } from "../../redux/actions/dictionaries";


const DirectoryPage =()=>{
    const { t } = useTranslation();
    const [open,setOpen]=useState('');
    const [edit,setEdit]=useState('');
    const [deleteItem,setDelete]=useState('');
    const [addedNew,setAddedNew]=useState('');
    const [form,setForm]=useState('');
    const fullList = useSelector((state) => state.fullList);
    const hint = useSelector((state) => state.hints);
    
    const dispatch=useDispatch();

    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value });
      };

    const openClick=(e)=>{
        if(e.currentTarget.id==open){
            setOpen('')
        }else{
            setOpen(e.currentTarget.id)
        }
    }
    const saveClick=(e)=>{
        let data={
            id:e.currentTarget.id,
            type:open,
            title:form[e.currentTarget.id]
        }
        dispatch(editDictionariesItem(data)).then(res=>{
            dispatch(getFullList()).then(res=>{
                setEdit('');
            })
        })
    }


    const editClick=(e)=>{
        setEdit(e.currentTarget.id)
    }
    const deleteClick=(e)=>{
        setDelete(e.currentTarget.id)
    }
    const createClick=(e)=>{
        setAddedNew(open);
    }
    const addClick =(e)=>{
        let data={
            type:open,
            title:form[open]
        }
        dispatch(addDictionariesItem(data)).then(res=>{
            dispatch(getFullList()).then(res=>{
                setAddedNew('');
            })
        }).catch(e=>{

        })
    }
    const deleteApprove =()=>{
        let data={
            type:open,
            id:deleteItem
        }
        dispatch(deleteDictionariesItem(data)).then(res=>{
            dispatch(getFullList()).then(res=>{
                setDelete('');
            })
        }).catch(e=>{

        })
    }
    
    return(<>
        <div className="profileSection" style={{marginBottom:'0px'}}>
            <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
                <h2 >{t('directory')}
                    <div className="infoHelp"> <Hint align='left' item={hint[31]}/></div>

                </h2>
                {/* <button className="prButton prButtonMain" >{t("users_create")}</button> */}
                {/* <a className="profileSectionHeaderLink link">{t('users_all')}<Icon type='rightarrow'/></a> */}
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='room-status' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='room-status'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>RoomStatus</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="room-status"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='room-status' && addedNew=='room-status' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'room-status'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='room-status' &&
                    fullList['RoomStatus'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                                {edit==el.id?
                                <>
                                    <div className="directoryElementText">
                                        <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={saveClick}>
                                            <Icon type='accept'></Icon>
                                        </div>
                                        <div className="prIconButton" onClick={()=>setEdit('')} >
                                            <Icon type='close'></Icon>
                                        </div>
                                    </div>
                                </>: 
                                    <>
                                    <div className="directoryElementText">
                                        {el.title}
                                        </div>
                                        <div className="prFlexRow directoryElementButton">
                                            <div className="prIconButton" id={el.id} onClick={editClick}>
                                                <Icon type='edit'></Icon>
                                            </div>
                                            <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                                <Icon type='close'></Icon>
                                            </div>
                                    </div>
                                </>}
                            </div>
                        )
                    })
                    }
            </div>


            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='sex' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='sex'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>Sex</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="sex"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='sex' && addedNew=='sex' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'sex'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='sex' &&
                    fullList['Sex'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                    }
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='user-roles' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='user-roles'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>UserRoles</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="user-roles"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='user-roles' && addedNew=='user-roles' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'user-roles'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='user-roles' &&
                    fullList['UserRoles'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                    }
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='transaction-status' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='transaction-status'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>TransactionStatus</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="transaction-status"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='transaction-status' && addedNew=='transaction-status' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'transaction-status'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='transaction-status' &&
                    fullList['TransactionStatus'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                    }
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='user-status' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='user-status'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>UserStatus</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="user-status"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='user-status' && addedNew=='user-status' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'user-status'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='user-status' &&
                    fullList['UserStatus'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                    }
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='platform' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='platform'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>Platform</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="platform"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='platform' && addedNew=='platform' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'platform'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='platform' &&
                    fullList['Platform'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                    }
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='ResultGame' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='ResultGame'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>ResultGame</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="ResultGame"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='ResultGame' && addedNew=='ResultGame' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'ResultGame'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='ResultGame' &&
                    fullList['ResultGame'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                    }
            </div>


            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='Formats' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='Formats'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>Formats</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="Formats"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='Formats' && addedNew=='Formats' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'Formats'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='Formats' &&
                    fullList['Formats'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                    }
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='Department' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='Department'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>Department</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="Department"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='Department' && addedNew=='Department' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'Department'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='Department' &&
                    fullList['Department'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                }
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='Priority' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='Priority'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>Priority</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="Priority"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='Priority' && addedNew=='Priority' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'Priority'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='Priority' &&
                    fullList['Priority'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                }
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='UserStatus' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='UserStatus'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>UserStatus</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="UserStatus"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='UserStatus' && addedNew=='UserStatus' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'UserStatus'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='UserStatus' &&
                    fullList['UserStatus'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                }
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='TypeDocument' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='TypeDocument'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>TypeDocument</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="TypeDocument"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='TypeDocument' && addedNew=='TypeDocument' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'TypeDocument'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='TypeDocument' &&
                    fullList['TypeDocument'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>: 
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                }
            </div>

            <div>
                <div className="prFlexRow directoryGroup">
                    <div id='timeZone' onClick={openClick} className="prFlexRow directoryGroupName">
                        <div className={open=='timeZone'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                            <Icon type='arrowForward'></Icon></div>
                                <div>TimeZone</div></div>
                    <div><button onClick={createClick} className="prButton prButtonMain prButtonMainIcon addButton" id="TypeDocument"><Icon type='plus'></Icon> Додати елемент</button></div>
                </div>
                {open=='timeZone' && addedNew=='timeZone' && 
                <div className="directoryElememt prFlexRow">
                    <div className="directoryElementText">
                        <input className="prInputInput" id={'timeZone'}  onChange={changeHandler} placeholder="Назва елементу" type="text" />
                    </div>
                    <div className="prFlexRow directoryElementButton">
                        <div className="prIconButton"  onClick={addClick}>
                            <Icon type='accept'></Icon>
                        </div>
                        <div className="prIconButton" onClick={()=>setAddedNew('')} >
                            <Icon type='close'></Icon>
                        </div>
                    </div>
                </div>
                }
                {open=='timeZone' &&
                    fullList['TimeZone'].map(el=>{
                        return(
                            <div key={el.id} className="directoryElememt prFlexRow">
                            {edit==el.id?
                            <>
                                <div className="directoryElementText">
                                    <input className="prInputInput" value={form[el.id]||el.title} id={el.id} onChange={changeHandler} placeholder="Назва елементу" type="text" />
                                </div>
                                <div className="prFlexRow directoryElementButton">
                                    <div className="prIconButton" id={el.id} onClick={saveClick}>
                                        <Icon type='accept'></Icon>
                                    </div>
                                    <div className="prIconButton" onClick={()=>setEdit('')} >
                                        <Icon type='close'></Icon>
                                    </div>
                                </div>
                            </>:
                                <>
                                <div className="directoryElementText">
                                    {el.title}
                                    </div>
                                    <div className="prFlexRow directoryElementButton">
                                        <div className="prIconButton" id={el.id} onClick={editClick}>
                                            <Icon type='edit'></Icon>
                                        </div>
                                        <div className="prIconButton"id={el.id} onClick={deleteClick}>
                                            <Icon type='close'></Icon>
                                        </div>
                                </div>
                            </>}
                        </div>
                        )
                    })
                }
            </div>
            
        </div>
        {deleteItem?.length>0 &&
            <WarningModal warningClick={deleteApprove} warningText={'are_you_sure_delete'} warningButton={'delete'} closeClick={()=>setDelete('')}></WarningModal>
        }
    </>)
}

export default DirectoryPage;