import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Filter from "../../../components/Filter/Filter";
import Hint from "../../../components/Hint/Hint";
import { Icon } from "../../../components/Icon";
import Pagination from "../../../components/Pagination/Pagination";
import getDate from "../../../helpers/getDate";

import { getNotifications, editNotification } from "../../../redux/actions/notifications";

const AdminReqPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ready, setReady] = useState(false);
  const { t } = useTranslation();
  const [listRes, setListRes] = useState({});
  const [filter, setFilter] = useState({});
  const [filterApplying, setFilterApplying] = useState(false);
  const dispatch=useDispatch();
  const hint = useSelector(state=>state.hints);
  const user = useSelector(state => state.currentUser?.user);
  const [pageCur, setPageCur] = useState(1);

  const navigate =useNavigate();
  
  const changeHandler = (e) => {
    const { id, value } = e.target;
    let updatedFilterCreate = { ...filter};

    if (e.target.tagName === 'SELECT' && e.target.accessKey) {
        updatedFilterCreate[e.target.accessKey] = value;
    }
    else if (id === "dateFrom" || id === "dateTo") {
      updatedFilterCreate[id] = getDate(value, "yyyy/mm/dd", "-");
    } 
    else if (value && value.length<1) {
        delete updatedFilterCreate[id];
    }
    else {
        updatedFilterCreate[id] = value;
    }
    setFilter(updatedFilterCreate);
  };

  const onSetResponsible = (req) => {
    const data = {};
    data.id = req.id;
    data.responsible = user.id;
    data.status = '1';

    dispatch(editNotification(data))
      .then( res => {
        initFunc();
      })
      .catch( error => {
        console.log('err:r ', error);
      })
  }

  const onDone = (req) => {
    dispatch(editNotification({ id: req.id, status: '2'}))
    .then( res => {
      initFunc();
    })
    .catch( error => {
      console.log('err: ', error);
    })
  }

  // const selectClick=(e)=>{
  //   e.preventDefault();
  //   let arr = [];

  //   if( filterCreate.platform?.length>0){
  //       arr=[...filterCreate.platform]
  //   }
  //   let el = e.target.id;
  //   if (arr.indexOf(el) !== -1) {
  //       arr = arr.filter(item => item !== el);
  //   } else {
  //       arr.push(el);
  //   }
  //   setFilterCreate({
  //       ...filterCreate,
  //       platform: arr
  //   });
  // }
  const resetFilter=()=>{
    setFilter({});
    setFilterApplying(true);
  }

  const applyFilters = () => {
    setFilterApplying(true);
  }

  const initFunc=(page = 1)=>{
    let data={filter:filter};
    data.limit = 20;
    data.offset = (page - 1) * data.limit;
    data.filter.type='0';
    dispatch(getNotifications({...data})).then(resp=>{
      setReady(true);
      setListRes(resp)
    })
  }

  useEffect(() => {
    if(!ready){
      initFunc();
      const intervalId = setInterval(initFunc, 30000);
      return () => clearInterval(intervalId);
    }
  }, [ready]);

  useEffect(() => {
    if (filterApplying) {
      initFunc();
      setFilterApplying(false);
    }
  }, [filterApplying]);

  return (
    <div className="profileSection supportListPage" style={{marginBottom:'0px'}}>
      <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
        <h2 >{t('Скарги')}
          <div className="infoHelp"> <Hint align='left' item={hint[40]}/></div>
        </h2>
        <div className="prFlexRow gameSection" style={{gap:'15px'}}>
            <button className="prButton prButtonMain prButtonSecond prFlexRow roomFilterButton" onClick={() => setIsOpen(true)}>
                <Icon type="filter" /> {t("filter_show")}
            </button>
        </div>
      </div>

      <div className="roomList">
          {
            listRes?.notification?.length > 0 && listRes?.notification.map((el, index) => {
              return (
                <div key={index} className="roomItem requestItem prFlexRow" style={{ flexWrap: 'nowrap', gap: '10px' }}>
                  <div className="requestItemCol">
                    <div style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+el.roomId)}>
                      <div className="roomItemBlockHeader">ID кімнати</div> 
                      <div className="roomItemLink2" style={{cursor:'pointer'}}>
                        <div className="roomItemBlockHeader croppedText">{el.roomId}</div>
                      </div>  
                    </div>
                    <div>
                      <div className="roomItemBlockHeader">ID запиту</div>
                      <div className="roomItemBlockValue">{el.id}</div>
                    </div>
                  </div>
                  <div className="requestItemCol">
                    <div>
                      <div className="roomItemBlockHeader">Відповідальний</div> 
                      <div className="roomItemBlockValue">
                        {el?.responsibleUser ? (
                            <div
                              className="flex pointer"
                              style={{ gap: '10px', alignItems: 'center'}}
                            >
                              {el.responsibleUser.nickname}
                              {(el.status == '1' || el.status == '0') && (
                                <div onClick={() => onSetResponsible(el)}>
                                  <Icon type="refresh" />
                                </div>
                              )}
                            </div>
                        ) : (
                          <button
                            className="prButton responsible"
                            onClick={() => onSetResponsible(el)}
                          >
                            {t('Взяти в роботу')}
                          </button>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="roomItemBlockHeader">Користувач</div>
                      <div className="roomItemBlockValue">{el.notificationUser?.nickname}</div>
                    </div>
                  </div>
                  <div className="requestItemCol">
                    <div>
                      <div className="roomItemBlockHeader">Статус скарги</div> 
                      <div
                        className="flex pointer"
                        style={{ alignItems: 'center', gap: '10px' }}
                        onClick={() => onDone(el)}
                      >
                        <div className="roomItemBlockValue">
                          {el.status=='0'?'Не вирішено':el.status=='1'?'В роботі':'Вирішено'}
                        </div>
                        {el.status == '1' && (
                          <Icon
                            type="accept"
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="roomItemBlockHeader">Час створення</div>
                      <div className="prFlexRow dateTimeBlock" style={{ gap: '18px' }}>
                        <div className="roomItemBlockValue"><Icon type='time'></Icon>{getDate(el.createdAt.replace('T',' '),'hh:mm',":")}</div>
                        <div className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                      </div>
                    </div>
                  </div>
                  <div className="requestItemCol" style={{ maxWidth: '500px' }}>
                    <div className="roomItemBlockHeader">Текст скарги</div>
                    <div className="roomItemBlockValue">{el.description}</div>
                  </div>
                </div>
              )
            }) 
          }
        </div>
        {isOpen && <Filter type='adminReqFilter'  resetFilter={resetFilter} accept={applyFilters} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}
        {ready && listRes.totalFilter>listRes.limit&& <Pagination
                        className="pagination-bar"
                        currentPage={pageCur}
                        totalCount={listRes.totalFilter}
                        pageSize={listRes.limit}
                        onPageChange={(page) => {
                            setPageCur(page);
                            initFunc(page);
                        }}
                    />}
    </div>
  )
}


export default AdminReqPage;