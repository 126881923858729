import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Filter from "../../../components/Filter/Filter";
import Hint from "../../../components/Hint/Hint";
import { Icon } from "../../../components/Icon";
import Pagination from "../../../components/Pagination/Pagination";
import getDate from "../../../helpers/getDate";

import { getNotifications } from "../../../redux/actions/notifications";



const ArbitrPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ready, setReady] = useState(false);
  const { t } = useTranslation();
  const [listRes, setListRes] = useState({});
  
  const [filter, setFilter] = useState({});
  const [filterApplying, setFilterApplying] = useState(false);
  const [pageCur, setPageCur] = useState(1);
  const dispatch=useDispatch();
  const list = useSelector(state=>state.list);
  const hint = useSelector(state=>state.hints);
  
  const navigate =useNavigate();

  const changeHandler = (e) => {
    const { id, value } = e.target;
    let updatedFilter = { ...filter};

    if (e.target.tagName === 'SELECT' && e.target.accessKey) {
      updatedFilter[e.target.accessKey] = value;
    }
    else if (id === "dateFrom" || id === "dateTo") {
      updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
    } 
    else if (value.length<1) {
        delete updatedFilter[id];
    }
    else {
      updatedFilter[id] = value;
    }
    setFilter(updatedFilter);
  };

  const resetFilter=()=>{
    setFilter({});
    setFilterApplying(true);
  }

  const applyFilter = () => {
    setFilterApplying(true);
  }

  const initFunc=(page = 1)=>{
    let data={filter:filter};
    data.limit = 10;
    data.offset = (page - 1) * data.limit;
    data.filter.type='1';
    dispatch(getNotifications({...data})).then(resp=>{
      setReady(true);
      setListRes(resp)
    })
  }

  useEffect(() => {
    if(!ready){
      initFunc();
      const intervalId = setInterval(initFunc, 30000);
      return () => clearInterval(intervalId);
    }
  }, [ready]);

  useEffect(() => {
    if (filterApplying) {
      initFunc();
      setFilterApplying(false);
    }
  }, [filterApplying]);

  return (
    <div className="profileSection" style={{marginBottom:'0px'}}>
      <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
        <h2>Запити на арбітраж
          <div className="infoHelp"> <Hint align='left' item={hint[41]}/></div>
        </h2>
        <div className="prFlexRow gameSection" style={{gap:'15px'}}>
            <button className="prButton prButtonMain prButtonSecond prFlexRow roomFilterButton" onClick={() => setIsOpen(true)}>
                <Icon type="filter" /> {t("filter_show")}
            </button>
        </div>
      </div>

      <div className="roomList">
        {
          listRes?.notification?.length > 0 && listRes?.notification.map((el, index) => {
            return (
              <div key={index} className="roomItem requestItem prFlexRow verificationRequests" style={{ flexWrap: 'nowrap', gap: '10px' }}>
                <div className="roomItemBlock">
                  <div className="roomItemBlockHeader">ID кімнати</div>
                  <div
                    className="roomItemLink pointer"
                    onClick={()=>navigate('/room/'+el.roomId)}
                  >
                    {el.roomId}
                  </div>
                </div>
                <div className="roomItemBlock">
                  <div className="roomItemBlockHeader">Відповідальний</div>
                  <div className="roomItemBlockValue">{el?.responsibleUser?.nickname}</div>
                </div>
                <div className="roomItemBlock">
                  <div className="roomItemBlockHeader">ID запиту</div>
                  <div className="roomItemBlockValue">{el.id}</div>
                </div>
                <div className="roomItemBlock">
                  <div className="roomItemBlockHeader">Статус</div>
                  <div className="roomItemBlockValue">{el.status=='0'?'Не вирішено':el.status=='1'?'В роботі':'Вирішено'}</div>
                </div>
                <div className="roomItemBlock">
                  <div className="roomItemBlockHeader">Гра</div>
                  <div className="roomItemBlockValue">{el.gameName}</div>
                </div>
                <div className="roomItemBlock">
                  <div className="roomItemBlockHeader">Час оновлення</div>
                  <div className="prFlexRow dateTimeBlock" style={{ gap: '18px' }}>
                    <div className="roomItemBlockValue"><Icon type='time'></Icon>{getDate(el.updatedAt.replace('T',' '),'hh:mm',":")}</div>
                    <div className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.updatedAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                  </div>
                </div>
                <div className="roomItemBlock">
                  <div className="roomItemBlockHeader">Час створення</div>
                  <div className="prFlexRow dateTimeBlock" style={{ gap: '18px' }}>
                    <div className="roomItemBlockValue"><Icon type='time'></Icon>{getDate(el.createdAt.replace('T',' '),'hh:mm',":")}</div>
                    <div className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                  </div>
                </div>
              </div>
            )
          }) 
        }
      </div>

      {isOpen && <Filter type='arbiterFilter'  resetFilter={resetFilter} accept={applyFilter} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}

      {listRes.total > listRes.limit && 
        <Pagination
          className="pagination-bar"
          currentPage={pageCur}
          totalCount={listRes.total}
          pageSize={listRes.limit}
          onPageChange={(page) => {
              setPageCur(page);
              initFunc(page);
          }}
        />
      }
    </div>
  )
}


export default ArbitrPage;