import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "../Icon";

const SmallModal = (props) => {
    const { t } = useTranslation();
    useEffect(() => {
        setTimeout(() => {
            props.setSuccess();
        }, 5000)
    }, [])

    return (
    <div className={props.class+' errorBlock prFlexRow'}>
        {props.class.includes('linkHin')?
            <div
            dangerouslySetInnerHTML={{__html: props.text}}>
            </div>
        :<div>{t(props.text)}</div>}
        <div onClick={()=>props.setSuccess(false)} className='errorBlockIcon'><Icon type="close"></Icon></div>
    </div>
    );
};

export default SmallModal;
