import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../Icon";

const WarningModal = (props) => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const navigate= useNavigate();

    useEffect(() => {
        setTimeout(() => {
            props.closeClick()
        },10000)
    }, []);
   
    return (
        <div className="warningModal">
            <div className="warningModalIcon">
                <div className="warningModalIconBack">
                    <Icon type='warning'></Icon>
                </div>
            </div>
            <div className="warningModalBlock">
                <div className="warningModalHeader">
                    {t('warning')}
                </div>
                <div className="warningModalMessage">
                    {t(props.warningText)}
                </div>
                <div className="warningModalButtons">
                    <button onClick={props.warningClick}>{t(props.warningButton)}</button>
                </div>
            </div>
            <div className="warningModalIconExit" onClick={props.closeClick}>
                <Icon type='close'></Icon>
            </div>
        </div>
    );
};

export default WarningModal;
