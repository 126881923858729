import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";  
import { Icon } from "../Icon";
import DatePicker from "../DatePicker/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import getDate from "../../helpers/getDate";
import { getUserReputation } from "../../redux/actions/user";
import TeamResult from "../TeamResult/TeamResult";
import MultiSelect from "../MultiSelect/MultiSelect";
import Select from "../Select/Select";
import Hint from "../Hint/Hint";
import SearchBox from "../SearchBox/SearchBox";

import { getUsersSearch } from "../../redux/actions/user";
import { getGames } from "../../redux/actions/game";
import Switch from "../Switch/Switch";

const Filter=(props) => {
    const { t } = useTranslation();
    const [form,setForm]=useState({})
    const [temperary,setTemperary]=useState({})
    const fullList = useSelector((state) => {
        return state.fullList
    });
    const user = useSelector((state) => state.currentUser.user);
    const hint = useSelector((state) => state.hints);
    const roomSettings = useSelector(state => state.system.SettingsWallet);
    const [searchList, setSearchList] = useState([]); 
    
    const list = useSelector((state) => {
        return state.list
    });
    const [activeTeamA, setActiveTeamA] = useState('');
    const [activeTeamB, setActiveTeamB] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if(props.type=='leaveRoom'){
            dispatch(getUserReputation({roomId:props.game.id})).then(res=>{
                setTemperary(res);
            })
        }

        const root = document.querySelector('html')
        root.style.overflow = 'hidden';

        return (() => {
            root.style.overflow = 'auto';
        })
    }, []);
    


    const [checkedC, setCheckedC] = useState(false);

    const toggleSidebar = () => {
        props.setIsOpen(!props.isOpen);
    };

    const closeSidebar = (e) => {
    if (e.target.classList.contains("overlay")) {
        props.setIsOpen(false);
    }
    };
    const changeHandler = (event) => {
        const {id, value} = event.target || event;

        setForm({ ...form, [id]: value });
        props.onChange(event);
    };


    useEffect(() => {
        setForm({ ...form, 'checkedC':checkedC });
        if(props.needCheckedC){
            props.onChange({target:{id:'checkedC' , value:checkedC}});
        }
    }, [checkedC]);

    const acceptClick=()=>{
        // props.savefilter();
        props.accept();
        toggleSidebar();
    }
    const acceptClickNoFilter=()=>{
        props.accept();
        toggleSidebar();
    }

    const handleIconClick = (team, resultType) => {
        if (team === 'teamA') {
            setActiveTeamA(resultType);
            if (resultType === 'win') {
                props.onChange({},{ teamA: '2', teamB: '0' })
                setActiveTeamB('lose');
            } else if (resultType === 'draft') {
                props.onChange({},{ teamA: '1', teamB: '1' })
                setActiveTeamB('draft');
            } else if (resultType === 'lose') {
                props.onChange({},{ teamA: '0', teamB: '2' })
                setActiveTeamB('win');
            }
        } else if (team === 'teamB') {
            setActiveTeamB(resultType);
            if (resultType === 'win') {
                props.onChange({},{ teamA: '0', teamB: '2' })
                setActiveTeamA('lose');
            } else if (resultType === 'draft') {
                props.onChange({},{ teamA: '1', teamB: '1' })
                setActiveTeamA('draft');
            } else if (resultType === 'lose') {
                props.onChange({},{ teamA: '2', teamB: '0' })
                setActiveTeamA('win');
            }
        }
    };

    const onSearch = (e) => {
        const { id, value } = e;

        if (value.length >= 3) {
            if (id === 'allUser') {
                dispatch(getUsersSearch({allUser: value}, 'user'))
                    .then( res => {
                        setSearchList(res.user)
                    })
                    .catch( error => {
                        console.log('err: ', error);
                    })
            }

            if (id === 'allResponsible') {
                dispatch(getUsersSearch({allResponsible: value}, 'admin'))
                    .then( res => {
                        setSearchList(res.admin)
                    })
                    .catch( error => {
                        console.log('err: ', error);
                    })
            }

            if (id === 'gameId') {
                const data = {
                    filter: {
                        name: [value],
                    }
                }

                dispatch(getGames(data))
                    .then( res => {
                        setSearchList(res.items);
                    })
                    .catch( error => {
                        console.log('err: ', error);
                    })
            }
        }
    }

    if(props.type=='users'){
        return(
            <>    
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar">
                        <h3 className="prFlexRow">
                            <div className="sidebarFilter">
                                <Icon type='filter'></Icon> 
                                <div>{t('filter_header')}</div>
                            </div> 
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                        <div className="sidebarBody">
                        <div className="prInput">
                            <label htmlFor="all" className={"prInputLabel"}>{t("search_name_nick_enail")}</label>
                            <input onChange={changeHandler} value={props.filter.all||form.all} id="all" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                        </div>
                        <div className="prInput">
                            <label htmlFor="role" className={"prInputLabel"}>{t("role_system")}</label>
                            <Select
                                id="role"
                                value={props.filter.role || ''}
                                onChange={changeHandler}
                                placeholder="Нічого не обрано"
                                options={
                                    fullList.UserRoles.length > 0 &&
                                    fullList.UserRoles
                                }
                            />
                        </div>
                        <div className="prInputPeriod">
                            <label htmlFor="birthday_period" className={"prInputLabel"}>{t("birthday_period")}</label>
                            <div className="prFlexRow">
                                <DatePicker onChange={changeHandler} id="dataBirthdayFrom" value={(props.filter.dataBirthdayFrom && getDate(props.filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''} icon={true}></DatePicker>
                                <div className="prInputPeriodDevider">-</div>
                                <DatePicker onChange={changeHandler} id="dataBirthdayTo" value={(props.filter.dataBirthdayTo && getDate(props.filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''}icon={true}></DatePicker>
                            </div>
                        </div>
            
                        <div  className="prInput">
                            <label htmlFor="sex" className={"prInputLabel"}>{t("gender")}</label>
                            <Select
                                id="sex"
                                value={props.filter.sex || ''}
                                onChange={changeHandler}
                                placeholder="Нічого не обрано"
                                options={
                                    fullList.Sex.length > 0 &&
                                    fullList.Sex
                                }
                            />
                        </div>
                        <div  className="prInput">
                            <label htmlFor="game" className={"prInputLabel"}>{t("game")}</label>
                            <Select
                                id="game"
                                value={form.game || ''}
                                onChange={changeHandler}
                                placeholder="Нічого не обрано"
                                disabled={true} 
                                options={[
                                    {id: "", title: t('empty_select')},
                                    {id: "", title: 'CS'},
                                    {id: "", title: 'Dota'},
                                    {id: "", title: 'WoT'},
                                ]}
                            />
                        </div>
                        <div className="prInputPeriod">
                            <label htmlFor="reputationFrom" className={"prInputLabel"}>{t("reputation")}</label>
                            <div className="prFlexRow">
                                <Select
                                    id="reputationFrom"
                                    value={form.reputationFrom || ''}
                                    onChange={changeHandler}
                                    placeholder=""
                                    align="top"
                                    options={[
                                        {id: "", title: t('empty_select')},
                                        {id: "0", title: '0'},
                                        {id: "1", title: '1'},
                                        {id: "2", title: '2'},
                                        {id: "3", title: '3'},
                                        {id: "4", title: '4'},
                                        {id: "5", title: '5'},
                                        {id: "6", title: '6'},
                                        {id: "7", title: '7'},
                                        {id: "8", title: '8'},
                                        {id: "9", title: '9'},
                                        {id: "10", title: '10'},
                                    ]}
                                />
                                <div className="prInputPeriodDevider">-</div>
                                <Select
                                    id="reputationTo"
                                    value={form.reputationTo || ''}
                                    onChange={changeHandler}
                                    placeholder=""
                                    align="top"
                                    options={[
                                        {id: "", title: t('empty_select')},
                                        {id: "0", title: '0'},
                                        {id: "1", title: '1'},
                                        {id: "2", title: '2'},
                                        {id: "3", title: '3'},
                                        {id: "4", title: '4'},
                                        {id: "5", title: '5'},
                                        {id: "6", title: '6'},
                                        {id: "7", title: '7'},
                                        {id: "8", title: '8'},
                                        {id: "9", title: '9'},
                                        {id: "10", title: '10'},
                                    ]}
                                />
                            </div>
                        </div>
                        <div  className="prInput">
                            <label htmlFor="status" className={"prInputLabel"}>{t("status")}</label>
                            <Select
                                id="status"
                                value={form.status || ''}
                                onChange={changeHandler}
                                placeholder="Нічого не обрано"
                                disabled={true} 
                                options={[
                                    {id: "", title: t('empty_select')},
                                    {id: "", title: 'Верифіковано'},
                                    {id: "", title: 'Очікує'},
                                    {id: "", title: 'Не верифіковано'},
                                ]}
                            />
                        </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                            </div>
                            <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                        </div>
                    </div>
                </div>    
            </>)
    }
    else if (props.type=='rooms'){
    return(
        <>
            <div className="overlay" onClick={closeSidebar}>
                <div className="sidebar">
                    <h3 className="prFlexRow">
                        <div className="sidebarFilter">
                            <Icon type='filter'></Icon> 
                            <div>{t('filter_header')}</div>
                        </div> 
                        <div className="prFlexRow sidebarFilterButtons"> 
                            <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                        </div>
                    </h3>
                    <div className="sidebarBody">
                    <div className="prInput">
                        <label htmlFor="roomId" className={"prInputLabel"}>{t("search_room_id")}</label>
                        <input onChange={changeHandler} value={props.filter?.roomId||''} id="roomId" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                    </div>
                    <div className="prInputPeriod">
                        <label htmlFor="costRate" className={"prInputLabel"}>{t("cost_rate")}</label>
                        <div className="prFlexRow">
                        <input onChange={changeHandler} id="priceFrom" value={props.filter?.priceFrom||''}  type="text" className={"prInputInput"} placeholder={'0$'} />
                        <div className="prInputPeriodDevider">-</div>
                        <input onChange={changeHandler} id="priceTo" value={props.filter?.priceTo||''}  type="text" className={"prInputInput"} placeholder={'10000$'} />
                        </div>
                    </div>
                    {/* <div className="prInput">
                        <label htmlFor="tockenType" className={"prInputLabel"}>{t("tocken_type")}</label>
                                <select
                                className={ "prInputInput"}
                                    id="tockenType"
                                    value={props.filter.tockenType || ""}
                                    onChange={changeHandler}
                                >
                                <option value="">{t('empty_select')}</option>
                                {fullList.TypeToken.length > 0 &&
                                    fullList.TypeToken.map(el => (
                                    <option key={el.id} value={el.id}>{el.title}</option>
                                ))
                                }
                            
                            </select>
                    </div> */}
                    <div className="prInput">
                        <label htmlFor="role" className={"prInputLabel"}>{t("role_room")}</label>
                        <Select
                            id="role"
                            value={form.role || ''}
                            onChange={changeHandler}
                            placeholder="Нічого не обрано"
                            options={
                                fullList.UserStatus.length > 0 &&
                                fullList.UserStatus
                            }
                        />
                    </div>
                    <div className="prInput">
                        <label htmlFor="gameId" className={"prInputLabel"}>{t("game")}</label>
                        <Select
                            id="gameId"
                            value={form.gameId || ''}
                            onChange={changeHandler}
                            placeholder="Нічого не обрано"
                            options={
                                user?.gamesNickname.map( el => ({
                                    id: el.gamePlay?.id,
                                    title: el.gamePlay?.name,
                                }))
                            }
                        />
                    </div>
                    <MultiSelect
                        placeholder="Оберіть формат"
                        label="Формат"
                        options={fullList.Formats}
                        id="formats"
                        value={props.filter.formats}
                        onChange={changeHandler}
                    />
                    <div className="prInput">
                        <label htmlFor="platform" className={"prInputLabel"}>{t("platform")}</label>
                        <Select
                            id="platform"
                            value={form.platform || ''}
                            onChange={changeHandler}
                            placeholder="Нічого не обрано"
                            options={
                                fullList.Platform.length > 0 &&
                                fullList.Platform
                            }
                        />
                    </div>
                    <div className="prInput">
                        <label htmlFor="result" className={"prInputLabel"}>{t("result")}</label>
                        <Select
                            id="result"
                            value={form.result || ''}
                            onChange={changeHandler}
                            placeholder="Нічого не обрано"
                            options={[
                                {id: '', title: t('empty_select')},
                                {id: '0', title: 'Поразка'},
                                {id: '1', title: 'Нічия'},
                                {id: '2', title: 'Перемога'},
                            ]}
                        /> 
                    </div>
                        <div className="userEditButtons">
                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                            <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                        </div>
                        <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                    </div>
                </div>
            </div>    
        </>)
    }
    else if (props.type=='roomsFilter'){
        return(
            <>
                <div className="overlay" onClick={closeSidebar}>
                <div className="sidebar">
                    <h3 className="prFlexRow">
                        <div className="sidebarFilter">
                            <Icon type='filter'></Icon> 
                            <div>{t('filter_header')}</div>
                        </div> 
                        <div className="prFlexRow sidebarFilterButtons"> 
                            <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                        </div>
                    </h3>
                    <div className="sidebarBody">
                    <div className="prInput">
                        <label htmlFor="roomId" className={"prInputLabel"}>{t("search_room_id")}</label>
                        <input onChange={changeHandler} value={props.filter?.roomId||''} id="roomId" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                    </div>
                    <div className="prInputPeriod">
                        <label htmlFor="costRate" className={"prInputLabel"}>{t("cost_rate")}</label>
                        <div className="prFlexRow">
                        <input onChange={changeHandler} id="priceFrom" value={props.filter?.priceFrom||''}  type="text" className={"prInputInput"} placeholder={'0$'} />
                        <div className="prInputPeriodDevider">-</div>
                        <input onChange={changeHandler} id="priceTo" value={props.filter?.priceTo||''}  type="text" className={"prInputInput"} placeholder={'10000$'} />
                        </div>
                    </div>

                    <div className="prInput">
                        <label htmlFor="platform" className={"prInputLabel"}>{t("platform")}</label>
                        <Select
                            id="platform"
                            value={props.filter.platform || ''}
                            onChange={changeHandler}
                            placeholder="Нічого не обрано"
                            options={props.game.platforms.length > 0 &&
                                props.game.platforms.map(el => (
                                {id: el, title: list[el]}
                            ))}
                        /> 
                    </div>
                    <div className="prInput">
                        <label htmlFor="format" className={"prInputLabel"}>{t("format")}</label>
                        <Select
                            id="format"
                            value={props.filter.format || ''}
                            onChange={changeHandler}
                            placeholder="Нічого не обрано"
                            options={props.game.formats.length > 0 &&
                                props.game.formats.map(el => (
                                {id: el, title: el + '/' +el}
                            ))}
                        /> 
                    </div>

                        <div className="userEditButtons">
                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                            <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                        </div>
                        <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                    </div>
                </div>
                </div>    
            </>)
    }
    else if (props.type=='transaction'){
        return(
            <>
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar">
                        <h3 className="prFlexRow">
                            <div className="sidebarFilter">
                                <Icon type='filter'></Icon> 
                                <div>{t('filter_header')}</div>
                            </div> 
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                        <div className="sidebarBody">
                            <div className="prInput">
                                <label htmlFor="id" className={"prInputLabel"}>{t("search_id_transaction")}</label>
                                <input onChange={changeHandler} id="id" type="text" value={props.filter.id||''} className={"prInputInput"} placeholder={t("enter_value")} />
                            </div>
                            <div className="prInputPeriod">
                                <label htmlFor="costRate" className={"prInputLabel"}>{t("cost_rate")}</label>
                                <div className="prFlexRow">
                                <input onChange={changeHandler} id="quantityFrom" type="text" value={props.filter.quantityFrom||''} className={"prInputInput"} placeholder={'0$'} />
                                <div className="prInputPeriodDevider">-</div>
                                <input onChange={changeHandler} id="quantityTo" value={props.filter.quantityTo||''} type="text" className={"prInputInput"} placeholder={'10000$'} />
                                </div>
                            </div>
                            <div className="prInput">
                                <label htmlFor="type" className={"prInputLabel"}>{t("transaction_type")}</label>
                                <Select
                                    id="type"
                                    value={form.type || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={[
                                        {id: '', title: t('empty_select')},
                                        {id: '1', title: 'Поповнення'},
                                        {id: '0', title: 'Вивід'},
                                    ]}
                                />
                            </div>
                            <div className="prInput">
                                <label htmlFor="paymentSystem" className={"prInputLabel"}>{t("payment_org")}</label>
                                <Select
                                    id="paymentSystem"
                                    value={form.paymentSystem || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={[
                                        {id: '', title: t('empty_select')},
                                        {id: 'merchant-api.sticklab', title: 'merchant-api.sticklab'},
                                    ]}
                                />
                            </div>
                            <div className="prInput">
                                <label htmlFor="transactionStatus" className={"prInputLabel"}>{t("transaction_status")}</label>
                                <Select
                                    id="transactionStatus"
                                    value={form.transactionStatus || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={
                                        fullList.TransactionStatus.length > 0 &&
                                        fullList.TransactionStatus
                                    }
                                />
                            </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                            </div>
                            <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                        </div>
                    </div>
                </div>    
            </>)
        }
    else if (props.type=='createRooms'){
        return(
            <>
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar bigSidebar">
                        <h3 className="prFlexRow">
                            <h2>{t('message')}
                            <div className="infoHelp">
                                <Hint align='center' item={hint[47]}/>
                            </div>                                    </h2>
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>

                        <div className="simpleText">
                            Якщо ви створюєте кімнату вперше, прочитайте наші рекомендації
                            та поради у розділі “<a href="#">Створення ігрової кімнати</a>”
                        </div>
                        <div className="sidebarBody">
                        {props.game?
                        <div className="prInput">
                        <label htmlFor="game" className={"prInputLabel"}>{t("choose_game")}</label>
                        <Select
                            id="game"
                            value={props.filter.game || ''}
                            onChange={changeHandler}
                            placeholder="Нічого не обрано"
                            options={[
                                {id: props.game.id, title: props.game.name}
                            ]}
                        />
                    </div>
                        :<div className="prInput">
                            <label htmlFor="game" className={"prInputLabel"}>{t("choose_game")}</label>
                            <Select
                                id="selectedGame"
                                value={props.filter.selectedGame || ''}
                                onChange={changeHandler}
                                placeholder="Нічого не обрано"
                                options={user?.gamesNickname.map( el => ({
                                    id: el.gameId, title: el.gamePlay.name
                                }))}
                            />
                        </div>}

                        {/* <div className="prInput">
                            <label htmlFor="isPrivateRoom" className={"prInputLabel"}>{t("is_private_room")}</label>
                            <div className="">
                                <div className="">Так</div>
                                <div className="">Ні</div>
                            </div>
                        </div> */}

                        <div className="prInput">
                            <label htmlFor="isPrivateRoom" className="prInputLabel">
                                {t('Це приватна кімната?')}
                            </label>
                            <Switch id='isPrivateRoom' onChange={changeHandler} checked={props.filter.isPrivateRoom || 0} />
                        </div>

                        <div className="prInput">
                            <label htmlFor="cost_invite" className={"prInputLabel"}>{t("cost_invite")}</label>
                            <div className="prFlexRow">
                                <input onChange={changeHandler} style={{width:'88px'}} id="price" type="text" value={props.filter.price||''} className={"prInputInput"} placeholder={''} />
                                <div className="checkInput prFlexRow" style={{ width: '60px',marginLeft:"55px"}}>
                                    <div onClick={() => setCheckedC(false)} className={!checkedC ? "prCheckBox checked" : "prCheckBox"} >
                                    </div>
                                    <div style={{ textAlign: 'left' }} onClick={() => setCheckedC(false)}>
                                        <Icon type='fTocken'></Icon>
                                    </div>
                                    </div>
                                <div className="checkInput prFlexRow" style={{ width: '60px',marginLeft:"25px"}}>
                                    <div onClick={() => setCheckedC(true)} className={checkedC ? "prCheckBox checked" : "prCheckBox"} >
                                    </div>
                                    <div style={{ textAlign: 'left' }} onClick={() => setCheckedC(true)}>
                                        <Icon type='cTocken'></Icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="prInput">
                                <div className="prInput" style={{ marginBottom: "-9px" }}>
                                    <label htmlFor="platform" className={"prInputLabel"}>
                                    {t("platform")}
                                    </label>
                                </div>
                                {fullList.Platform.map((el) => {
                                    if(props.game && props.game['platforms']?.includes(el.id)){
                                        return(
                                        <div className="checkInput prFlexRow" key={el.id} style={{ width: "180px", marginTop: "9px" }}>
                                            <div
                                                id={el.id}
                                                onClick={props.selectClick}
                                                className={props.filter['platform']?.includes(el.id) ? "prCheckBox checked" : "prCheckBox"}
                                            ></div>
                                            <div style={{ textAlign: "left", width: "180px" }} id={el.id} onClick={props.selectClick}>
                                                {t(el.title)}
                                            </div>
                                        </div>
                                        )
                                    }else if(!props.game){
                                        return user?.gamesNickname.map(element=>{
                                            if(element.gamePlay.id === props.filter.selectedGame &&element.gamePlay.platforms.includes(el.id)){
                                                return(
                                                    <div className="checkInput prFlexRow" key={el.id} style={{ width: "180px", marginTop: "9px" }}>
                                                        <div
                                                            id={el.id}
                                                            onClick={props.selectClick}
                                                            className={props.filter['platform']?.includes(el.id) ? "prCheckBox checked" : "prCheckBox"}
                                                        ></div>
                                                        <div style={{ textAlign: "left", width: "180px" }} id={el.id} onClick={props.selectClick}>
                                                            {t(el.title)}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                })}
                        </div >
                        <div className="prInput">
                            <div className="prInput" style={{ marginBottom: "-9px" }}>
                                <label htmlFor="format" className="prInputLabel">{t("platform")} для вашого пристрою</label>
                                <Select
                                    id="platformId"
                                    value={props.filter.platformId || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={user.gamesNickname.flatMap((el) => {
                                        if ((props.game && el.gameId === props.game.id) || (el.gameId === props.filter.selectedGame)) {
                                            return el.platforms
                                            .filter((elem) => props.filter['platform']?.includes(elem.platform))
                                            .map((elem) => ({ id: elem.platform, title: list[elem.platform] }));
                                        }
                                        return [];
                                    })}
                                />
                            </div>
                        </div>
                            
                        <div className="prInput">

                            <label htmlFor="format" className={"prInputLabel"}>{t("format")}</label>
                            <Select
                                id="format"
                                value={props.filter.format || ''}
                                onChange={changeHandler}
                                placeholder="Нічого не обрано"
                                options={
                                    props?.game?.formats?.length
                                        ? props.game.formats.map(el => ({ id: el, title: `${el} x ${el}` }))
                                        : user.gamesNickname.flatMap(elem => 
                                            elem.gameId === props.filter.selectedGame
                                            ? elem.gamePlay.formats.map(el => ({ id: el, title: `${el} x ${el}` }))
                                            : []
                                        )
                                }
                            />
                        </div>

                        <div className="prInput">
                            <label htmlFor="description" className={"prInputLabel"}>{t("description")}</label>
                            <div className="simpleText">
                            Якщо у вас є певні умови, наприклад на якому сервері або за якими правилами будете грати, обов’язково напишіть про це в описі, щоб інші учасники орієнтувались
                            </div>
                            <textarea onChange={changeHandler} id="description" value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                        </div>
                        <div className="userEditButtons">
                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                            <button className="prButton prButtonMain" disabled={!props.filter?.format?.length>0 || !props.filter?.platformId?.length>0 || !props.filter?.price?.length>0} onClick={acceptClickNoFilter}>{t('next')}</button>
                        </div>
                        </div>
                    </div>
                </div>    
            </>
        )
    }
    else if (props.type === 'rules'){
        return(
            <>
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar bigSidebar">
                        <h3 className="prFlexRow">
                            <h2>
                                {t('Правила кімнати')}
                            </h2>
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>

                        <div className="simpleText">
                            {roomSettings.rules?.split("\n").map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </div>
                    </div>
                </div>    
            </>
        )
    }
    else if (props.type === 'userBlock'){
        return(
            <>
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar bigSidebar">
                        <h3 className="prFlexRow">
                            <h2>
                                {t('Причина блокування')}
                            </h2>
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>

                        <div className="sidebarBody">
                            <div className="prInput">
                                <textarea
                                    style={{ margin: 0 }}
                                    onChange={changeHandler}
                                    id="blockReason"
                                    value={props.form?.blockReason || ""}
                                    placeholder={t("enter_value")}
                                    cols="30"
                                    rows="10"
                                ></textarea>
                            </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                                <button className="prButton prButtonMain" disabled={!props.form.blockReason} id="block" onClick={props.accept}>{t('Підтвердити')}</button>
                            </div>
                        </div>
                    </div>
                </div>    
            </>
        )
    }
    else if (props.type === 'declineVerification'){
        return(
            <>
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar bigSidebar">
                        <h3 className="prFlexRow">
                            <h2>
                                {t('Причина відмови')}
                            </h2>
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>

                        <div className="sidebarBody">
                            <div className="prInput">
                                <textarea
                                    style={{ margin: 0 }}
                                    onChange={changeHandler}
                                    id="verificationReason"
                                    value={props.form?.verificationReason || ""}
                                    placeholder={t("enter_value")}
                                    cols="30"
                                    rows="10"
                                ></textarea>
                            </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                                <button className="prButton prButtonMain" disabled={!props.form.verificationReason} id='unVerified' onClick={props.accept}>{t('Підтвердити')}</button>
                            </div>
                        </div>
                    </div>
                </div>    
            </>
        )
    }
    else if (props.type=='addMyGame'){

        props.user.gamesNickname.forEach(el=>{
            if(el.gameId==props.filter.id && !props.filter.setted ){
                let data={...props.filter};
                data.platformNew={};
                let arr = [...props.filter.platform]; 
                el.platforms.forEach(elem=>{
                    props.filter.platforms.forEach(ele=>{
                        if(elem.platform===ele && !arr.includes(ele)){
                            data.platformNew[ele] = elem.nickname;
                            arr.push(ele);
                        }
                    })
                })
                props.setFilter(prev => (
                    {
                    ...prev,
                    platform: arr,
                    setted:true,
                    platformNew: { ...prev.platformNew, ...data.platformNew }
                }));
            }
        })
        return(
            <>
                            <div className="overlay" onClick={closeSidebar}>
                            <div className="sidebar myGameEdit">
                                <h3 className="prFlexRow">
                                    <h2 className="chooseFor">
                                        {t('choose_options_for_game')} 
                                        <br />
                                        <span className="chooseForGame"> {props.filter.name}!</span>
                                    </h2>
                                    <div className="prFlexRow sidebarFilterButtons" style={{alignSelf:'baseline'}}> 
                                        <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                                    </div>
                                </h3>

                                <div className="platform">
                                    <div className="prInput" style={{ marginBottom: "-9px" }}>
                                        <label htmlFor="platform" className={"prInputLabel"}>
                                        {t("platform")}
                                        <div className="infoHelp">
                                            <Hint align='right' item={hint[44]}/>
                                        </div>
                                        </label>
                                    </div>
                                    {fullList.Platform.map((el) => {
                                        if(props.filter['platforms']?.includes(el.id)){
                                            return(
                                            <div className="checkInput prFlexRow" key={el.id} style={{ width: "180px", marginTop: "9px" }}>
                                                <div
                                                    id={el.id}
                                                    onClick={props.selectClick}
                                                    className={props.filter['platform']?.includes(el.id) ? "prCheckBox checked" : "prCheckBox"}
                                                ></div>
                                                <div style={{ textAlign: "left", width: "180px" }} id={el.id} onClick={props.selectClick}>
                                                    {t(el.title)}
                                                </div>
                                            </div>
                                            )
                                        }
                                    })}
                                </div>
                                <div className="platform">
                                    {props?.filter?.platform.map(el=>{
                                        return(
                                            <div className="prInput">
                                                <label htmlFor={el} className={"prInputLabel"}>{t("nickname_for")} {list[el]}
                                                <div className="infoHelp">
                                                    <Hint align='right' item={hint[45]}/>
                                                </div>
                                                </label>
                                                <input onChange={changeHandler} id={el} type="text" value={props?.filter?.platformNew ? props?.filter?.platformNew[el]:''} className={"prInputInput"} placeholder={t("enter_value")} />
                                            </div>
                                        )

                                    })}

                                </div>
                            
                                <div className="userEditButtons">
                                    <button
                                        className="prButton prButtonSecond"
                                        disabled={props?.filter?.platform?.length<1}
                                        onClick={props.accept}
                                        style={{ margin: '0 10px' }}
                                    >
                                        Зберегти
                                    </button>
                                </div>
                                </div>
                            </div>    
                        </>)
    }
    else if (props.type=='joinRoom'){
        return(
            <>
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar bigSidebar">
                    <h3 className="prFlexRow">
                        <h2 className="chooseFor">
                            {t('choose_options_for_game')} 
                            <br />
                            <span className="chooseForGame"> {props.game.game.name}!</span>
                        </h2>
                        <div className="prFlexRow sidebarFilterButtons"> 
                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                        </div>
                    </h3>
                <div className="sidebarBody">

                <div className="prInput" style={{ marginBottom: "20px" }}>
                    <label htmlFor="format" className="prInputLabel">{t("platform")} для вашого пристрою</label>
                    <Select
                        id="platformId"
                        value={props.filter.platformId || ''}
                        onChange={changeHandler}
                        placeholder="Нічого не обрано"
                        options={user.gamesNickname.flatMap((el) => {
                            const isSelectedGame = props.game ? el.gameId === props.game.game.id : el.gameId === props.filter.game;
                            if (!isSelectedGame) return [];
                        
                            const platforms = props.game ? props.game["platform"] : el.gamePlay["platforms"];
                            return el.platforms
                              .filter((elem) => platforms?.includes(elem.platform))
                              .map((elem) => ({ id: elem.platform, title: list[elem.platform] }));
                          })}
                    />
                </div>
                    <div className="userEditButtons">
                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                            <button className="prButton prButtonMain" disabled={!props.filter.platformId} onClick={acceptClickNoFilter}>{t('next')}</button>
                    </div>
                </div>
                </div>    
                </div>    
            </>)
    }
    else if (props.type=='leaveRoom'){
        return(
            <>
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar bigSidebar">
                    <h3 className="prFlexRow">
                        <h2 className="">
                            Ви точно хочете покинути кімнату? 
                            <div className="infoHelp">
                                <Hint align='center' item={hint[55]}/>
                            </div>
                            {/* <span className="chooseForGame"> {props.game.game.name}!</span> */}
                        </h2>
                        <div className="prFlexRow sidebarFilterButtons"> 
                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                        </div>
                    </h3>
                <div className="sidebarBody">
                    <div className="attentionPart dFlex">
                        <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                        <div>
                            <h3>Увага!</h3>
                            <div>Нагадуємо що:</div>
                            <div>За 3 виходи з Кімнати поспіль з вашої репутації буде списано 1 бал</div>
                            <div>За 5 виходів з Кімнати впродовж доби з вашої репутації буде списано 2 бали</div>
                        </div>
                    </div>

                    <div className="leavingRoomText">
                        <h3>Зараз у вас:</h3>
                        <div> {temperary?.consecutiveExits} із 3 виходів із кімнати поспіль</div>
                        <div> {temperary?.dayExit} із 5 виходи із кімнати впродовж доби</div>
                    </div>

                <div className="userEditButtons">
                        <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відхилити</button>
                        <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>{t('next')}</button>
                    </div>
                    </div>
                </div>    
                </div>    
            </>)
    }
    else if (props.type=='callAdmin'){
        return(
            <>
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar bigSidebar">
                    <h3 className="prFlexRow">
                        <h2 className="">
                            Форма виклику адміністратора
                            <div className="infoHelp">
                                <Hint align='center' item={hint[54]}/>
                            </div>
                            {/* <span className="chooseForGame"> {props.game.game.name}!</span> */}
                        </h2>
                        <div className="prFlexRow sidebarFilterButtons"> 
                            <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                        </div>
                    </h3>
                <div className="sidebarBody">
                    <div className="prInput">
                        <div className="simpleText">
                        Опишіть, будь ласка, суть своєї скарги
                        </div>
                        <textarea onChange={changeHandler} id="description" maxLength={250} value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                        <div className="prInputLength">{props.filter?.description?.length || 0}/250</div>
                    </div>
                    <div className="attentionPart dFlex">
                        <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                        <div>
                            <h3>Увага!</h3>
                            <div>Безпричинний виклик адміністратора може призвести до зняття репутаційних балів</div>
                        </div>
                    </div>
                        <div className="userEditButtons" style={{marginTop:'40px'}}>
                        <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                        <button className="prButton prButtonMain" disabled={props.filter?.description?.length<1} onClick={acceptClickNoFilter}>Поскаржитись</button>
                    </div>
                    </div>
                </div>    
                </div>    
            </>)
    }
    else if(props.type=='callRef'){
        return(
            <> 
            <div className="overlay" onClick={closeSidebar}>
                <div className="sidebar bigSidebar">
                <h3 className="prFlexRow">
                    <h2 className="">
                        Завантажити файли із доказами?
                            <div className="infoHelp">
                                <Hint align='center' item={hint[48]}/>
                            </div>
                    </h2>
                    <div className="prFlexRow sidebarFilterButtons"> 
                        <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                    </div>
                </h3>
            <div className="sidebarBody">
                <div className="prInput">
                    <div className="simpleText">
                        Виклик арбітра потрібен лише тоді, коли один із учасників не погоджується із результатами, або підозрює іншого учасника в неспортивній поведінці (шахрайство, читерство тощо).  Цю дію неможливо скасувати чи відредагувати, тому будьте уважні, заповнюючи цю форму
                    </div>
                </div>

                <div className="prInput">
                        <label className="prInputLabel">
                            Опишіть причину виклику арбітра
                        </label>
                        <div className="simpleText">
                            Опишіть, будь ласка, суть своєї скарги
                        </div>
                        <textarea onChange={changeHandler} id="description" value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                        <div className="prInputLength">{props.filter?.description?.length || 0}/250</div>
                </div>

                <div>
                    <TeamResult handleIconClick={handleIconClick} activeTeamA={activeTeamA} activeTeamB={activeTeamB}></TeamResult>
                </div>

                <div className="switch-container prInput" style={{marginTop:'40px'}}>
                    <label className="prInputLabel">Чи користувались Ви під час гри клієнтом?
                            <div className="infoHelp">
                                <Hint align='center' item={hint[50]}/>
                            </div>
                    </label>
                    <label className="switch">
                        <input
                        type="checkbox"
                        id="isClientUsed"
                        checked={props.filter.isClientUsed}
                        onChange={changeHandler}
                        />
                        <span className="slider round"></span>
                        {props.filter.isClientUsed==1?<span className="switchText left">Так</span>:
                        <span className="switchText right">Ні</span>}
                    </label>
                </div>

                
                <div className="prInput">
                    <div className="simpleText" style={{marginBottom:'11px'}}>
                        Якщо хочете надати нам більше інформації, можете розмістити її на Dropbox чи Google Drive, відкрити доступ до перегляду, і надіслати нам посилання
                    </div>
                    <input onChange={changeHandler} value={props.filter.link||form.link} id="link" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                </div>
                <div className="attentionPart dFlex">
                    <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                    <div>
                        <h3>Увага!</h3>
                        <div>Безпричинний виклик арбітра може призвести до зняття репутаційних балів</div>
                    </div>
                </div>
                <div className="userEditButtons" style={{marginTop:'40px'}}>
                    <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                    <button className="prButton prButtonMain" disabled={props.filter?.description?.length<1} onClick={acceptClickNoFilter}>Надіслати</button>
                </div>
                </div>
            </div>    
            </div>   

            </>
        )
    }
    else if(props.type=='callRefAdd'){
        return(
            <> 
            <div className="overlay" onClick={closeSidebar}>
                <div className="sidebar bigSidebar">
                <h3 className="prFlexRow">
                    <h2 className="">
                        Завантажити файли із доказами?
                        <div className="infoHelp">
                            <Hint align='center' item={hint[51]}/>
                        </div>
                    </h2>
                    <div className="prFlexRow sidebarFilterButtons"> 
                        <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                    </div>
                </h3>
            <div className="sidebarBody">
                <div className="switch-container prInput">
                    <label className="prInputLabel">Чи користувались Ви під час гри клієнтом?
                        <div className="infoHelp">
                            <Hint align='center' item={hint[52]}/>
                        </div>
                    </label>
                    <label className="switch">
                        <input
                        type="checkbox"
                        id="isClientUsed"
                        checked={props.filter.isClientUsed==1}
                        onChange={changeHandler}
                        />
                        <span className="slider round"></span>
                        {props.filter.isClientUsed==1?<span className="switchText left">Так</span>:
                        <span className="switchText right">Ні</span>}
                    </label>
                </div>
                <div className="prInput">
                    <div className="simpleText" style={{marginBottom:'11px'}}>
                        Якщо хочете надати нам більше інформації, можете розмістити її на Dropbox чи Google Drive, відкрити доступ до перегляду, і надіслати нам посилання
                    </div>
                    <input onChange={changeHandler} value={props.filter.link||form.link} id="link" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                </div>

                <div className="prInput">
                    <label className="prInputLabel">Ваш суперник до виклику арбітра додав наступний текст:</label>
                    <div className="infoWithPrev">
                        {props.game.appealArbitrator[0].description?.split("\n").map((line, index) => (
                            <p key={index}>{line}</p>
                        ))}
                    </div>
                </div>
                <div className="prInput">
                        <label className="prInputLabel">
                            Напишіть свою відповідь (за бажання)
                        </label>
                        <div className="simpleText">
                            Зерніть увагу, Ваш суперник буде бачити текст, написаний к цьому полі, тому радимо бути уважними та обережними у формулюваннях
                        </div>
                        <textarea onChange={changeHandler} id="description" value={props.filter.description} placeholder={t("enter_value")} cols="30" rows="10"></textarea>
                        <div className="prInputLength">{props.filter?.description?.length || 0}/250</div>
                </div>
                <div className="attentionPart dFlex">
                    <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
                    <div>
                        <h3>Увага!</h3>
                        <div>Нагадуємо, що підробка доказів та введення в оману може призвети до втрати балів репутації та навіть бану</div>
                    </div>
                </div>
                <div className="userEditButtons" style={{marginTop:'40px'}}>
                    <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                    <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>Надіслати</button>
                </div>
                </div>
            </div>    
            </div>   

            </>
        )
    }
    else if(props.type=='inviteUser'){
        return(
            <> 
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar bigSidebar">
                        <h3 className="prFlexRow">
                            <h2 className="">
                            Запросити гравця до кімнати
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[53]}/>
                                </div>
                            </h2>
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                    
                        <div className="sidebarBody">
                            <div className="prInput">
                                <label htmlFor="roomId" className={"prInputLabel"}>Ви можете знайти гравця за нікнеймом, яким він підписаний саме в цій грі</label>
                                <input onChange={changeHandler} value={form.nickname||''} id="nickname" type="text" className={"prInputInput"} placeholder={t("enter_value")} />
                            </div>

                            <div>
                            {props.users?.length > 0 &&
                                props.users.map(el => {
                                    const isSelected = props.userSet.includes(el.nicknameUser.id); // Проверяем, выбран ли пользователь
                                    return (
                                        <div
                                            className={`userItemFilter ${isSelected ? "selected" : ""}`}
                                            key={el.id}
                                            id={el.nicknameUser.id}
                                            onClick={() => {
                                                if (isSelected) {
                                                    props.setUserSet(props.userSet.filter(userId => userId !== el.nicknameUser.id));
                                                } else {
                                                    props.setUserSet([...props.userSet, el.nicknameUser.id]);
                                                }
                                            }}
                                        >   <div className="userItemFilterAvatar" style={{backgroundImage:`url(${el?.avatarImg})`}}>
                                                {!el?.avatarImg && <Icon type="avatar" />}
                                            </div>
                                            <div>
                                                {el.nickname} 
                                            </div>
                                            {el.platform=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                                <Icon type='pc'></Icon>
                                            }
                                            {el.platform=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                            <Icon type='ps'></Icon>
                                            }
                                            {el.platform=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                            <Icon type='xbox'></Icon>
                                            }
                                        </div>
                                    );
                                })}
                            </div>

                        <div className="userEditButtons" style={{marginTop:'40px'}}>
                            <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                            <button className="prButton prButtonMain" onClick={acceptClickNoFilter}>Запросити</button>
                        </div>
                        </div>
                </div>    
                </div>   
            </>
        )
    }
    else if (props.type === 'supportPage') {
        return(
            <> 
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar">
                        <h3 className="prFlexRow">
                            <div className="sidebarFilter">
                                <Icon type='filter'></Icon> 
                                <div>{t('filter_header')}</div>
                            </div> 
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                        <div className="sidebarBody">
                            <div className="prInput">
                                <label htmlFor="id" className={"prInputLabel"}>Пошук за ID запиту</label>
                                <input onChange={changeHandler} value={form.id || ''} id="id" type="text" className={"prInputInput"} placeholder='Введіть ID запиту' />
                            </div>
                            <div className="prInput">
                                <label htmlFor="department" className={"prInputLabel"}>Відділ</label>
                                <Select
                                    id="department"
                                    value={form.department || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={
                                        fullList.Department.length > 0 &&
                                        fullList.Department
                                    }
                                />
                            </div>

                            <div className="prInput">
                                <label htmlFor="status" className={"prInputLabel"}>Статус запиту</label>
                                <Select
                                    id="status"
                                    value={form.status || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={
                                        fullList.StatusSupport.length > 0 &&
                                        fullList.StatusSupport
                                    }
                                />
                            </div>
                            <div className="prInputPeriod">
                                <label htmlFor="support_date" className={"prInputLabel"}>Період створення</label>
                                <div className="prFlexRow">
                                    <DatePicker
                                        onChange={changeHandler}
                                        id="dateFrom"
                                        value={(form.dateFrom && getDate(form.dateFrom,'dd/mm/yyyy','.')) || ''}
                                        icon={true}
                                    />
                                    <div className="prInputPeriodDevider">-</div>
                                    <DatePicker
                                        onChange={changeHandler}
                                        id="dateTo"
                                        value={(form.dateTo && getDate(form.dateTo,'dd/mm/yyyy','.')) || ''}
                                        icon={true}
                                        alignRight={true}
                                    />
                                </div>
                            </div>
                            <div className="prInput">
                                <label htmlFor="responsible" className={"prInputLabel"}>Відповідальний</label>
                                <SearchBox
                                    id="allResponsible"
                                    filterId="responsible"
                                    onSearch={onSearch}
                                    options={searchList}
                                    onChange={changeHandler}
                                    value={form.responsible || null}
                                    valueField="id"
                                    titleField="nickname"
                                />
                            </div>
                            <div className="prInput">
                                <label htmlFor="userId" className={"prInputLabel"}>Користувач</label>
                                <SearchBox
                                    id="allUser"
                                    filterId="userId"
                                    onSearch={onSearch}
                                    options={searchList}
                                    onChange={changeHandler}
                                    value={form.userId || null}
                                    valueField="id"
                                    titleField="nickname"
                                />
                            </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                            </div>
                            <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                        </div>
                    </div>    
                </div>   
            </>
        )
    }
    else if (props.type === 'adminReqFilter') {
        return(
            <> 
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar">
                        <h3 className="prFlexRow">
                            <div className="sidebarFilter">
                                <Icon type='filter'></Icon> 
                                <div>{t('filter_header')}</div>
                            </div> 
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                        <div className="sidebarBody">
                            <div className="prInput">
                                <label htmlFor="id" className={"prInputLabel"}>Пошук за ID скарги</label>
                                <input onChange={changeHandler} value={form.id || ''} id="id" type="text" className={"prInputInput"} placeholder='Введіть ID запиту' />
                            </div>
                            <div className="prInput">
                                <label htmlFor="roomId" className={"prInputLabel"}>Пошук за ID кімнати</label>
                                <input onChange={changeHandler} value={form.roomId || ''} id="roomId" type="text" className={"prInputInput"} placeholder='Введіть ID запиту' />
                            </div>
                            <div className="prInput">
                                <label htmlFor="status" className={"prInputLabel"}>Статус запиту</label>
                                <Select
                                    id="status"
                                    value={form.status || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={[
                                        { id: '', title: t('empty_select') },
                                        { id: '0', title: 'Не вирішено' },
                                        { id: '1', title: 'В роботі' },
                                        { id: '2', title: 'Вирішено' },
                                    ]}
                                />
                            </div>
                            <div className="prInputPeriod">
                                <label htmlFor="notification_date" className={"prInputLabel"}>Період створення</label>
                                <div className="prFlexRow">
                                    <DatePicker
                                        onChange={changeHandler}
                                        id="dateFrom"
                                        value={(form.dateFrom && getDate(form.dateFrom,'dd/mm/yyyy','.')) || ''}
                                        icon={true}
                                    />
                                    <div className="prInputPeriodDevider">-</div>
                                    <DatePicker
                                        onChange={changeHandler}
                                        id="dateTo"
                                        value={(form.dateTo && getDate(form.dateTo,'dd/mm/yyyy','.')) || ''}
                                        icon={true}
                                        alignRight={true}
                                    />
                                </div>
                            </div>
                            <div className="prInput">
                                <label htmlFor="responsible" className={"prInputLabel"}>Відповідальний</label>
                                <SearchBox
                                    id="allResponsible"
                                    filterId="responsible"
                                    onSearch={onSearch}
                                    options={searchList}
                                    onChange={changeHandler}
                                    value={form.responsible || null}
                                    valueField="id"
                                    titleField="nickname"
                                />
                            </div>
                            <div className="prInput">
                                <label htmlFor="userId" className={"prInputLabel"}>Користувач</label>
                                <SearchBox
                                    id="allUser"
                                    filterId="userId"
                                    onSearch={onSearch}
                                    options={searchList}
                                    onChange={changeHandler}
                                    value={form.userId || null}
                                    valueField="id"
                                    titleField="nickname"
                                />
                            </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                            </div>
                            <button className="w100 prButton prButtonSecond" onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                        </div>
                    </div>    
                </div>   
            </>
        )
    }
    else if (props.type === 'arbiterFilter') {
        return(
            <> 
                <div className="overlay" onClick={closeSidebar}>
                    <div className="sidebar">
                        <h3 className="prFlexRow">
                            <div className="sidebarFilter">
                                <Icon type='filter'></Icon> 
                                <div>{t('filter_header')}</div>
                            </div> 
                            <div className="prFlexRow sidebarFilterButtons"> 
                                <div onClick={props.resetFilter}><Icon type='clear'></Icon></div> 
                                <div onClick={toggleSidebar}><Icon type='close'></Icon></div>
                            </div>
                        </h3>
                        <div className="sidebarBody">
                            <div className="prInput">
                                <label htmlFor="id" className={"prInputLabel"}>Пошук за ID запиту</label>
                                <input onChange={changeHandler} value={form.id || ''} id="id" type="text" className={"prInputInput"} placeholder='Введіть ID запиту' />
                            </div>
                            <div className="prInput">
                                <label htmlFor="roomId" className={"prInputLabel"}>Пошук за ID кімнати</label>
                                <input onChange={changeHandler} value={form.roomId || ''} id="roomId" type="text" className={"prInputInput"} placeholder='Введіть ID запиту' />
                            </div>
                            <div className="prInput">
                                <label htmlFor="status" className={"prInputLabel"}>Статус запиту</label>
                                <Select
                                    id="status"
                                    value={form.status || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={[
                                        { id: '', title: t('empty_select') },
                                        { id: '0', title: 'Не вирішено' },
                                        { id: '1', title: 'В роботі' },
                                        { id: '2', title: 'Вирішено' },
                                    ]}
                                />
                            </div>
                            <div className="prInputPeriod">
                                <label htmlFor="birthday_period" className={"prInputLabel"}>Період створення</label>
                                <div className="prFlexRow">
                                    <DatePicker
                                        onChange={changeHandler}
                                        id="dateFrom"
                                        value={(props.filter.dataBirthdayFrom && getDate(props.filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''}
                                        icon={true}
                                    />
                                    <div className="prInputPeriodDevider">-</div>
                                    <DatePicker
                                        onChange={changeHandler}
                                        id="dateTo"
                                        value={(props.filter.dataBirthdayTo && getDate(props.filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''}
                                        icon={true}
                                        alignRight={true}
                                    />
                                </div>
                            </div>
                            <div className="prInput">
                                <label htmlFor="responsible" className={"prInputLabel"}>Відповідальний</label>
                                <SearchBox
                                    id="allResponsible"
                                    filterId="responsible"
                                    onSearch={onSearch}
                                    options={searchList}
                                    titleField="nickname"
                                    onChange={changeHandler}
                                    value={form.responsible || null}
                                    valueField="id"
                                />
                            </div>
                            <div className="prInput">
                                <label htmlFor="gameId" className={"prInputLabel"}>Гра</label>
                                <SearchBox
                                    id="gameId"
                                    filterId="gameId"
                                    onSearch={onSearch}
                                    options={searchList}
                                    titleField="name"
                                    onChange={changeHandler}
                                    value={form.gameId || null}
                                    valueField="id"
                                />
                            </div>
                            <div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={toggleSidebar}>Відмінити</button>
                                <button className="prButton prButtonMain" onClick={acceptClick}>Застосувати</button>
                            </div>
                            <button className="w100 prButton prButtonSecond"  onClick={props.resetFilter}><Icon type='clear'></Icon> Очистити фільтр</button>
                        </div>
                    </div>    
                </div>   
            </>
        )
    }
}

export default Filter;