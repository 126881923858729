import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/Icon";
import { restore, restoreCode } from "../../../redux/actions/auth";
import { removeRestrictedCharacters } from "../../../helpers/removeRestrictedCharacters";
import { setMessage } from "../../../redux/actions/auth";
import { formatTime } from "../../../helpers/formatTime";


const RestorePage=()=>{
    const [show,setShow]=useState(false)
    const [timer, setTimer] = useState(0);
    const [isTimerActive, setIsTimerActive] = useState(false);
    const { t } = useTranslation();
    const navigate=useNavigate();
    const [form,setForm]=useState({})
    const dispatch = useDispatch();
    const [error,setError]=useState(false)
    const [errors, setErrors] = useState({});
    const [validForm, setValidForm] = useState({});

    const checkHandler = (event) => {
        const emailCheck =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{8,30}$/;
        const { id, value } = event.target;

        switch (id) {
            case "email":
                if (emailCheck.test(String(value).toLowerCase())) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_email") });
                }
                break;
           
            case "code":
                if (value.length >= 6) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalidw_code") });
                }
                break;
            case "password":
                if (passwordCheck.test(String(value))) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_password") });
                }
                break;
            default:
                setValidForm({ ...validForm, [id]: true });
                setErrors({ ...errors, [id]: "" });
        }
    };


    const changeHandler = (event) => {
        const { id, value } = event.target;
        let sanitizedValue = removeRestrictedCharacters(value);

        if (event.target.type === 'password' && sanitizedValue !== value) {
            dispatch(setMessage({
                message: 'Введено заборонений символ "<, >, /, \\, [, ], $, ; *"',
                type: 'error'
            }))
        }

        setForm({ ...form, [id]: sanitizedValue });
    };

    const sendCode=()=>{
        if(!form.email){
            setValidForm({ ...validForm, 'email': false });
            setErrors({ ...errors, 'email':  t("invalid_email") });
        }else{
            dispatch(restoreCode({'email':form.email}))
            .then((res) => {
                setTimer(60);
                setIsTimerActive(true);
            })
            .catch((e) => {
                if (e.time) {
                    setTimer(e.time);
                    setIsTimerActive(true);
                }
            });
        }
        
    }

    const restoreHandler =(e)=>{
        dispatch(restore(form)).then(res=>{
            if(res.error){
                setError(true);
            }else{
                navigate('/login')
            }
        }).catch(e=>{

        })
    }
    const cancelHandler=(e)=>{
        navigate('/login')
    }

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setIsTimerActive(false);
        }
    }, [timer]);

    return(
        <div className="prFullHeight">
            <div className="prHeaderAuth"> {t("auth_restore" )} 
                <div className="infoHelp"><Icon type='info'/></div>
            </div>
            <div className="prInput">
                    <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                    <input
                        onChange={changeHandler}
                        onBlur={checkHandler}
                        // value={form.email || ''}
                        id="email"
                        type="text"
                        className={errors.email ? "prInputInput inputError" : "prInputInput"}
                        placeholder={t("mail")}
                    />
                    {errors.email && <div className="error">{errors.email}</div>}
                </div>
            <div className="prInput">
                    <label htmlFor="code" className={errors.code ? "prInputLabel inputError" : "prInputLabel"}>{t("register_code")}</label>
                    <div className="prFlexRow" style={{ gap: '10px' }}>
                        <button
                            className="prButton prButtonMain prButtonhalfAuth"
                            onClick={sendCode}
                            disabled={isTimerActive}
                        >
                            {!isTimerActive ? t('register_code_tooltip') : formatTime(timer)}
                        </button>
                        <div className="halfWidth">
                            <input
                                maxLength={6}
                                onChange={changeHandler}
                                onBlur={checkHandler}
                                id="code"
                                type={'text'}
                                className={errors.code ? "prInputInput inputError" : "prInputInput"}
                                placeholder={t("register_code")}
                            />
                        </div>
                    </div>
                    {errors.code && <div className="error">{errors.code}</div>}
                </div>
                <div className="prInput prInputIcon">
                    <label htmlFor="password" className={errors.password ? "prInputLabel inputError" : "prInputLabel"}>{t("new_password")}</label>
                    <input
                        minLength={8}
                        maxLength={30}
                        onChange={changeHandler}
                        onBlur={checkHandler}
                        value={form.password || ''}
                        id="password"
                        type={show ? 'text' : "password"}
                        className={errors.password ? "prInputInput inputError" : "prInputInput"}
                        placeholder={t("new_password")}
                    />
                    <div className="prInputIconIcon" onClick={() => setShow(!show)}><Icon type={show ? 'eyeOpen' : 'eyeClosed'} /></div>
                    {errors.password && <div className="error">{errors.password}</div>}
                </div>

            <button onClick={restoreHandler} className="prButton prButtonMain" style={{marginBottom:'20px',marginTop:'34px',width:'100%', textTransform:'none'}}>{t("save_new_password" )}</button>
            <button onClick={cancelHandler} className="prButton prButtonSecond" style={{marginBottom:'60px',width:'100%', textTransform:'none'}}>{t("cancel" )}</button>
        
        </div>
    )

}


export default RestorePage;