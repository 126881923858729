import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '../Icon';
import { uploadFile } from "../../redux/actions/file";
import { useTranslation } from 'react-i18next';
import { setMessage } from '../../redux/actions/auth';



const FileUpload = (props) => {
  const { t } = useTranslation();
  const chunkSize = 1048576 * 1; //its  MB, increase the number measure in mb
  const [file, setFile] = useState(null);
  const [imageUrl,setImageUrl] = useState(props.imageUrl || null);
  const [fileGuid, setFileGuid] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [chunkCount, setChunkCount] = useState(0);
  const [fileToBeUpload, setFileToBeUpload] = useState({});
  const [counter, setCounter] = useState(1);
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
  const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
  const [chunkSet, setChunkSet] = useState();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const url = props.url || '';

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveFile = () => {
    setFile(null);
    setImageUrl(null);
    setFileToBeUpload({});
    setCounter(1);
    setProgress(0);
    setBeginingOfTheChunk(0);
    setEndOfTheChunk(chunkSize);
    setFileSize(0);
  };
  const handleRemoveFileTrue = (e) => {
    const item = e.currentTarget.id;
    const updatedArray = props.form[props.id].filter((el) => el.url !== item);
      props.setForm({
      ...props.form,
      [props.id]: updatedArray,
    });
  };



  const loadFile=(elem)=>{
    const _file =elem;
    if (_file.size > 10000000) {
        dispatch(setMessage({
          message:'Розмір файлу не більше 10мб'
        }))
        return;
    }

    setFileSize(_file.size);
    const _totalCount =
      _file.size % chunkSize == 0
        ? _file.size / chunkSize
        : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    setChunkCount(_totalCount);
    setCounter(1);
    setFileToBeUpload(_file);
    const _fileID = _file.name;
    setFileGuid(_fileID + Math.floor(1000 + Math.random() * 9000));
  }

  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter > 0 && counter <= chunkCount) {
      let chunk = fileToBeUpload;
      uploadChunk(chunk);
    } else if (counter > 0 && counter > chunkCount) {
      setCounter(0);
    }
  };
  const uploadChunk = async (chunk) => {
    try {
        let formData = new FormData();
        formData.append("originalFileName", chunk.name);
        formData.append("size", fileSize);
        formData.append("chunkIndex", counter);
        formData.append("totalChunks", chunkCount);
        formData.append(
          "file",
          chunk.slice(beginingOfTheChunk, endOfTheChunk, fileToBeUpload.type)
        );
        formData.append("uploadId", chunk.name + chunkSize);
        setChunkSet(chunk);
        let response = await dispatch(uploadFile(formData, url));
        const data = response.data;
        if(data.message=='Ready'){
            setFileToBeUpload({});
            setProgress(100);
            let resp = { ...props.form };
            if(props.maxFiles && props.maxFiles>0){
              if( resp[props.id]?.length>0){
                resp[props.id].push({url:data.url,name:chunk.name});
              }else{
                resp[props.id]=[];
                resp[props.id].push({url:data.url,name:chunk.name});
              }
            }else{
              resp[props.id]=data.url;
            }
            props.setForm(resp);
        }else {
            setBeginingOfTheChunk(endOfTheChunk);
            setEndOfTheChunk(endOfTheChunk + chunkSize);
              let percentage = (counter / chunkCount) * 100;
              setProgress(percentage);
        }

        console.log(data);
        
        }catch (error) {
            console.log("error", error);
        }
  
    }


    useEffect(() => {
        if (fileSize > 0 && counter > 0) {
          fileUpload(counter);
        }
    }, [fileToBeUpload,counter, progress]);


    useEffect(() => {
        if(file){
            loadFile(file);
        }
    }, [file]);



  return (
    <div className="file-upload">
      <div 
        className="drop-area" 
        onDrop={handleDrop} 
        onDragOver={handleDragOver}
      >
        <div className="icon">
          <Icon type={'cloud'}></Icon>
        </div>
        <p className='file-text'>{t('drop_here')}</p>
        <p className='file-text sep'>{t('or')}</p>
        <label className="upload-btn">
          <input 
            type="file" 
            onChange={handleFileChange} 
            style={{ display: 'none' }} 
          />
          {t('choose_file')}
        </label>
        <p className="file-info">{t('max_size_file')}: 10 MB</p>
      </div>

      {props.maxFiles && props.maxFiles>0 && props.form[props.id]?.length>0 && 
      <>
        {props.form[props.id].map(el=>{
          return(
          <div className="file-preview">
          <img 
            src={el.url} 
            alt="preview" 
            className="file-thumbnail" 
            onError={(e) => {
              // Fallback to a placeholder image
              e.target.src = 'https://playroom.technodreams.biz/assets/uploads/blank.png';
              e.target.alt = 'Preview Unavailable';
            }}
          />
          <div className="file-details">
          <p className="file-name">
                {el?.name?.length > 30 ? `${el.name?.substring(0, 30)}...` : el?.name}
            </p>
            <div className='progress-success'>{t('successful')}</div>
          </div>
          <button className="remove-file-btn" id={el.url} onClick={handleRemoveFileTrue}>
            <Icon type='close'></Icon>
          </button>
        </div>)

        }) }
      
      </>}
      
      {((file || imageUrl) && ((progress < 100 && props.maxFiles>0)||!props.maxFiles)) && (
      <div className="file-preview">
        <img
          src={imageUrl || URL.createObjectURL(file)}
          alt="preview"
          className="file-thumbnail"
          onError={(e) => {
            // Fallback to a placeholder image
            e.target.src = 'https://playroom.technodreams.biz/assets/uploads/blank.png';
            e.target.alt = 'Preview Unavailable';
          }}
        />
        <div className="file-details">
          <p className="file-name">
            {file?.name?.length > 30
              ? `${file.name.substring(0, 30)}...`
              : file?.name || imageUrl.substring(0, 30)}
          </p>
          {progress < 100 && !imageUrl ? (
            <div className="file-progress-bar">
              <div className="progress" style={{ width: progress + '%' }}></div>
            </div>
          ) : (
            <div className="progress-success">{t('successful')}</div>
          )}
        </div>
        <button className="remove-file-btn" onClick={handleRemoveFile}>
          <Icon type="close" onClick={handleRemoveFile} />
        </button>
      </div>
    )}
    </div>
  );
};

export default FileUpload;
