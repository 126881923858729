import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Filter from "../../components/Filter/Filter";
import { Icon } from "../../components/Icon";
import TeamResult from "../../components/TeamResult/TeamResult";
import TimerPR from "../../components/TimerPR/TimerPR";
import { getRoomDetail } from "../../redux/actions/room";

import { socket } from "../../socket";
import checkRole  from "../../helpers/checkRole";
import { getNicknames, getUserDetail } from "../../redux/actions/user";
import Hint from "../../components/Hint/Hint";
import { setMessage } from "../../redux/actions/auth";


const RoomItemPage=()=>{
    const currentId =useParams().id;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const user = useSelector((state) => state.currentUser.user);
    const hint = useSelector((state) => state.hints);
    
    const [ready,setReady]=useState(false);
    const [readyClicked,setReadyClicked]=useState(false);
    const [usersList,setUsersList]=useState([]);
    const [timerValue,setTimerValue]=useState(100);
    const chatContainerRef = useRef(null);
    const [isCaptain,setIsCaptain]=useState(false);
    const [myNumber,setMyNumber]=useState('');
    
    
    const { userConsent } = location.state || {};
    const [consent, setConsent] = useState(userConsent);

    const [joinRoom,setJoinRoom]=useState(false);
    const [leaveRoom,setLeaveRoom]=useState(false);
    const [callAdmin,setCallAdmin]=useState(false);
    const [callRef,setCallRef]=useState(false);
    const [callRefAdd,setCallRefAdd]=useState(false);
    const [room,setRoom]=useState({});
    const [joinRoomPlatform,setJoinRoomPlatform]=useState({});
    const list = useSelector(state=>state.list)
    const [chatTab,setChatTab]=useState('generalChat')
    const [usersA, setUsersA] = useState([]);
    const [usersB, setUsersB] = useState([]);
    const [myTeam, setMyTeam] = useState('');
    const [allChat, setAllChat] = useState([]);
    const [roomChat, setRoomChat] = useState([]);
    const [roomChatAlert, setRoomChatAllert] = useState(false);
    const [allChatAlert, setAllChatAllert] = useState(false);
    const [message, setMessages] = useState('');
    const [result, setResult] = useState({teamA:'',teamB:''});
    const [generalChatId, setGeneralChatId] = useState('');
    const [activeTeamA, setActiveTeamA] = useState('');
    const [activeTeamB, setActiveTeamB] = useState('');
    const [selsectedTab, setSelsectedTab] = useState('description');
    const [errorMessage, setErrorMessage] = useState('');
    const [inviteUser, setInviteUser] = useState(false);
    const [userSet, setUserSet] = useState([]);
    const [rules, setRules] = useState(false);

    const [showComponent, setShowComponent] = useState(false);
    

    const changeStatus=(status)=>{
        socket.emit('changeStatus',{'status':status,roomId:currentId})
    }
    
    const changeHandlerCreate = (e,result) => {
        let updatedFilterCreate = { ...joinRoomPlatform };
        if(result){
            updatedFilterCreate['result']=result;
        }else{
            const { id, value } = e.target;
            if (e.target.tagName === 'SELECT' && e.target.accessKey) {
                updatedFilterCreate[e.target.accessKey] = value;
            }else if (e.target.type==='checkbox'){
                if( e.target.checked){
                    updatedFilterCreate[id]=1;
                }else{
                    updatedFilterCreate[id]=0;
                }
            }
            else if (value.length<1) {
                delete updatedFilterCreate[id];
            }
            else {
                updatedFilterCreate[id] = value;
            }
        }
        setJoinRoomPlatform(updatedFilterCreate);
        
    };

    const joinRoomFunc =(e)=>{
        let data={...joinRoomPlatform};
        setJoinRoomPlatform({});
        data.roomId=currentId;
        data.teamId=joinRoom;
        data.gameId=room.game.id;
        socket.emit('joinRoom',data);
        setReady(false);
}

    const onJoinRoom = (team) => {
        if (!consent) {
            navigate('/conditions', { state: { fallback: location.pathname, team: team} });
        } else {
            setJoinRoom(team);
        }
    }

    const leaveRoomFunc =(e)=>{
        let data={};
        data.roomId=currentId;
        data.gameId=room.game.id;
        socket.emit('leaveRoom',data)
        navigate('/gameItem/'+room.game.id+'/rooms')
    }
    useEffect(() => {
        scrollDown();
    }, [allChat,roomChat,chatTab]); 
    function scrollDown(){
        if(chatContainerRef.current){
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }  
    }
    function onUsersRoom(value) {
        if (value.length>0&& value[0].length>0&& user?.id?.length>6 &&  value[0].some(e => e?.userId === user?.id)) {
            value[0].map(e =>{
                if( e?.userId === user?.id){
                    setMyTeam('a');
                    setMyNumber(e.teamUser.userNumber);
                    if(e.isCaptain){
                        setIsCaptain(true);
                    }else{
                        setIsCaptain(false);
                    }
                    if(e.isReady){
                        setReadyClicked(true);
                    }else{
                        setReadyClicked(false);
                    }
                }
            } )
        }
        else if (value.length>0&& value[1].length>0&& user?.id?.length>6&&  value[1].some(e => e?.userId === user?.id)) {
            value[1].map(e =>{
                if( e?.userId === user?.id){
                    setMyTeam('b');
                    setMyNumber(e.teamUser.userNumber);
                    if(e.isCaptain){
                        setIsCaptain(true);
                    }else{
                        setIsCaptain(false);
                    }
                    if(e.isReady){
                        setReadyClicked(true);
                    }else{
                        setReadyClicked(false);
                    }
                }
            } )
        }
        setUsersA(value[0]);
        setUsersB(value[1]);
    }
    useEffect(() => {
        if(myTeam.length===1){
            socket.emit('joinTeamChat',{roomId:currentId, team:myTeam})
        }
    }, [myTeam]);
    

    function onCurrentRoom(value){
        setRoom(value);
        setTimeout(() => {
            setReady(true);
        }, 100);
    }
    function saveChatId(value){
        setGeneralChatId(value);
    }

    function checkError(value){
        if(value==='Помилка при отриманні кімнати'){
            navigate('/gamesLibrary')
        }
    }

    function checkWarning(value){
        setErrorMessage(value.message);
        if(room?.game?.id){
            setTimeout(() => {
                navigate('/gameItem/'+room.game.id+'/rooms')
            }, 10000);
        }
       
    }
    function checkWereingKicked(){
        dispatch(setMessage({
            message: 'Вас було видалено із кімнати',
          })).then(res=>{
            if(room.game?.id){
                navigate('/gameItem/'+room.game.id+'/rooms')
            }else{
                navigate('/profile/myGames');
            }
          })
    }
   
    socket.on('usersRoom', onUsersRoom);
    socket.on('currentRoom', onCurrentRoom);
    socket.on('allChat', onAllChat);
    socket.on('previousTeamsMessages', onRoomChat);
    socket.on('previousGeneralMessages', onAllChat);
    socket.on('message',saveChatId );
    socket.on('error',checkError );
    socket.on('warning',checkWarning );
    socket.on('youWereKick',checkWereingKicked );
    
    const initFunc=()=>{
        dispatch(getRoomDetail({id:currentId})).then(res=>{
            // setRoom(res.room);
            socket.emit('getRoom',{roomId:currentId})
            socket.emit('joinGeneralChat',{roomId:currentId})
            if(myTeam.length===1){
                socket.emit('joinTeamChat',{roomId:currentId, team:myTeam})
            }
        })
    }

    const changeHandlerSearch=(e)=>{
        let value= e.target.value;
        dispatch(getNicknames({gameId:room.game.id,nickname:value})).then(res=>{
            if(res){
                setUsersList(res.all);
            }else{
                setUsersList([]);
            }

        })
    }

    const inviteUserSend=()=>{
       userSet.map(el=>{
            socket.emit("inviteSend",{roomId:currentId,userId:el,type:2,description:room.game.name})
       })
      
       dispatch(setMessage({
        message: 'Запрошення надіслане',
        type: 'success',
        })).then(res=>{
            setUserSet([]);
            setUsersList([]);
        })
    }

    const newMessage =(e)=>{
    let value= e.target.value;
    setMessages(value);
    }

    const readyClick =()=>{
       let data={
        roomId:currentId
       }
       setReadyClicked(true);
       socket.emit('readyUser',data)
    }
    const unreadyClick =()=>{
        let data={
        roomId:currentId
        }
        setReadyClicked(false);
        socket.emit('unreadyUser',data)
    }
   

    const sendMessage =(e)=>{
       let data={
        socketId: generalChatId,
        chatRoom: currentId,
        message: message,
        userId:user.id,
        roomId:currentId,
       }

       if(myTeam.length===1 && chatTab=='roomChat'){
           data.team=myTeam;
        socket.emit('teamMessage',data)
       }else{
        socket.emit('generalMessage',data)
       }
       setMessages('');
    }

    function onAllChat(value) {
        setAllChat(value);
        scrollDown();
        if (chatTab !== 'generalChat') {
            setAllChatAllert(true);
        }
    }

    function onRoomChat(value) {
        setRoomChat(value);
        scrollDown();
        if (chatTab !== 'roomChat') {
            setRoomChatAllert(true);
        }
    }

    // Reset alerts when switching tabs
    function handleTabSwitch(tab) {
        setAllChatAllert(false);  // Reset alert when switching to "generalChat"
        setRoomChatAllert(false);  // Reset alert when switching to "roomChat"
        setChatTab(tab);
        scrollDown();
    }

    const handleIconClick = (team, resultType) => {
        if(!isCaptain){
            return;
        }
        if (team === 'teamA') {
            setActiveTeamA(resultType);
            if (resultType === 'win') {
                setResult({ teamA: '2', teamB: '0' });
                setActiveTeamB('lose');
            } else if (resultType === 'draft') {
                setResult({ teamA: '1', teamB: '1' });
                setActiveTeamB('draft');
            } else if (resultType === 'lose') {
                setResult({ teamA: '0', teamB: '2' });
                setActiveTeamB('win');
            }
        } else if (team === 'teamB') {
            setActiveTeamB(resultType);
            if (resultType === 'win') {
                setResult({ teamA: '0', teamB: '2' });
                setActiveTeamA('lose');
            } else if (resultType === 'draft') {
                setResult({ teamA: '1', teamB: '1' });
                setActiveTeamA('draft');
            } else if (resultType === 'lose') {
                setResult({ teamA: '2', teamB: '0' });
                setActiveTeamA('win');
            }
        }
    };

    const resultReadyClick =()=>{
        let data={roomId:currentId, result:{
           ...result
          }}
        data.result.gameId=room.game.id;
        socket.emit('setResult',data);
    }
    const resultArbitrReadyClick=()=>{
        let data={roomId:currentId, result:{
            ...result
           },comment:joinRoomPlatform.comment}
           setJoinRoomPlatform({});
         data.result.gameId=room.game.id;
         socket.emit('arbitrResult',data);
    }

    const resultAcceptClick =()=>{
        let data={roomId:currentId}
        socket.emit('acceptUser',data)
    }
    const callAdminFunc=()=>{
        let data={};
        data.description=joinRoomPlatform.description;
        data.type=0;
        data.roomId=currentId;
        setJoinRoomPlatform({});
        socket.emit('notification',data);
        dispatch(setMessage({
            message: 'Адміністратора запрошено успішно',
            type: 'success',
          }))
    }
    const callRefFunc=()=>{
        let data =joinRoomPlatform;
        setJoinRoomPlatform({});
        data.roomId=currentId;
        data.type=1;
        socket.emit('arbitrationResult',data);
        dispatch(setMessage({
            message: 'Арбітра запрошено успішно',
            type: 'success',
          }))
    }
    const callRefAddFunc=()=>{
        let data =joinRoomPlatform;
        setJoinRoomPlatform({});
        data.roomId=currentId;
        data.type=1;
        socket.emit('arbitrationResultAdded',data);
    }
    const setCaptainClick =(team,userId)=>{
        let data ={
            team:team,
            userId:userId,
            roomId:currentId
        }        
        socket.emit('setCaptain',data);
        dispatch(setMessage({
            message: 'Назначено капітана',
            type: 'success',
          }))
    }

    const setKickClick =(team,userId)=>{
        let data ={
            team:team,
            userId:userId,
            roomId:currentId
        }        
        socket.emit('kickUser',data);
        dispatch(setMessage({
            message: 'Користувача видалено',
            type: 'success',
          }))

    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(room?.id+'-'+myNumber)
            .then( res => {
                dispatch(setMessage({
                    message: 'ID кімнати скопійовано',
                    type: 'success',
                }))
            })
    };
    
    useEffect(() => {
        if(!ready){
            setTimeout(() => {
                initFunc(); 
            }, 500);
        }
    }, [ready]);

    useEffect(() => {
        if(ready){
        setReady(false);
        }
        
    }, [currentId]);

    const onNicknameClick = (id) => {
        if (!checkRole(user, 'admin')) return;
        navigate(`/profile/wallet/${id}`)
    };

    useEffect(() => {
        console.log(location.state);
        const { openSidebar } = location.state || {};
        const { team } = location.state || {};

        if (openSidebar && team) {
            setJoinRoom(team);
            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [])

    useEffect(() => {
        const isFirstVisit = localStorage.getItem("firstVisit"+room?.id);
        if (!isFirstVisit) {
          dispatch(setMessage({
            message: 'Завантажуйте клієнт за <a class="hintLink" target="_blank" href="https://my.rivalsroom.com/assets/program/PlayRoomClient.Setup.exe">посиланням</a>',
            type: 'success linkHin',
          }))
          localStorage.setItem("firstVisit"+room?.id, "true");
        }
      }, []);

    if(ready && room){
        return (
            <>
            <div className="game-room">
                {/* Header Section */}
                <div className="dFlex" style={{justifyContent:'space-between'}}>
                <div className="room-info">
                    <div className="roomTitle flex">
                        <h1>
                            ID кімнати: <span style={{fontSize:'12px',verticalAlign:'middle'}}>{room.id+'-'+myNumber}</span>
                            <div className="infoHelp">
                                <Hint align='center' item={hint[59]}/>
                            </div>
                        </h1>
                        <div className="flex" style={{ alignItems: 'center', marginLeft: '40px', gap: '10px' }}>
                                <div className="pointer" onClick={copyToClipboard}>
                                    <Icon type="copy"/>
                                </div>
                                {room.status==='20ba716e-3597-4751-84b3-8fdf453cd001' &&<div className="pointer" onClick={() => setInviteUser(true)}>
                                    <Icon type="invite"/>
                                </div>}
                        </div>
                    </div>
                    <div className="roomTitleMobileBlock">
                        <div className="prflexColumn">
                            <div className="flex">
                                <h1>
                                    ID кімнати: <span style={{fontSize:'10px',verticalAlign:'middle'}}>{room.id+'-'+myNumber}</span>
                                    <div className="infoHelp">
                                        <Hint align='center' item={hint[59]}/>
                                    </div>
                                </h1>
                                <div className="flex" style={{ alignItems: 'center', marginLeft: '40px', gap: '10px' }}>
                                        <div className="pointer" onClick={copyToClipboard}>
                                            <Icon type="copy"/>
                                        </div>
                                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd001' && <div className="pointer" onClick={() => setInviteUser(true)}>
                                            <Icon type="invite"/>
                                        </div>}
                                </div>
                            </div>
                            <span className="roomType">{list[room.status]}</span>
                        </div>
                        <div className="roomItemMoney">
                            <span className="middle-sectioHeader">Вартість участі:</span>
                            <div className="roomItemMoneyValue prFlexRow">{room.price} <Icon type='fTocken'></Icon></div>
                        </div>
                    </div>
                    <p className="dFlex roomInfoLine"> 
                        <span className="roomType">{room?.isPrivate?'Приватна кімната':'Загальна кімната'}</span>
                        <span className="roomSeparator"></span>
                        <span>{room.game?.name}</span> 
                        <span className="roomSeparator"></span>
                        <span className="dFlex" style={{gap:'5px'}}>
                            <>
                                {room?.platform?.map(el=>{
                                    if(el==='09ba716e-3597-4751-84b3-8fdf453cd072'){
                                        return(
                                        <Icon type='pc'></Icon>)
                                    }else if(el=='09ba716e-3597-4751-84b3-8fdf453cd073'){
                                    return(
                                        <Icon type='ps'></Icon>
                                        
                                    )}
                                    else if(el=='09ba716e-3597-4751-84b3-8fdf453cd071'){
                                        return(<Icon type='xbox'></Icon>)
                                    }else{
                                        return null
                                    }
                                })}
                            </>
                        </span> 
                        <span className="roomSeparator"></span>
                        <span>{room.format} x {room.format}</span> 
                        <span className="roomSeparator"></span>
                        <span className="roomType">{list[room.status]}</span>
                    </p>
                    <div className="roomDetailsMobile">
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">Гра</div>
                            <div className="roomItemBlockValue">{room.game?.name}</div>
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">Формат</div>
                            <div className="roomItemBlockValue">{room.format} х {room.format}</div>
                        </div>
                        <div className="roomItemMobileBlock">
                            <div className="roomItemBlockHeader">Тип</div>
                            <div className="roomItemBlockValue">{room.isPrivateRoom===1?'Приватна':'Публічна'}</div>
                        </div>
                        <span className="dFlex" style={{gap:'5px', width: '100%'}}>
                            <>
                                {room?.platform?.map(el=>{
                                    if(el==='09ba716e-3597-4751-84b3-8fdf453cd072'){
                                        return(
                                        <Icon type='pc'></Icon>)
                                    }else if(el=='09ba716e-3597-4751-84b3-8fdf453cd073'){
                                    return(
                                        <Icon type='ps'></Icon>
                                        
                                    )}
                                    else if(el=='09ba716e-3597-4751-84b3-8fdf453cd071'){
                                        return(<Icon type='xbox'></Icon>)
                                    }else{
                                        return null
                                    }
                                })}
                            </>
                        </span> 
                    </div>
                    <div className="room-status-block-tablet">
                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd001' && <div className="middle-section">
                            <div>
                                <div className="timerWait">Очікуємо учасників 
                                    <div className="infoHelp">
                                        <Hint align='center' item={hint[61]}/>
                                    </div>
                                </div>
                                <div className="timeSmallText">
                                    До завершеня подачі запитів залишилось:
                                </div>
                                <div className="timerTime">
                                    <TimerPR initialSeconds={room.termExistence} />
                                </div>
                            </div>
                        </div>
                        }
                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd002' && <div className="middle-section">
                            <div>
                                <div className="timerWait">Підтвердіть готовність
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[63]}/>
                                        </div>
                                </div>
                                <div className="timeSmallText">
                                До завершеня підтвердження готовності залишилось:
                                </div>
                                <div className="timerTime">
                                    <TimerPR initialSeconds={room.termExistence} />
                                </div>
                                {readyClicked?<button className="prButton prButtonMain" style={{marginTop:'40px'}} onClick={unreadyClick} >Скасувати</button>:<button className="prButton prButtonMain" style={{marginTop:'40px'}} onClick={readyClick} >Підтвердити</button>}
                            </div>
                        </div>
                        }
                        {(room.status==='20ba716e-3597-4751-84b3-8fdf453cd003' || room.status==='20ba716e-3597-4751-84b3-8fdf453cd004')  && <div className="middle-section">
                            <div>
                                <div className="timerWait">Фінальний результат
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[64]}/>
                                        </div></div>
                                <div className="timeSmallText">
                                Інформація з’явиться після того, як всі сторони погодяться із результатами
                                </div>
                                <div className="timerTime">
                                    <TimerPR initialSeconds={room.termExistence} />
                                </div>
                                {room?.result?.nickname?
                                <div className="resultBlock">
                                    <div className="headerResultNick">Користувач <span>{room?.result?.nickname}</span> запроповнував результат</div>
                                    <div>
                                        <div className="dFlex resultTeam ready">
                                            <div className="triangular"><Icon type="triangular" /></div>
                                            <div className={room.result?.teamA=='2'?"resultTeamName green":room.result?.teamA=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 1</div>
                                            {room.result?.teamA=='2' && <div
                                                className={'active resultIcon green'}
                                            >
                                                <Icon type="win" />
                                            </div>}
                                            {room.result?.teamA=='1' && <div
                                                className={'active resultIcon orange' }
                                            >
                                                <Icon type="draft" />
                                            </div>}
                                            {room.result?.teamA=='0' && <div
                                                className={'active resultIcon red'}
                                            >
                                                <Icon type="loose" />
                                            </div>}
                                        </div>
                                        <div className="dFlex resultTeam ready">
                                            <div className="square"><Icon type="square" /></div>
                                            <div className={room.result?.teamB=='2'?"resultTeamName green":room.result?.teamB=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 2</div>
                                            {room.result?.teamB=='2' && <div
                                                className={'active resultIcon green'}
                                            >
                                                <Icon type="win" />
                                            </div>}
                                            {room.result?.teamB=='1' && <div
                                                className={'active resultIcon orange' }
                                            >
                                                <Icon type="draft" />
                                            </div>}
                                            {room.result?.teamB=='0' && <div
                                                className={'active resultIcon red'}
                                            >
                                                <Icon type="loose" />
                                            </div>}
                                        </div>
                                    </div>
                                    <button className="prButton prButtonMain" disabled={room.result?.teamA?.length===0 || !isCaptain} style={{ marginTop: '40px', padding:'12px 0px', width:"100%",  whiteSpace: 'nowrap' }} onClick={resultAcceptClick}>
                                        Погодитись
                                    </button>
                                    <button className="rules-btn prButton prButtonSecond" style={{marginTop:'12px', width:'100%'}} onClick={()=>setCallRef(true)} >Запросити арбітра</button>
                                </div>
                                :<div className="resultBlock">
                                    <TeamResult handleIconClick={handleIconClick} activeTeamA={activeTeamA} activeTeamB={activeTeamB}></TeamResult>
                                    <button className="prButton prButtonMain" disabled={result.teamA.length===0} style={{ marginTop: '40px', padding:'12px 0px', width:"100%",  whiteSpace: 'nowrap' }} onClick={resultReadyClick}>
                                        Запропонувати результат
                                    </button>
                                    <button className="rules-btn prButton prButtonSecond" style={{marginTop:'12px', width:'100%'}} onClick={()=>setCallRef(true)} >Запросити арбітра</button>
                                </div>}
                            </div>
                        </div>
                        }
                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd005' && <div className="middle-section">
                            <div>
                                <div className="timerWait">Фінальний результат
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[64]}/>
                                        </div>
                                </div>
                                <div className="timeSmallText">
                                Інформація з’явиться після того, як всі сторони погодяться із результатами
                                </div>
                                <div className="timerTime">
                                    <TimerPR timerValue={true} setTimerValue={setTimerValue} initialSeconds={room.termExistence} />
                                </div>


                                {checkRole(user,'arbitr')?<div className="resultBlock">
                                    <TeamResult isArbitr={true} handleIconClick={handleIconClick} activeTeamA={activeTeamA} activeTeamB={activeTeamB}></TeamResult>
                                    
                                    <div style={{marginTop:'20px'}}>
                                        <input type="text" className={"prInputInput"} id='comment' onChange={changeHandlerCreate} value={joinRoomPlatform.comment||''} placeholder="Введіть коментар..." />
                                    </div>
                                    <button className="prButton prButtonMain" disabled={result.teamA.length===0} style={{ marginTop: '40px', padding:'12px 0px', width:"100%",  whiteSpace: 'nowrap' }} onClick={resultArbitrReadyClick}>
                                        Результат Арбітражу
                                    </button>
                                </div>:<>
                                    <div className="attentionBlockInfo dFlex" style={{marginTop:'20px'}}>
                                    <div>
                                        <Icon type='warning'></Icon>
                                    </div>
                                    <div>
                                        У кімнату запрошено Арбітра
                                        </div>
                                    </div>
                                    <div className="timeSmallText">
                                        Якщо Ви маєте файли, які допоможуть арбітру приняти рішення, просимо завантажити їх до звершення відліку таймера
                                    </div>
                                    <button className="prButton prButtonMain" disabled={room.termExistence<1 || timerValue==0} style={{marginTop:'12px', width:'100%'}} onClick={()=>setCallRefAdd(true)} >Додати докази</button>
                                </>}
                                
                            </div>
                        </div>
                        }
                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd006' && <div className="middle-section">
                            {room.result?.teamA && <div>
                                <div className="timerWait" style={{marginTop:'33px',marginBottom:'20px'}}>Фінальний результат
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[65]}/>
                                        </div>
                                </div>
                                    <div>
                                        <div className="dFlex resultTeam ready">
                                            <div className="triangular"><Icon type="triangular" /></div>
                                            <div className={room.result?.teamA=='2'?"resultTeamName green":room.result?.teamA=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 1</div>
                                            {room.result?.teamA=='2' && <div
                                                className={'active resultIcon green'}
                                            >
                                                <Icon type="win" />
                                            </div>}
                                            {room.result?.teamA=='1' && <div
                                                className={'active resultIcon orange' }
                                            >
                                                <Icon type="draft" />
                                            </div>}
                                            {room.result?.teamA=='0' && <div
                                                className={'active resultIcon red'}
                                            >
                                                <Icon type="loose" />
                                            </div>}
                                        </div>
                                        <div className="dFlex resultTeam ready">
                                            <div className="square"><Icon type="square" /></div>
                                            <div className={room.result?.teamB=='2'?"resultTeamName green":room.result?.teamB=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 2</div>
                                            {room.result?.teamB=='2' && <div
                                                className={'active resultIcon green'}
                                            >
                                                <Icon type="win" />
                                            </div>}
                                            {room.result?.teamB=='1' && <div
                                                className={'active resultIcon orange' }
                                            >
                                                <Icon type="draft" />
                                            </div>}
                                            {room.result?.teamB=='0' && <div
                                                className={'active resultIcon red'}
                                            >
                                                <Icon type="loose" />
                                            </div>}
                                        </div>
                                    </div>
                                {room.refComment?.length>1 && <>
                                    <div className="timerWait" style={{marginTop:'20px',marginBottom:"7px"}}>Коментар Арбітра</div>
                                    <div className="refCommentText">{room.refComment}</div>
                                </>
                                }
                                {/* <button className="prButton prButtonMain" style={{marginTop:'40px'}} onClick={readyClick} >Підтвердити</button> */}
                            </div>}
                        </div>
                        }
                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd008' && <div className="middle-section">
                            {errorMessage && <div className="errorMessageResult">
                                {errorMessage}
                            </div>}
                            {errorMessage && <div className="errorMessageResult">
                                {'Кімнату закрито, а токени повернуто на рахунки гравців'}
                            </div>}
                        </div>
                        }
                    </div>
                    <div className="room-actions-tablet">
                        <div className="prFlexRow">
                            <button
                                className="rules-btn prButton prButtonSecond"
                                onClick={() => navigate('/conditions')}
                            >
                                Правила кімнати
                            </button>
                            <button className="leave-btn prButton prButtonSecond" disabled={(room.status!=='20ba716e-3597-4751-84b3-8fdf453cd006'&& room.status!=='20ba716e-3597-4751-84b3-8fdf453cd001'&&room.status!=='20ba716e-3597-4751-84b3-8fdf453cd002')} onClick={()=>{if(myTeam.length===0||room.status==='20ba716e-3597-4751-84b3-8fdf453cd006'||room.status==='20ba716e-3597-4751-84b3-8fdf453cd008'){navigate('/gameItem/'+room.game.id+'/rooms')}else{setLeaveRoom(true)}}}>Покинути кімнату</button>
                        </div>
                        <button disabled={myTeam.length === 0} className="admin-btn prButton prButtonMain" onClick={()=>setCallAdmin(true)}>Викликати адміністратора</button>
                        {checkRole(user,'admin')?
                        <>
                            <button className="admin-btn prButton prButtonMain" onClick={()=>changeStatus('20ba716e-3597-4751-84b3-8fdf453cd001')}>Повернути до пошуку учасників</button>
                            <button className="admin-btn prButton prButtonMain" onClick={()=>changeStatus('20ba716e-3597-4751-84b3-8fdf453cd002')}>Повернути до готовості</button>
                            <button className="admin-btn prButton prButtonMain" onClick={()=>changeStatus('20ba716e-3597-4751-84b3-8fdf453cd003')}>Повернути до очікування результату</button>
                        </>
                        :""}
                    </div>

                    <div className="main-body">
                        <div className="team">
                            <h2>Команда 1 <Icon type='triangular'></Icon></h2>
                            <div className="team-member">
                                {usersA?.length > 0 &&
                                    usersA.map((el) => { 
                                    if(el?.userId){
                                        return(
                                        <div className="userItem left" key={el.id}>
                                        {!el.isCaptain &&((isCaptain && myTeam==='a')||checkRole(user,'arbitr'))&&<div className="userItemCaptain" onClick={()=>setCaptainClick('a',el.teamUser?.id)}>c</div>}
                                        {((isCaptain && myTeam==='a' && (room.status=="20ba716e-3597-4751-84b3-8fdf453cd001" || room.status=="20ba716e-3597-4751-84b3-8fdf453cd002"))||checkRole(user,'arbitr'))&&  <div className="userItemCaptain userItemCaptainkick" onClick={()=>setKickClick('a',el.teamUser?.id)}>x</div>}
                                        <div>
                                            <div
                                                className="userItemNick"
                                                onClick={() => {
                                                    onNicknameClick(el.userId);
                                                }}
                                            >
                                                {el.teamUser.nickname} <Icon type="editName" />
                                            </div>
                                            <div className="prFlexRow statistic">
                                                <div className="prFlexRow white">
                                                    <Icon type="playController" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow green">
                                                    <Icon type="win" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow red">
                                                    <Icon type="loose" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow orange">
                                                    <Icon type="draft" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{backgroundImage:`url(${el?.teamUser?.avatarImg})`}} className={room.status==='20ba716e-3597-4751-84b3-8fdf453cd002'?el.isReady==1?"headerUserAvatar ready":"headerUserAvatar notready":room.status==='20ba716e-3597-4751-84b3-8fdf453cd004'?el.isAccept==1?"headerUserAvatar ready":"headerUserAvatar notready":"headerUserAvatar"}>
                                            {!el?.teamUser?.avatarImg && <Icon type="avatar" />}
                                            <div
                                                className={
                                                    (el?.teamUser.reputation > 6 && "headerUserAvatarReputation") ||
                                                    ((el?.teamUser.reputation < 7 && el?.teamUser.reputation > 3) && "headerUserAvatarReputation caution") ||
                                                    (el?.teamUser.reputation < 4 && "headerUserAvatarReputation error")
                                                }
                                            >
                                                {el.teamUser.reputation}
                                            </div>
                                            {el.isCaptain==1 && <div className="captain">C</div>}
                                        </div>
                                    </div>)
                                    }else{
                                        if(myTeam.length===1){
                                            return(
                                                <div className="beUser dFlex" onClick={()=>setInviteUser(true)}>
                                                    <div className="beUserText">
                                                    Очікування учасника
                                                    </div>
                                                    <div className="beUserPlus">
                                                        ?
                                                    </div>
                                                </div>)
                                        }else{
                                        return(
                                        <div className="beUser dFlex" onClick={()=>{onJoinRoom('a')}}>
                                            <div className="beUserText">
                                                Стати учасником
                                            </div>
                                            <div className="beUserPlus">
                                                +
                                            </div>
                                        </div>)
                                        }
                                    }
                                
                                })}  
                            </div>
                        </div>
                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd001' && <div className="middle-section">
                            <div >
                                <div className="middle-sectioHeader">
                                Вартість участі:
                                </div>
                                <div className="middle-sectioPrice">
                                    {room.price}
                                    <Icon type='fTocken'></Icon>
                                </div>
                            </div>
                                <div>
                                    <div className="timerWait">Очікуємо учасників
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[61]}/>
                                        </div>
                                    </div>
                                    <div className="timeSmallText">
                                        До завершеня подачі запитів залишилось:
                                    </div>
                                    <div className="timerTime">
                                        <TimerPR initialSeconds={room.termExistence} />
                                    </div>
                                </div>
                            <div>

                            </div>
                        </div>
                        }
                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd002' && <div className="middle-section">
                            <div >
                                <div className="middle-sectioHeader">
                                Бюджет кімнати:
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[62]}/>
                                        </div>
                                </div>
                                <div className="middle-sectioPrice">
                                    {room.amountCollected}
                                    <Icon type='fTocken'></Icon>
                                </div>
                            </div>
                                <div>
                                    <div className="timerWait">Підтвердіть готовність 
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[63]}/>
                                        </div>
                                    </div>
                                    <div className="timeSmallText">
                                    До завершеня підтвердження готовності залишилось:
                                    </div>
                                    <div className="timerTime">
                                        <TimerPR initialSeconds={room.termExistence} />
                                    </div>
                                    {readyClicked?<button className="prButton prButtonMain" style={{marginTop:'40px'}} onClick={unreadyClick} >Скасувати</button>:<button className="prButton prButtonMain" style={{marginTop:'40px'}} onClick={readyClick} >Підтвердити</button>}
                                </div>
                            <div>
                            </div>
                        </div>
                        }
                        {(room.status==='20ba716e-3597-4751-84b3-8fdf453cd003' || room.status==='20ba716e-3597-4751-84b3-8fdf453cd004')  && <div className="middle-section">
                            <div >
                                <div className="middle-sectioHeader">
                                Бюджет кімнати:
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[62]}/>
                                        </div>
                                </div>
                                <div className="middle-sectioPrice">
                                    {room.amountCollected}
                                    <Icon type='fTocken'></Icon>
                                </div>
                            </div>
                                <div>
                                    <div className="timerWait">Фінальний результат
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[66]}/>
                                        </div>
                                    </div>
                                    <div className="timeSmallText">
                                    Інформація з’явиться після того, як всі сторони погодяться із результатами
                                    </div>
                                    <div className="timerTime">
                                        <TimerPR initialSeconds={room.termExistence} />
                                    </div>
                                    {room?.result?.nickname?
                                    <div className="resultBlock">
                                        <div className="headerResultNick">Користувач <span>{room?.result?.nickname}</span> запроповнував результат</div>
                                        <div>
                                            <div className="dFlex resultTeam ready">
                                                <div className="triangular"><Icon type="triangular" /></div>
                                                <div className={room.result?.teamA=='2'?"resultTeamName green":room.result?.teamA=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 1</div>
                                                {room.result?.teamA=='2' && <div
                                                    className={'active resultIcon green'}
                                                >
                                                    <Icon type="win" />
                                                </div>}
                                                {room.result?.teamA=='1' && <div
                                                    className={'active resultIcon orange' }
                                                >
                                                    <Icon type="draft" />
                                                </div>}
                                                {room.result?.teamA=='0' && <div
                                                    className={'active resultIcon red'}
                                                >
                                                    <Icon type="loose" />
                                                </div>}
                                            </div>
                                            <div className="dFlex resultTeam ready">
                                                <div className="square"><Icon type="square" /></div>
                                                <div className={room.result?.teamB=='2'?"resultTeamName green":room.result?.teamB=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 2</div>
                                                {room.result?.teamB=='2' && <div
                                                    className={'active resultIcon green'}
                                                >
                                                    <Icon type="win" />
                                                </div>}
                                                {room.result?.teamB=='1' && <div
                                                    className={'active resultIcon orange' }
                                                >
                                                    <Icon type="draft" />
                                                </div>}
                                                {room.result?.teamB=='0' && <div
                                                    className={'active resultIcon red'}
                                                >
                                                    <Icon type="loose" />
                                                </div>}
                                            </div>
                                        </div>
                                        <button className="prButton prButtonMain" disabled={room.result?.teamA?.length===0 || !isCaptain}  style={{ marginTop: '40px', padding:'12px 0px', width:"100%",  whiteSpace: 'nowrap' }} onClick={resultAcceptClick}>
                                            Погодитись
                                        </button>
                                        <button className="rules-btn prButton prButtonSecond" style={{marginTop:'12px', width:'100%'}} onClick={()=>setCallRef(true)} >Запросити арбітра</button>
                                    </div>
                                    :<div className="resultBlock">
                                        <TeamResult disabled={!isCaptain} handleIconClick={handleIconClick} activeTeamA={activeTeamA} activeTeamB={activeTeamB}></TeamResult>
                                        <button className="prButton prButtonMain" disabled={result.teamA.length===0 || !isCaptain} style={{ marginTop: '40px', padding:'12px 0px', width:"100%",  whiteSpace: 'nowrap' }} onClick={resultReadyClick}>
                                            Запропонувати результат
                                        </button>
                                        <button className="rules-btn prButton prButtonSecond" style={{marginTop:'12px', width:'100%'}} onClick={()=>setCallRef(true)} >Запросити арбітра</button>
                                    </div>}
                                </div>
                            <div>
                            </div>
                        </div>
                        }
                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd005' && <div className="middle-section">
                            <div >
                                <div className="middle-sectioHeader">
                                Бюджет кімнати:
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[62]}/>
                                        </div>
                                </div>
                                <div className="middle-sectioPrice">
                                    {room.amountCollected}
                                    <Icon type='fTocken'></Icon>
                                </div>
                            </div>
                                <div>
                                    <div className="timerWait">Фінальний результат
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[66]}/>
                                        </div>
                                    </div>
                                    <div className="timeSmallText">
                                    Інформація з’явиться після того, як всі сторони погодяться із результатами
                                    </div>
                                    <div className="timerTime">
                                        <TimerPR timerValue={true} setTimerValue={setTimerValue} initialSeconds={room.termExistence} />
                                    </div>


                                    {checkRole(user,'arbitr')?<div className="resultBlock">
                                        <TeamResult isArbitr={true} handleIconClick={handleIconClick} activeTeamA={activeTeamA} activeTeamB={activeTeamB}></TeamResult>
                                        
                                        <div style={{marginTop:'20px'}}>
                                            <input type="text" className={"prInputInput"} id='comment' onChange={changeHandlerCreate} value={joinRoomPlatform.comment||''} placeholder="Введіть коментар..." />
                                        </div>
                                        <button className="prButton prButtonMain" disabled={result.teamA.length===0} style={{ marginTop: '40px', padding:'12px 0px', width:"100%",  whiteSpace: 'nowrap' }} onClick={resultArbitrReadyClick}>
                                            Результат Арбітражу
                                        </button>
                                    </div>:<>
                                        <div className="attentionBlockInfo dFlex" style={{marginTop:'20px'}}>
                                        <div>
                                            <Icon type='warning'></Icon>
                                        </div>
                                        <div>
                                            У кімнату запрошено Арбітра
                                            </div>
                                        </div>
                                        <div className="timeSmallText">
                                            Якщо Ви маєте файли, які допоможуть арбітру приняти рішення, просимо завантажити їх до звершення відліку таймера
                                        </div>
                                        <button className="prButton prButtonMain" disabled={room.termExistence<1 || timerValue==0} style={{marginTop:'12px', width:'100%'}} onClick={()=>setCallRefAdd(true)} >Додати докази</button>
                                    </>}
                                    
                                </div>
                            <div>
                            </div>
                        </div>
                        }
                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd006' && <div className="middle-section">
                            <div >
                                <div className="middle-sectioHeader">
                                Бюджет кімнати:
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[62]}/>
                                        </div>
                                </div>
                                <div className="middle-sectioPrice">
                                    {room.amountCollected}
                                    <Icon type='fTocken'></Icon>
                                </div>
                            </div>
                                {room.result?.teamA && <div>
                                    <div className="timerWait" style={{marginTop:'33px',marginBottom:'20px'}}>Фінальний результат
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[65]}/>
                                        </div>
                                    </div>
                                        <div>
                                            <div className="dFlex resultTeam ready">
                                                <div className="triangular"><Icon type="triangular" /></div>
                                                <div className={room.result?.teamA=='2'?"resultTeamName green":room.result?.teamA=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 1</div>
                                                {room.result?.teamA=='2' && <div
                                                    className={'active resultIcon green'}
                                                >
                                                    <Icon type="win" />
                                                </div>}
                                                {room.result?.teamA=='1' && <div
                                                    className={'active resultIcon orange' }
                                                >
                                                    <Icon type="draft" />
                                                </div>}
                                                {room.result?.teamA=='0' && <div
                                                    className={'active resultIcon red'}
                                                >
                                                    <Icon type="loose" />
                                                </div>}
                                            </div>
                                            <div className="dFlex resultTeam ready">
                                                <div className="square"><Icon type="square" /></div>
                                                <div className={room.result?.teamB=='2'?"resultTeamName green":room.result?.teamB=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 2</div>
                                                {room.result?.teamB=='2' && <div
                                                    className={'active resultIcon green'}
                                                >
                                                    <Icon type="win" />
                                                </div>}
                                                {room.result?.teamB=='1' && <div
                                                    className={'active resultIcon orange' }
                                                >
                                                    <Icon type="draft" />
                                                </div>}
                                                {room.result?.teamB=='0' && <div
                                                    className={'active resultIcon red'}
                                                >
                                                    <Icon type="loose" />
                                                </div>}
                                            </div>
                                        </div>
                                    {room.refComment?.length>1 && <>
                                        <div className="timerWait" style={{marginTop:'20px',marginBottom:"7px"}}>Коментар Арбітра</div>
                                        <div className="refCommentText">{room.refComment}</div>
                                    </>
                                    }
                                    {/* <button className="prButton prButtonMain" style={{marginTop:'40px'}} onClick={readyClick} >Підтвердити</button> */}
                                </div>}
                            <div>
                            </div>
                        </div>
                        }
                        {room.status==='20ba716e-3597-4751-84b3-8fdf453cd008' && <div className="middle-section">
                            <div >
                                <div className="middle-sectioHeader">
                                Бюджет кімнати:
                                        <div className="infoHelp">
                                            <Hint align='center' item={hint[62]}/>
                                        </div>
                                </div>
                                <div className="middle-sectioPrice">
                                    {room.amountCollected}
                                    <Icon type='fTocken'></Icon>
                                </div>
                            </div>

                                {errorMessage && <div className="errorMessageResult">
                                    {errorMessage}
                                </div>}
                                {errorMessage && <div className="errorMessageResult">
                                    {'Кімнату закрито, а токени повернуто на рахунки гравців'}
                                </div>}
                            <div>
                            </div>
                        </div>
                        }
                        <div className="team right">
                            <h2><Icon type='square'></Icon> Команда 2</h2>
                            <div className="team-member">
                            {usersB?.length > 0 &&
                                    usersB.map((el) => { 
                                    if(el?.userId){
                                        return(
                                    <div className="userItem" key={el.id}>
                                        {!el.isCaptain && ((isCaptain && myTeam==='b')||checkRole(user,'arbitr'))&& <div className="userItemCaptain" onClick={()=>setCaptainClick('b',el.teamUser?.id)}>c</div>}
                                        {((isCaptain && myTeam==='b' && (room.status=="20ba716e-3597-4751-84b3-8fdf453cd001" || room.status=="20ba716e-3597-4751-84b3-8fdf453cd002"))||checkRole(user,'arbitr'))&& <div className="userItemCaptain userItemCaptainkick" onClick={()=>setKickClick('b',el.teamUser?.id)}>x</div>}
                                        <div style={{backgroundImage:`url(${el?.teamUser?.avatarImg})`}} className={room.status==='20ba716e-3597-4751-84b3-8fdf453cd002'?el.isReady==1?"headerUserAvatar ready":"headerUserAvatar notready":room.status==='20ba716e-3597-4751-84b3-8fdf453cd004'?el.isAccept==1?"headerUserAvatar ready":"headerUserAvatar notready":"headerUserAvatar"}>
                                            {!el?.teamUser?.avatarImg && <Icon type="avatar" />}
                                            <div
                                                className={
                                                    (el?.teamUser.reputation > 6 && "headerUserAvatarReputation") ||
                                                    ((el?.teamUser.reputation < 7 && el?.teamUser.reputation > 3) && "headerUserAvatarReputation caution") ||
                                                    (el?.teamUser.reputation < 4 && "headerUserAvatarReputation error")
                                                }
                                            >
                                                {el.teamUser.reputation}
                                            </div>
                                            {el.isCaptain==1 && <div className="captain">C</div>}
                                        </div>
                                        <div>
                                            <div
                                                className="userItemNick"
                                                onClick={() => {
                                                    onNicknameClick(el.userId);
                                                }}
                                            >
                                                {el.teamUser.nickname} <Icon type="editName" />
                                            </div>
                                            <div className="prFlexRow statistic">
                                                <div className="prFlexRow white">
                                                    <Icon type="playController" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow green">
                                                    <Icon type="win" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow red">
                                                    <Icon type="loose" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow orange">
                                                    <Icon type="draft" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                                    }else{
                                        if(myTeam.length===1){
                                        return(
                                            <div className="beUser right dFlex" onClick={()=>setInviteUser(true)}>
                                                <div className="beUserPlus">
                                                    ?
                                                </div>
                                                <div className="beUserText">
                                                    Очікування учасника
                                                </div>
    
                                            </div>)

                                        }else{
                                        return(
                                    <div className="beUser right dFlex" onClick={()=>{onJoinRoom('b')}}>
                                        <div className="beUserPlus">
                                            +
                                        </div>
                                        <div className="beUserText">
                                            Стати учасником
                                        </div>

                                    </div>)}
                                    }
                            })}  
                            </div>
                    </div>
                </div>
                <div className="room-description">
                    <div className="dFlex roomDescriptionTabs">
                        <h3 onClick={()=>setSelsectedTab('description')} className={selsectedTab=='description'?"roomDescriptionHeader active":"roomDescriptionHeader"}>Опис кімнати</h3>
                    {room.status=='20ba716e-3597-4751-84b3-8fdf453cd005' && <h3 onClick={()=>setSelsectedTab('arb')} className={selsectedTab=='arb'?"roomDescriptionHeader active":"roomDescriptionHeader"}>Арбітраж</h3>}
                    </div>
                    
                    {selsectedTab=='description' && <p className="roomDescriptionText">
                    {room.description?.split("\n").map((line, index) => (
                                            <p key={index}>{line}</p>
                                        ))}
                    </p>}
                    {selsectedTab=='arb' &&room.status=='20ba716e-3597-4751-84b3-8fdf453cd005' && room.appealArbitrator.length>0&&
                    <p className="roomDescriptionText">
                        {room.appealArbitrator.map((el,index)=>{
                            return(
                                <>
                                {index===0&&
                                    <h3>Ініціатор виклику арбітра</h3>
                                }
                                <div className="dFlex infoFull">
                                    <div className="infoFullPos">
                                    <div className="userItem left" key={el.id}>
                                        
                                        <div>
                                            <div className="userItemNick">
                                                {el?.notificationUser?.nickname} <Icon type="editName" />
                                            </div>
                                            <div className="prFlexRow statistic">
                                                <div className="prFlexRow white">
                                                    <Icon type="playController" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow green">
                                                    <Icon type="win" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow red">
                                                    <Icon type="loose" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow orange">
                                                    <Icon type="draft" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{backgroundImage:`url(${el?.notificationUser.avatarImg})`}} className={room.status==='20ba716e-3597-4751-84b3-8fdf453cd002'?el.isReady==1?"headerUserAvatar ready":"headerUserAvatar notready":room.status==='20ba716e-3597-4751-84b3-8fdf453cd004'?el.isAccept==1?"headerUserAvatar ready":"headerUserAvatar notready":"headerUserAvatar"}>
                                            {!el?.notificationUser.avatarImg && <Icon type="avatar" />}
                                            <div
                                                className={
                                                    (el?.notificationUser?.reputation > 6 && "headerUserAvatarReputation") ||
                                                    ((el?.notificationUser?.reputation < 7 && el?.notificationUser?.reputation > 3) && "headerUserAvatarReputation caution") ||
                                                    (el?.notificationUser?.reputation < 4 && "headerUserAvatarReputation error")
                                                }
                                            >
                                                {el.notificationUser?.reputation}
                                            </div>
                                            {el.isCaptain==1 && <div className="captain">C</div>}
                                        </div>
                                    </div>
                                    

                                    </div>
                                    <div className="information">
                                        <p>{el.description?.split("\n").map((line, index) => (
                                            <p key={index}>{line}</p>
                                        ))}</p>
                                        {index===0&&<div className="informationResult">
                                            <div className="dFlex resultTeam ready">
                                                    <div className="triangular"><Icon type="triangular" /></div>
                                                    <div className={el.result?.teamA=='2'?"resultTeamName green":el.result?.teamA=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 1</div>
                                                    {el.result?.teamA=='2' && <div
                                                        className={'active resultIcon green'}
                                                    >
                                                        <Icon type="win" />
                                                    </div>}
                                                    {el.result?.teamA=='1' && <div
                                                        className={'active resultIcon orange' }
                                                    >
                                                        <Icon type="draft" />
                                                    </div>}
                                                    {el.result?.teamA=='0' && <div
                                                        className={'active resultIcon red'}
                                                    >
                                                        <Icon type="loose" />
                                                    </div>}
                                                </div>
                                                <div className="dFlex resultTeam ready">
                                                    <div className="square"><Icon type="square" /></div>
                                                    <div className={el.result?.teamB=='2'?"resultTeamName green":el.result?.teamB=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 2</div>
                                                    {el.result?.teamB=='2' && <div
                                                        className={'active resultIcon green'}
                                                    >
                                                        <Icon type="win" />
                                                    </div>}
                                                    {el.result?.teamB=='1' && <div
                                                        className={'active resultIcon orange' }
                                                    >
                                                        <Icon type="draft" />
                                                    </div>}
                                                    {el.result?.teamB=='0' && <div
                                                        className={'active resultIcon red'}
                                                    >
                                                        <Icon type="loose" />
                                                    </div>}
                                                </div>
                                            </div>}

                                        <div className="dFlex informationBlock">
                                                <div>
                                                    <div className="infoHead">Файли з клієнта </div>
                                                    { el.isClientUsed===0 && <div style={{  whiteSpace:'nowrap'}}>Не використовує клієнт</div>}
                                                </div>
                                                <div className="infoSeparator"></div>
                                                <div>
                                                    <div className="infoHead">Посилання</div>
                                                    <div className="dFlex"> <Icon type='link'></Icon>{el.link}</div>
                                                </div>

                                        </div>
                                    </div>
                                </div>
                            
                                
                                </>
                            )
                        })
                        }
                    </p>
                    }
                </div>
            </div>
                <div className="room-actions">
                    <div className="prFlexRow">
                        <button
                            className="rules-btn prButton prButtonSecond"
                            onClick={() => setRules(true)}
                        >
                            Правила кімнати
                        </button>
                        <button className="leave-btn prButton prButtonSecond" disabled={(room.status!=='20ba716e-3597-4751-84b3-8fdf453cd006'&& room.status!=='20ba716e-3597-4751-84b3-8fdf453cd001'&&room.status!=='20ba716e-3597-4751-84b3-8fdf453cd002')} onClick={()=>{if(myTeam.length===0||room.status==='20ba716e-3597-4751-84b3-8fdf453cd006'||room.status==='20ba716e-3597-4751-84b3-8fdf453cd008'){navigate('/gameItem/'+room.game.id+'/rooms')}else{setLeaveRoom(true)}}}>Покинути кімнату</button>
                    </div>
                    <button disabled={myTeam.length === 0} className="admin-btn prButton prButtonMain" onClick={()=>setCallAdmin(true)}>Викликати адміністратора</button>
                    {checkRole(user,'admin')?
                    <>
                        <button className="admin-btn prButton prButtonMain" onClick={()=>changeStatus('20ba716e-3597-4751-84b3-8fdf453cd001')}>Повернути до пошуку учасників</button>
                        <button className="admin-btn prButton prButtonMain" onClick={()=>changeStatus('20ba716e-3597-4751-84b3-8fdf453cd002')}>Повернути до готовості</button>
                        <button className="admin-btn prButton prButtonMain" onClick={()=>changeStatus('20ba716e-3597-4751-84b3-8fdf453cd003')}>Повернути до очікування результату</button>
                    </>
                    :""}
                
                    <div className="chat-section" style={{marginTop:'40px'}}>
                        <div className="chatSectionHeader">Чат-рум 
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[60]}/>
                                </div></div>
                        <div className="chatSectionSeparator"></div>
                        <div className="chat-header">
                            <button 
                                onClick={() =>  {if(chatTab === "roomChat"){handleTabSwitch('generalChat')}}} 
                                className={chatTab === "generalChat" ? 'chat-tab active' : allChatAlert ? "chat-tab alert" : "chat-tab"}
                            >
                                Загальний чат
                            </button>

                            {myTeam.length === 1 && +room.format>1 &&(
                                <button 
                                    onClick={() =>  {if(chatTab === "generalChat"){handleTabSwitch('roomChat')}}} 
                                    className={chatTab === "roomChat" ? 'chat-tab active' : roomChatAlert ? "chat-tab alert" : "chat-tab"}
                                >
                                    Командний чат
                                </button>
                            )}
                        </div>
                        <div className="chatBodySeparator"></div>
                        <div className="chat-body" ref={chatContainerRef}>
                            {chatTab=='generalChat' && allChat.length>0?
                            allChat.map(el=>{
                                return(
                                    <div className={el.userId === user.id ?"chat-message dFlex my":"chat-message dFlex"}>
                                        {el.userId !== user.id && <div className="chatMessageAvatar" style={{backgroundImage:`url(${el?.chatUser?.avatarImg})`}}> {!el?.chatUser?.avatarImg && <Icon type="avatar" />}</div>}
                                        <div className="chatMessageText">
                                            <div className="chatMessageTextNick">{el.chatUser.nickname}</div>
                                            <div className="chatMessageTextText">{el.message}</div>
                                        </div>
                                        {el.userId === user.id && <div className="chatMessageAvatar" style={{backgroundImage:`url(${el?.chatUser?.avatarImg})`}}> {!el?.chatUser?.avatarImg && <Icon type="avatar" />}</div>}
                                    </div>
                                )
                            })
                            :''}
                            {chatTab=='roomChat' && roomChat.length>0?
                            roomChat.map(el=>{
                                return(
                                    <div className={el.userId === user.id ?"chat-message dFlex my":"chat-message dFlex"}>
                                        {el.userId !== user.id && <div className="chatMessageAvatar" style={{backgroundImage:`url(${el?.chatUserTeam?.avatarImg})`}}> {!el?.chatUserTeam?.avatarImg && <Icon type="avatar" />}</div>}
                                        <div className="chatMessageText">
                                            <div className="chatMessageTextNick">{el.chatUserTeam.nickname}</div>
                                            <div className="chatMessageTextText">{el.message}</div>
                                        </div>
                                        {el.userId === user.id && <div className="chatMessageAvatar" style={{backgroundImage:`url(${el?.chatUserTeam?.avatarImg})`}}> {!el?.chatUserTeam?.avatarImg && <Icon type="avatar" />}</div>}
                                    </div> 
                                )
                            })
                            :''}
                    </div>
                    <div className="chat-footer top">
                        <div className="chatMessageAvatar" style={{backgroundImage:`url(${user?.avatarImg})`}}></div>

                        <input type="text" className={"prInputInput"} onChange={newMessage} onKeyDown={(event) => {if (event.key === "Enter") {sendMessage();}}} value={message||''} placeholder="Введіть повідомлення..." />
                        
                    </div>
                    <div className="chat-footer">
                        <button className="chatFooterButton prButton prButtonMain" onClick={sendMessage}>Надіслати</button>
                    </div>
                    
                    </div>
                    <div className="room-description-tablet">
                    <div className="dFlex roomDescriptionTabs">
                        <h3 onClick={()=>setSelsectedTab('description')} className={selsectedTab=='description'?"roomDescriptionHeader active":"roomDescriptionHeader"}>Опис кімнати</h3>
                    {room.status=='20ba716e-3597-4751-84b3-8fdf453cd005' && <h3 onClick={()=>setSelsectedTab('arb')} className={selsectedTab=='arb'?"roomDescriptionHeader active":"roomDescriptionHeader"}>Арбітраж</h3>}
                    </div>
                    
                    {selsectedTab=='description' && <p className="roomDescriptionText">
                    {room.description?.split("\n").map((line, index) => (
                                            <p key={index}>{line}</p>
                                        ))}
                    </p>}
                    {selsectedTab=='arb' &&room.status=='20ba716e-3597-4751-84b3-8fdf453cd005' && room.appealArbitrator.length>0&&
                    <p className="roomDescriptionText">
                        {room.appealArbitrator.map((el,index)=>{
                            return(
                                <>
                                {index===0&&
                                    <h3>Ініціатор виклику арбітра</h3>
                                }
                                <div className="dFlex infoFull">
                                    <div className="infoFullPos">
                                    <div className="userItem left" key={el.id}>
                                        
                                        <div>
                                            <div className="userItemNick">
                                                {el?.notificationUser?.nickname} <Icon type="editName" />
                                            </div>
                                            <div className="prFlexRow statistic">
                                                <div className="prFlexRow white">
                                                    <Icon type="playController" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow green">
                                                    <Icon type="win" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow red">
                                                    <Icon type="loose" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                                <div className="prFlexRow orange">
                                                    <Icon type="draft" />
                                                    <div className="statisticValue">&nbsp;: 0</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{backgroundImage:`url(${el?.notificationUser?.avatarImg})`}} className={room.status==='20ba716e-3597-4751-84b3-8fdf453cd002'?el.isReady==1?"headerUserAvatar ready":"headerUserAvatar notready":room.status==='20ba716e-3597-4751-84b3-8fdf453cd004'?el.isAccept==1?"headerUserAvatar ready":"headerUserAvatar notready":"headerUserAvatar"}>
                                            {!el?.notificationUser?.avatarImg && <Icon type="avatar" />}
                                            <div
                                                className={
                                                    (el?.notificationUser?.reputation > 6 && "headerUserAvatarReputation") ||
                                                    ((el?.notificationUser?.reputation < 7 && el?.notificationUser?.reputation > 3) && "headerUserAvatarReputation caution") ||
                                                    (el?.notificationUser?.reputation < 4 && "headerUserAvatarReputation error")
                                                }
                                            >
                                                {el.notificationUser?.reputation}
                                            </div>
                                            {el.isCaptain==1 && <div className="captain">C</div>}
                                        </div>
                                    </div>
                                    

                                    </div>
                                    <div className="information">
                                        <p>{el.description?.split("\n").map((line, index) => (
                                            <p key={index}>{line}</p>
                                        ))}</p>
                                        {index===0&&<div className="informationResult">
                                            <div className="dFlex resultTeam ready">
                                                    <div className="triangular"><Icon type="triangular" /></div>
                                                    <div className={el.result?.teamA=='2'?"resultTeamName green":el.result?.teamA=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 1</div>
                                                    {el.result?.teamA=='2' && <div
                                                        className={'active resultIcon green'}
                                                    >
                                                        <Icon type="win" />
                                                    </div>}
                                                    {el.result?.teamA=='1' && <div
                                                        className={'active resultIcon orange' }
                                                    >
                                                        <Icon type="draft" />
                                                    </div>}
                                                    {el.result?.teamA=='0' && <div
                                                        className={'active resultIcon red'}
                                                    >
                                                        <Icon type="loose" />
                                                    </div>}
                                                </div>
                                                <div className="dFlex resultTeam ready">
                                                    <div className="square"><Icon type="square" /></div>
                                                    <div className={el.result?.teamB=='2'?"resultTeamName green":el.result?.teamB=='1'?"resultTeamName orange":"resultTeamName red"}>Команда 2</div>
                                                    {el.result?.teamB=='2' && <div
                                                        className={'active resultIcon green'}
                                                    >
                                                        <Icon type="win" />
                                                    </div>}
                                                    {el.result?.teamB=='1' && <div
                                                        className={'active resultIcon orange' }
                                                    >
                                                        <Icon type="draft" />
                                                    </div>}
                                                    {el.result?.teamB=='0' && <div
                                                        className={'active resultIcon red'}
                                                    >
                                                        <Icon type="loose" />
                                                    </div>}
                                                </div>
                                            </div>}

                                        <div className="dFlex informationBlock">
                                                <div>
                                                    <div className="infoHead">Файли з клієнта </div>
                                                    { el.isClientUsed===0 && <div style={{  whiteSpace:'nowrap'}}>Не використовує клієнт</div>}
                                                </div>
                                                <div className="infoSeparator"></div>
                                                <div>
                                                    <div className="infoHead">Посилання</div>
                                                    <div className="dFlex"> <Icon type='link'></Icon>{el.link}</div>
                                                </div>

                                        </div>
                                    </div>
                                </div>
                            
                                
                                </>
                            )
                        })
                        }
                    </p>
                    }
                    </div>
                </div>
                </div>
            </div>
            {joinRoom && <Filter game={room} filter={joinRoomPlatform} type={'joinRoom'}  accept={joinRoomFunc}  setFilter={setJoinRoomPlatform} onChange={changeHandlerCreate} isOpen={joinRoom} setIsOpen={setJoinRoom}></Filter>}
            {leaveRoom && <Filter game={room} type={'leaveRoom'}  accept={leaveRoomFunc} isOpen={leaveRoom} setIsOpen={setLeaveRoom}></Filter>}
            {callAdmin && <Filter game={room} type={'callAdmin'}  setFilter={setJoinRoomPlatform}  filter={joinRoomPlatform} onChange={changeHandlerCreate} accept={callAdminFunc} isOpen={callAdmin} setIsOpen={setCallAdmin} ></Filter>}
            {callRef && <Filter game={room} type={'callRef'}  setFilter={setJoinRoomPlatform}  filter={joinRoomPlatform} onChange={changeHandlerCreate} accept={callRefFunc} isOpen={callRef} setIsOpen={setCallRef} ></Filter>}
            {callRefAdd && <Filter game={room} type={'callRefAdd'}  setFilter={setJoinRoomPlatform}  filter={joinRoomPlatform} onChange={changeHandlerCreate} accept={callRefAddFunc} isOpen={callRefAdd} setIsOpen={setCallRefAdd} ></Filter>}
            {rules && <Filter game={room} type={'rules'} isOpen={rules} setIsOpen={setRules} ></Filter>}
            
            {inviteUser && <Filter game={room} userSet={userSet} setUserSet={setUserSet} users={usersList} type={'inviteUser'} setFilter={setJoinRoomPlatform}  filter={joinRoomPlatform}  onChange={changeHandlerSearch} accept={inviteUserSend} isOpen={inviteUser} setIsOpen={setInviteUser}></Filter>}
            
            </>
            );

    }  
};

export default RoomItemPage;