import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import checkRole from '../../helpers/checkRole';
import { logout } from '../../redux/actions/auth';
import { socket } from '../../socket';
import CreateRoom from '../CreateRoom/CreateRoom';
import { Icon } from '../Icon';
import TimerPR from '../TimerPR/TimerPR';
import start from '../../assets/sounds/start.mp3';
import waiting from '../../assets/sounds/waiting.mp3';



const HeaderAuth = (props) => {
    const { t } = useTranslation();
    const {user} = useSelector((state) => state.currentUser);
    const list = useSelector(state=>state.list)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [openInner, setOpenInner] = useState(false);
    const [openRight, setOpenRight] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openNotifications2, setOpenNotifications2] = useState(false);
    const [openDownloads, setOpenDownloads] = useState(false);
    
    const [openNotificationsOuter, setOpenNotificationsOuter] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [openRoomsList, setOpenRoomsList] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const menuRef = useRef(null); // Ref for the left side menu
    const menuRefInner = useRef(null); 
    const menuRightRef = useRef(null); // Ref for the right side menu
    const menuNotificationRef = useRef(null); // Ref for the right side menu
    const menuNotificationRef2 = useRef(null); // Ref for the right side menu
    const menuDownloadRef = useRef(null); // Ref for the right side menu
    
    const menuNotificationRefOuter = useRef(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const logOutClick = () => {
        dispatch(logout());
        navigate('/login');
    };

    const setTopicClick = (topic) => {
        props.setTopic(topic);
        localStorage.setItem('Theme', topic);
    };

    const openMenu = () => {
        setOpen(!open);
    };

    const openMenuRight = () => {
        setOpenRight(!openRight);
    };

    const openMenuInner = () => {
        setOpenInner(!openInner);
    }

    const openNotification = () => {
        if(!openNotifications){
            socket.emit('privateMessageGet',{});
            socket.emit('privateMessageRead',{});
        }
        setOpenNotifications(!openNotifications);
    };
    const openDownload = () => {
        setOpenDownloads(!openDownloads);
    };


    const openNotificationOuter = () => {
        if(!openNotificationsOuter){
            socket.emit('privateMessageGet',{});
            socket.emit('privateMessageRead',{});
        }
        setOpenNotificationsOuter(!openNotificationsOuter);
    };

    const getNotifications=(resp)=>{
        setNotifications(resp);
    }
    const wasCreated=(resp)=>{
        navigate('/room/'+resp)
    }

    const soundStart = new Audio(start);
    const soundWaiting = new Audio(waiting);
    const lastStatuses = useRef({}); // Зберігає останні статуси кімнат
    const playedStatuses = useRef(new Set(JSON.parse(localStorage.getItem('playedStatuses')) || []));
    
    const getMyRooms = (resp) => {
        setRooms(resp);
        resp.forEach(room => {
            const prevStatus = lastStatuses.current[room.id];
            const newStatus = room.status;

            if (prevStatus !== newStatus && !playedStatuses.current.has(newStatus)) {
                if (newStatus === "20ba716e-3597-4751-84b3-8fdf453cd002") {
                    playSound(soundWaiting);
                } else if (newStatus === "20ba716e-3597-4751-84b3-8fdf453cd003") {
                    playSound(soundStart);
                }
                playedStatuses.current.add(newStatus);
                localStorage.setItem('playedStatuses', JSON.stringify([...playedStatuses.current]));
            }
            lastStatuses.current[room.id] = newStatus; // Оновлюємо останній статус кімнати
        });
    };
    const playSound = (audio) => {
        setTimeout(() => {
            audio.play();
        }, 0);
        setTimeout(() => {
            audio.pause();
            audio.currentTime = 0; 
        }, 5000);
    };


    const setOpenRoomsListClick=()=>{
        setOpenRoomsList(!openRoomsList);
        socket.emit('getMyRooms',{});
    }

    socket.on('privateMessage',getNotifications);
    socket.on('roomsMyList',getMyRooms);
    socket.on('wasCreated',wasCreated);
    useEffect(() => {
        if(user?.role){
            setIsAdmin(checkRole(user,'admin'));
        }
    }, [user]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && 
                !menuRef.current.contains(event.target)) {
                setOpen(false);
            }
            if (menuRightRef.current && !menuRightRef.current.contains(event.target)) {
                setOpenRight(false);
            }
            if(menuNotificationRef.current && 
                !menuNotificationRef.current.contains(event.target)) {
                setOpenNotifications(false);
            }
            if(menuDownloadRef.current && 
                !menuDownloadRef.current.contains(event.target)) {
                setOpenDownloads(false);
            }
            
            if(menuNotificationRef2.current && 
                !menuNotificationRef2.current.contains(event.target)) {
                setOpenNotifications2(false);
            }
            
            if (menuRefInner.current && !menuRefInner.current.contains(event.target)) {
                setOpenInner(false);
            }
            if(menuNotificationRef.current && !menuNotificationRef.current.contains(event.target)) {
                setOpenNotifications(false);
            }
            if(menuNotificationRefOuter.current && !menuNotificationRefOuter.current.contains(event.target)) {
                setOpenNotificationsOuter(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, [menuRef, menuRefInner, menuRightRef, menuNotificationRef,menuDownloadRef, menuNotificationRefOuter]);

    return (
        <>
        <div className='headerPosition'>
            <div className='headerAuth prFlexRow'>
                <div className='headerLogo'>
                    <div className='headerLogoLogo pointer' onClick={() => navigate('/')}>
                        <Icon type='logo'></Icon>
                    </div>

                    <div className="headerLogoText" onClick={() => navigate('/')}>
                        <Icon type="rivalsLogoText"/>
                    </div>

                    <div className='headerTheme'>
                        <div onClick={() => setTopicClick('black')} className={props.topic === 'white' ? 'selected' : 'selected active'}>
                            <Icon type='darkTheme'></Icon>
                        </div>
                        <div onClick={() => setTopicClick('white')} className={props.topic === 'black' ? 'selected' : 'selected active'}>
                            <Icon type='lightTheme'></Icon>
                        </div>
                    </div>
                </div>
                <div className='prFlexRow' style={{ gap: '30px' }}>
                <div className='emptyDiv' style={user && checkRole(user, 'arbitr') ? { display:'none' } : {}}/>
                {user && checkRole(user,'arbitr')?
                <div>
                    
                </div>
                :rooms.length>0 && <div className='userAttempt prFlexRow'>
                        <div className='addUserAttempt' onClick={()=>setIsOpenCreate(true)}> <Icon type='plus'></Icon></div>
                        <div style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+rooms[0].id)}>
                            <div className='prFlexRow'>
                                <Icon type='playController'></Icon>
                                <div className='userAttemptStatus'>
                                    {list[rooms[0].status]}
                                </div>
                            </div>
                            <div className='userAttemptTime'>
                                <TimerPR initialSeconds={rooms[0].termExistence} />
                            </div>
                        </div>
                        <div className='numberUserAttempt' onClick={setOpenRoomsListClick}>{rooms.length}</div>
                    
                        {openRoomsList &&
                        <div className='userAttemptList'>
                            {rooms.map((el,index)=>{
                                if(index===0){
                                    return null;
                                }
                                return(
                                    <div className='userAttempt prFlexRow'>
                                        <div style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+el.id)}>
                                            <div className='prFlexRow'>
                                                <Icon type='playController'></Icon>
                                                <div className='userAttemptStatus'>
                                                    {list[el.status]}
                                                </div>
                                            </div>
                                            <div className='userAttemptTime'>
                                                <TimerPR initialSeconds={el.termExistence} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                })}
                        </div>
                         }
                    </div>}
                    

                    {user && checkRole(user,'arbitr')?
                    <div className='adminHeaderIcons'>
                        <div onClick={()=>navigate('/addminreq')}><Icon type='addminreq'></Icon></div>
                        <div onClick={()=>navigate('/arbitr')}><Icon type='arbitr'></Icon></div>
                        <div onClick={()=>navigate('/verificationlist')}><Icon type='verification'></Icon></div>
                        <div onClick={()=>navigate('/supportList')}><Icon type='support'></Icon></div>
                    </div>
                    : <div className='prFlexRow headerMoney'>
                        <div className='headerMoneyWallet'><Icon type='wallet'></Icon></div>
                        <div>
                            <div className='prFlexRow'>
                                <Icon type='fTocken'></Icon>
                                <div>
                                    <span className='prTockenEvailable'>{user?.userMoney?.balanceFiat} </span>/<span className='prTockenBlocked'> {user?.userMoney?.balanceFiatFrozen}</span>
                                </div>
                            </div>
                            <div className='prFlexRow'>
                                <Icon type='cTocken'></Icon>
                                <div>
                                    <span className='prTockenEvailable'>{user?.userMoney?.balanceCoin} </span>/<span className='prTockenBlocked'> {user?.userMoney?.balanceCoinFrozen}</span>
                                </div>
                            </div>
                        </div>
                    </div>}

                    <div className='prFlexRow headerUser sideMenu' onClick={openMenu} ref={menuRef}>
                        <div className='headerUserAvatar'  style={{backgroundImage:`url(${user?.avatarImg})`}} >
                            {!user?.avatarImg && <Icon type='avatar'></Icon>}
                        </div>
                        <div>
                            <div className='nickname'>
                                {user?.nickname}
                            </div>
                            {user && checkRole(user,'arbitr')?
                            <div className='nickname' style={{fontSize:'12px'}}>
                                {user &&(checkRole(user,'superAdmin') && 'Супер Адмін') || (checkRole(user,'admin')&&'Admin:' ) ||  (checkRole(user,'arbitr') && 'Арбітр')}
                            </div>
                            :<div className='prFlexRow'>
                                <span className='reputation'>{t('reputation')}:</span> 
                                <div className={
                                        (user?.reputation > 6 && "reputationNumber") ||
                                        ((user?.reputation < 7 && user?.reputation > 3) && "reputationNumber caution") ||
                                        (user?.reputation < 4 && "reputationNumber error")
                                }>{user?.reputation} {(user?.reputation > 4 && t('points')) || (user?.reputation > 1 && t('pointss')) || (user?.reputation === 1 && t('point')) || (user?.reputation === 0 && t('points'))}
                            </div>
                            </div>}
                        </div>

                        {open && <div className='openSideMenu'>
                            <div className='openSideMenuNavigation'>
                                <div onClick={()=>navigate('/profile/rooms')}>{t('rooms')}</div>
                                <div onClick={()=>navigate('/profile/wallet')}>{t('wallet')}</div>
                                {user.isStatus==0 && <div onClick={()=>navigate('/verification')}>{t('accaunt_verifcation')}</div>}
                                <div onClick={()=>navigate('/profile/settings')}>{t('settings')}</div>
                            </div>
                            <div className='openSideMenuExit' onClick={logOutClick}>
                                <Icon type='exit' />  {t('log_out')}
                            </div>
                        </div>}
                    </div>

                    <div className='headerButtons prFlexRow'>
                       {user && !checkRole(user,'arbitr') && <div onClick={()=>navigate('/supportList')}><Icon type='support'></Icon></div>}
                       
                        <div onClick={openNotification}>
                            <Icon type='notification'></Icon>
                        </div>
                        <div onClick={openDownload}>
                            <Icon type='download'></Icon>
                        </div>
                        <div onClick={openMenuRight}>
                                <Icon type='more'></Icon>
                            </div>
                        <div className='sideMenu' ref={openDownloads?menuDownloadRef:openNotifications?menuNotificationRef:menuRightRef}>
                            {openDownloads && <div className='openSideMenu openSideMenuBig downloadCli openSideMenuRight'>
                                <div className='openSideMenuNotifications'>
                                    <h3>Завантажити клієнт можна за <a target={'_blank'} href="https://my.rivalsroom.com/assets/program/PlayRoomClient.Setup.exe">посиланням</a></h3>
                                </div>
                            </div>}
                            {openNotifications && <div className='openSideMenu openSideMenuBig openSideMenuRight'>
                                <div className='openSideMenuNotifications'>
                                    <h3>Повідомлення</h3>
                                    <div className='openSideMenuNotificationsBody'>
                                    {notifications.map(el=>{
                                        return(
                                        <div className='notificationItem' onClick={(e) => {navigate('/room/'+el.roomId);openNotification();}}>
                                            <div className='dFlex'>
                                                <div className={el.isRead===1?'read':'read unread'}>
                                                </div>
                                                <div>
                                                    <div className='notificationItemHeader'>
                                                        {el.type===0 && 'Запит адміністратора: '}
                                                        {el.type===1 && 'Запит арбітра: '}
                                                        {el.type===2 && `Вас запрошено в гру ${el.description}: `}
                                                        <span>{el.nickname}</span>
                                                    </div>
                                                   {el.type!==2 && <div className='notificationItemText'>
                                                        {el.description}
                                                    </div>}
                                                    <div className='dFlex notificationItemTime'>
                                                        <Icon type='time'></Icon>  {el.time}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        )
                                        
                                    })}
                                     </div>
                                </div>
                        </div>}
                        {openRight && <div className='openSideMenu openSideMenuBig openSideMenuRight'>
                                <div className='openSideMenuNavigation'>
                                    {isAdmin && <div  onClick={() => {navigate('/users');openMenuRight();}}>{t('users_all')}</div>}
                                    {/* <div>{t('Dictionary')}</div> */}
                                    {isAdmin && <div onClick={() => {navigate('/directory');openMenuRight();}}>{t('directory')}</div>}
                                    {isAdmin &&<div onClick={() => {navigate('/games');openMenuRight();}}>{t('games')}</div>}
                                    <div onClick={() => {navigate('/gamesLibrary');openMenuRight();}}>{t('games_library')}</div>
                                    {isAdmin && <div onClick={() => {navigate('/allRooms');openMenuRight();}}>{t('Всі кімнати')}</div>}
                                    {isAdmin &&<div onClick={() => {navigate('/transactionList');openMenuRight();}}>{t('Всі транзакції')}</div>}
                                    {isAdmin &&<div onClick={() => {navigate('/blacklist');openMenuRight();}}>{t('Чорний список')}</div>}
                                    {isAdmin &&<div onClick={() => {navigate('/hints');openMenuRight();}}>{t('Текстові константи')}</div>}
                                    {isAdmin &&<div onClick={() => {navigate('/logs');openMenuRight();}}>{t('Журнал дій')}</div>}
                                    {isAdmin &&<div onClick={() => {navigate('/settings');openMenuRight();}}>{t('Налаштування системи')}</div>}
                                </div>
                                <div className='sideMenuMobile'>
                                    <div
                                        className='prFlexRow headerUser sideMenu'
                                        onClick={openMenuInner}
                                        ref={menuRefInner}
                                    >
                                        <div className='prFlexRow' style={{ gap: '10px' }}>
                                            <div className='headerUserAvatar' style={{backgroundImage:`url(${user?.avatarImg})`}}>
                                            {!user?.avatarImg && <Icon type='avatar'></Icon>}
                                            </div>
                                            <div>
                                                <div className='nickname'>
                                                    {user?.nickname}
                                                </div>
                                                {user && checkRole(user,'arbitr')?
                                                <div className='nickname' style={{fontSize:'12px'}}>
                                                    {user &&(checkRole(user,'superAdmin') && 'Супер Адмін') || (checkRole(user,'admin')&&'Admin:' ) ||  (checkRole(user,'arbitr') && 'Арбітр')}
                                                </div>
                                                :<div className='prFlexRow'>
                                                    <span className='reputation'>{t('reputation')}:</span> 
                                                    <div className={
                                                            (user?.reputation > 6 && "reputationNumber") ||
                                                            ((user?.reputation < 7 && user?.reputation > 3) && "reputationNumber caution") ||
                                                            (user?.reputation < 4 && "reputationNumber error")
                                                    }>{user?.reputation} {(user?.reputation > 4 && t('points')) || (user?.reputation > 1 && t('pointss')) || (user?.reputation === 1 && t('point')) || (user?.reputation === 0 && t('points'))}
                                                </div>
                                                </div>}
                                            </div>
                                        </div>

                                        {openInner && 
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%'
                                                }}
                                            >
                                                <div className='openSideMenuNavigation'>
                                                    <div onClick={()=>navigate('/profile/rooms')}>{t('rooms')}</div>
                                                    <div onClick={()=>navigate('/profile/wallet')}>{t('wallet')}</div>
                                                    <div onClick={()=>navigate('/profile/varification')}>{t('accaunt_verifcation')}</div>
                                                    <div onClick={()=>navigate('/profile/settings')}>{t('settings')}</div>
                                                </div>
                                                <div className='openSideMenuExit' onClick={logOutClick}>
                                                    <Icon type='exit' />  {t('log_out')}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='prFlexRow headerMoney'>
                                        <div className='headerMoneyWallet'><Icon type='wallet'></Icon></div>
                                        <div>
                                            <div className='prFlexRow'>
                                                <Icon type='fTocken'></Icon>
                                                <div>
                                                    <span className='prTockenEvailable'>{user?.userMoney?.balanceFiat} </span>/<span className='prTockenBlocked'> {user?.userMoney?.balanceFiatFrozen}</span>
                                                </div>
                                            </div>
                                            <div className='prFlexRow'>
                                                <Icon type='cTocken'></Icon>
                                                <div>
                                                    <span className='prTockenEvailable'>{user?.userMoney?.balanceCoin} </span>/<span className='prTockenBlocked'> {user?.userMoney?.balanceCoinFrozen}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className='prFlexRow'
                                        style={{
                                            paddingRight: '50px',
                                        }}
                                    >
                                        <div className='headerTheme'>
                                            <div onClick={() => setTopicClick('black')} className={props.topic === 'white' ? 'selected' : 'selected active'}>
                                                <Icon type='darkTheme'></Icon>
                                            </div>
                                            <div onClick={() => setTopicClick('white')} className={props.topic === 'black' ? 'selected' : 'selected active'}>
                                                <Icon type='lightTheme'></Icon>
                                            </div>
                                        </div>
                                        <div className='prFlexRow' style={{ gap: '20px' }}>
                                            {user && !checkRole(user,'arbitr') && 
                                                <div
                                                    onClick={()=> {
                                                        navigate('/supportList');
                                                        openMenuRight();
                                                    }}
                                                >
                                                    <Icon type='support'></Icon>
                                                </div>
                                            }
                                            <div onClick={openNotification}>
                                                <Icon type='notification'></Icon>
                                            </div>
                                        </div>
                                    </div>
                                    {openNotifications && <div className='openSideMenuNotifications'>
                                        <h3>Повідомлення</h3>
                                        <div className='openSideMenuNotificationsBody'>
                                            {notifications.map(el=>{
                                                return(
                                                <div className='notificationItem'  onClick={() => {navigate('/room/'+el.roomId);openNotification();}}>
                                                    <div className='dFlex'>
                                                        <div className={el.isRead===1?'read':'read unread'}>
                                                        </div>
                                                        <div>
                                                            <div className='notificationItemHeader'>
                                                                {el.type===0 && 'Запит адміністратора: '}
                                                                {el.type===1 && 'Запит арбітра: '}
                                                                {el.type===2 && `Вас запрошено в гру ${el.description}: `}
                                                                <span>{el.nickname}</span>
                                                            </div>
                                                        {el.type!==2 && <div className='notificationItemText'>
                                                                {el.description}
                                                            </div>}
                                                            <div className='dFlex notificationItemTime'>
                                                                <Icon type='time'></Icon>  {el.time}
                                                            </div>
                                                        </div>
                                                    </div>   
                                                </div>
                                                )  
                                            })}
                                        </div>
                                    </div>}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

            {isOpenCreate && <CreateRoom isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} ></CreateRoom>}
        </>
    );
};

export default HeaderAuth;
