import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Icon } from "../../components/Icon";
import { t } from "i18next";
import Hint from "../../components/Hint/Hint";
import { useSelector } from "react-redux";

const RoomConditionsPage = () => {
  const [open, setOpen] = useState('');
  const hint =useSelector(state=>state.hints);
  const navigate = useNavigate();
  const location = useLocation();
  const { fallback } = location.state || {};
  const { team } = location.state || {};

  const openClick = (e) => {
    const id = e.currentTarget.id;
		
		open === id ? setOpen('') : setOpen(id);
  };

  const onConsentClick = () => {
    console.log('conditions', location.state);
    
    navigate(fallback, { state: { userConsent: true, openSidebar: true, team: team } });
  }

  console.log('location', location);

  return (
    <div className="flexCol alignCenter conditionsPage">
      <div className="flexCol alignCenter">
        <button
          className="prButton prButtonMain prFlexRow"
          style={{ gap: '15px', marginBottom: '39px' }}
          onClick={onConsentClick}
        >
          <Icon type="arrowReturn" />
          {t("Погодитись")}
        </button>
        <h2 >Умови участі в кімнаті
          <div className="infoHelp">
            <Hint align='center' item={hint[67]}/>
          </div>
        </h2>
        <p className="prInputLabel" style={{ marginTop: '8px' }}>
          Це обов’язкова сторінка, із якою повинен ознайомитись кожен учасник кімнати. Будь ласка, зверніть увагу на блок питання/відповідь.
        </p>
      </div>

      <div className="attentionPart dFlex" style={{ marginTop: '39px' }}>
          <div className="warningModalIcon"> <Icon type='warning'></Icon></div>
          <div>
              <h3>Важливо</h3>
              <div>Інформація в цьому розділі може вберегти Вас від втрати грошей</div>
          </div>
      </div>

      <div className="prFlexRow conditionsImagesWrapper" style={{ marginTop: '64px' }}>
        <div className="conditionsImage">
          <img src="/images/accept.jpeg" alt="Document Example" />
        </div>
        <Icon type="rightArrowLg"/>
        <div className="conditionsImage">
          <img src="/images/accept.jpeg" alt="Document Example" />
        </div>
        <Icon type="rightArrowLg"/>
        <div className="conditionsImage">
          <img src="/images/accept.jpeg" alt="Document Example" />
        </div>
        <Icon type="rightArrowLg"/>
        <div className="conditionsImage">
          <img src="/images/accept.jpeg" alt="Document Example" />
        </div>
      </div>

      <div className="faq">
        <div className="prFlexRow directoryGroup">
            <div id='first-question' onClick={openClick} className="prFlexRow directoryGroupName">
              <div className={open=='first-question'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                <Icon type='arrowForward'></Icon>
              </div>
              <div>First question</div>
            </div>
            {open === 'first-question' && (
              <p className="prInputLabel answer">58.11.16: Maps and hydrographic or similar diagrams, printed, not in book form.</p>
            )}
        </div>
        <div className="prFlexRow directoryGroup">
            <div id='second-question' onClick={openClick} className="prFlexRow directoryGroupName">
              <div className={open=='second-question'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                <Icon type='arrowForward'></Icon>
              </div>
              <div>First question</div>
            </div>
            {open === 'second-question' && (
              <p className="prInputLabel answer">58.11.16: Maps and hydrographic or similar diagrams, printed, not in book form.</p>
            )}
        </div>
        <div className="prFlexRow directoryGroup">
            <div id='third-question' onClick={openClick} className="prFlexRow directoryGroupName">
              <div className={open=='third-question'?"arrowForwardOpen":"arrowForwardOpen arrowForwardOpenTransform"}>
                <Icon type='arrowForward'></Icon>
              </div>
              <div>First question</div>
            </div>
            {open === 'third-question' && (
              <p className="prInputLabel answer">58.11.16: Maps and hydrographic or similar diagrams, printed, not in book form.</p>
            )}
        </div>
      </div>

      <div className="flexCol alignCenter" style={{ marginTop: '90px' }}>
				<h2>
					Договір оферта
        </h2>
				<p className="prInputLabel" style={{ marginTop: '40px' }}>
					Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia perspiciatis magnam harum est animi consequatur qui enim corrupti fugit quos voluptatem labore, dignissimos, ipsum optio incidunt quae natus quaerat cum.
					Vel odio voluptate impedit officiis! Ratione, asperiores voluptatibus. Cum aliquid eum ab at illum et omnis velit pariatur odio nam, animi reiciendis itaque, dignissimos voluptate dolore quaerat tempora corporis sequi?
					Repellat eligendi inventore iste dicta quia dolor, autem ipsa, animi culpa exercitationem sit. Eveniet amet labore ullam optio. Architecto, maxime vitae nam odit officia earum! Cumque tempora voluptatum veniam error.
					Iusto, alias natus eveniet velit ducimus libero numquam qui quisquam sed mollitia cumque, sit ab quo molestias quis doloremque! Vitae voluptates repudiandae maxime beatae sit porro facilis asperiores esse excepturi?
					Esse, recusandae consequatur ipsam iusto mollitia illo molestias omnis fugit? Eveniet quaerat necessitatibus debitis quisquam deserunt nobis dignissimos atque fuga pariatur magni, delectus maxime vero odit? Iure delectus numquam laboriosam?
					Nostrum, eius esse, atque odit aut, magnam eaque illo recusandae beatae reiciendis neque? Et magni reprehenderit autem inventore reiciendis possimus nulla? Ducimus inventore, adipisci consequatur doloremque fuga iste porro accusamus!
					Id nesciunt sit iusto quis. Nesciunt maxime praesentium deleniti repudiandae? Dolorum tenetur natus, temporibus culpa ipsa sed incidunt, nam architecto repellat, itaque aut sequi reprehenderit necessitatibus non. Sed, tempora aut?
					Molestias neque vel, ratione hic maiores esse harum, iste aliquid sequi nostrum, perferendis in explicabo quod sint reprehenderit unde? Nam dolorem laboriosam, beatae dolorum delectus dicta a nisi et earum?
					Dolores suscipit molestiae dolorem facilis expedita omnis deserunt nesciunt minima ab est vero repudiandae obcaecati deleniti dolorum non vitae voluptatibus doloremque quaerat voluptatem culpa harum, itaque tempora sunt. Quisquam, dignissimos!
					Tempora, pariatur. Et quos eaque molestiae! Accusantium, distinctio quae. Earum dicta nisi totam dolore reprehenderit, error quae placeat nihil consequuntur quis saepe ratione. Molestias illo quos, quaerat id perspiciatis minima.
				</p>
      </div>

    </div>
  )
}

export default RoomConditionsPage;