import {
  GETSYSTEMSETTINGS_SUCCESS,
  GETSYSTEMSETTINGS_FAIL,
  UPDATESYSTEMSETTINGS_SUCCESS,
  UPDATESYSTEMSETTINGS_FAIL,
} from "../actions/types";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GETSYSTEMSETTINGS_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case GETSYSTEMSETTINGS_FAIL:
      return {
        ...state
      }
    case UPDATESYSTEMSETTINGS_SUCCESS:
      return {
        ...state
      }
    case UPDATESYSTEMSETTINGS_FAIL:
      return {
        ...state
      }
    default:
      return state
  }
}