import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Filter from "../../../components/Filter/Filter";
import Hint from "../../../components/Hint/Hint";
import { Icon } from "../../../components/Icon";
import Pagination from "../../../components/Pagination/Pagination";
import getDate from "../../../helpers/getDate";
import { setMessage } from "../../../redux/actions/auth";
import { createTransaction, editTransaction, getTransactionsList, transactionFree, transactionFrozen } from "../../../redux/actions/transaction";
import { getUserDetail } from "../../../redux/actions/user";
import Sort from "../../../components/Sort/Sort";

const sortOptions = [
    { id: "createdAt", value: "За датою створення" },
];

const AdminProfileWalletPage=()=>{
    const { t } = useTranslation();
    const dispatch =useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const userCur = useSelector((state) => state.currentUser.user);
    const user = useSelector((state) => state.user.currentUser?.user);
    const transaction = useSelector((state) => state.transaction);
    const list = useSelector((state) => state.list);
    const hint = useSelector((state) => state.hints);
    
    const id =useParams().id;
    
    const [pageCur,setPageCur]=useState(1);
    const [sort,setSort]=useState('asc');
    const [ready, setReady] = useState(false);
    const [checkReturn, setCheckReturn] = useState('f');
    const [checkAdd, setCheckAdd] = useState('f');
    const [selectedType, setSelectedType] = useState("add");
    const [selectedTypeFrozen, setSelectedTypeFrozen] = useState("add");
    const [filterApplying, setFilterApplying] = useState(false);


    const [sortValue,setSortValue]=useState('createdAt');
    const [form, setForm] = useState(false);
    const [needGet, setNeedGet] = useState(false);

    const [filter, setFilter] = useState(() => {
        // Initialize filter from localStorage if available
        const savedFilter = localStorage.getItem("profileWalletPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const handleChange = (event) => {
        setSelectedType(event.target.value);
      };
      
      const handleChangeFrozen = (event) => {
        setSelectedTypeFrozen(event.target.value);
      };


      
    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...filter };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else 
        if (id === "dateFrom" || id === "dateTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
            setNeedGet(true);
        } 
        else if (id === "all" && value.length<3 && value.length>0) {
           
        } 
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }
        setFilter(updatedFilter);
        localStorage.setItem("profileWalletPage", JSON.stringify(updatedFilter));
    };

    const changeHandlerForm = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...form };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else 
        if (id === "dataBirthdayFrom" || id === "dataBirthdayTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
        } 
        // else if (id === "all" && value.length<3 && value.length>0) {
           
        // } 
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }
        setForm(updatedFilter);
    };

    const initFunc = (page = 1) => {
        let data = {};
        data.limit = 10;
        data.offset = (page - 1) * data.limit;
        data.filter = filter;
        data.sort={};
        data.sort[sortValue]=sort;
        data.id=id;


       
        dispatch(getUserDetail(data)).then(res=>{
            dispatch(getTransactionsList(data)).then(() => {
                setReady(true);
            });
        })

    };

    const addClick =()=>{
        let data={};
        data.id=id;
        data.value= form.addMoney;
        data.type=selectedType;
        data.tocken=checkAdd;
        dispatch(transactionFree(data)).then(res=>{
            dispatch(setMessage({
                message:selectedType==='add'?'Токени додано':'Токени забрано',
                type: 'success',
              })).then(res=>{
                initFunc(1);
            })
        })
    }

    const addClickFrozen =()=>{
        let data={};
        data.id=id;
        data.value= form.addMoneyFrozen;
        data.type=selectedTypeFrozen;
        data.tocken=checkReturn;
        dispatch(transactionFrozen(data)).then(res=>{
            dispatch(setMessage({
                message:selectedTypeFrozen==='add'?'Токени заморожено':'Токени доступні',
                type: 'success',
              })).then(res=>{
                initFunc(1);
            })
        })
    }

    const applyFilter = () => {
        setFilterApplying(true);
    }
    
    const resetFilter=()=>{
        localStorage.removeItem('profileWalletPage');
        setFilter({});
        setFilterApplying(true);
    }

    const onSortValueChange= (sortOption) => {
        setSortValue(sortOption.id);
        setSort(sortOption.sort);
        localStorage.setItem('profileAdminWalletPageSort', JSON.stringify({id: sortOption.id, sort: sortOption.sort}));
    }

    useEffect(() => {
        if (!ready) {
            initFunc();
            const intervalId = setInterval(initFunc, 100000);
            return () => clearInterval(intervalId);
        }
    }, [ready]);


    useEffect(() => {
        if (ready&& needGet) {
            setNeedGet(false)
            initFunc();
        }
    }, [filter,needGet]);

    useEffect(() => {
        if (filterApplying) {
            initFunc();
            setFilterApplying(false);
        }
    }, [filterApplying])

    useEffect(() => {
        initFunc();
    }, [sort])

    return(<>
        <div className="profileSectionBlock prFlexRow walletBalanceBlock">
            <div className="userEditLeft">
                <h2>{t('current_money')}
                    <div className="infoHelp">
                        <Hint align='left' item={hint[3]}/>
                    </div>
                </h2>
                <div className="infoText">
                    {t('profile_wallet_current_info_text')}
                </div>
                <div className="profileSectionBlockMoneyInfo">
                    <h3>
                        <Icon type='fTocken'></Icon> {t('fiat')}
                    </h3>
                    <div class="token-info moneyInfoBlock">
                        <div class="token-row">
                            <span class="token-label">Вільні</span>
                            <span class="token-value green">{user?.userMoney?.balanceFiat}</span>
                            <span class="token-icon green">F</span>
                        </div>
                        <div class="token-row">
                            <span class="token-label">Зарезервовані</span>
                            <span class="token-value orange">{user?.userMoney?.balanceFiatFrozen}</span>
                            <span class="token-icon orange">F</span>
                        </div>
                                                        
                    </div>
                </div>
                <div className="profileSectionBlockMoneyInfo">
                    <h3>
                        <Icon type='cTocken'></Icon> {t('crypto')}
                    </h3>
                    <div class="token-info">
                        <div class="token-row">
                            <span class="token-label">Вільні</span>
                            <span class="token-value green">{user?.userMoney?.balanceCoin}</span>
                            <span class="token-icon green">C</span>
                        </div>
                        <div class="token-row">
                            <span class="token-label">Зарезервовані</span>
                            <span class="token-value orange">{user?.userMoney?.balanceCoinFrozen}</span>
                            <span class="token-icon orange">C</span>
                        </div>
                                                        
                    </div>
                </div>

            </div>
            <div className="userEditLeft">
                <h2>{t('Вільні кошти')}
                    <div className="infoHelp">
                        <Hint align='left' item={hint[20]}/>
                    </div>
                </h2> 
                <div className="textWithInfoHelp">
                    {t("choose_tocken_type")} 
                        <div className="infoHelp">
                            <Hint align='left' item={hint[21]}/>
                        </div>
                </div>
                <div className="radioSelect">
                    <div  onClick={()=>setCheckAdd('f')} className={checkAdd=='f'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckAdd('f')} type='fTocken'></Icon>
                    <div style={{marginLeft:'37px'}} onClick={()=>setCheckAdd('c')}  className={checkAdd=='c'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckAdd('c')} type='cTocken'></Icon>
                </div>

                <div className="textWithInfoHelp">
                    {t("Кількість токенів")} 
                        <div className="infoHelp">
                            <Hint align='left' item={hint[22]}/>
                        </div>
                </div>
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                    <input className="prInputInput" id='addMoney'  onChange={changeHandlerForm} type="text" /> </div>
                <div className="textWithInfoHelp">
                    {t("Тип операції")} 
                        <div className="infoHelp">
                            <Hint align='left' item={hint[23]}/>
                        </div>
                </div>
                <div className="dFlex">
                        <label className="radioInput">
                        <input
                            type="radio"
                            name="documentType"
                            value="add"
                            checked={selectedType === "add"}
                            onChange={handleChange}
                            className='radioInputInput'
                        />
                        Додати
                        </label>
                        <label className="radioInput">
                        <input
                            type="radio"
                            name="documentType"
                            value="remove"
                            checked={selectedType === "remove"}
                            onChange={handleChange}
                            className='radioInputInput'
                        />
                        Відняти
                        </label>
                    </div>

                
                <div className="dFlex jCC">
                    <button className="prButton prButtonMain" onClick={addClick}>{t('Виконати')}</button>
                </div>
            </div>
            <div className="userEditLeft">
                <h2>{t('Зарезервовані кошти')}
                        <div className="infoHelp">
                            <Hint align='left' item={hint[24]}/>
                        </div>
                </h2>
                
                <div className="textWithInfoHelp">
                    {t("choose_tocken_type")} 
                        <div className="infoHelp">
                            <Hint align='left' item={hint[25]}/>
                        </div>
                </div>
                <div className="radioSelect">
                    <div  onClick={()=>setCheckReturn('f')} className={checkReturn=='f'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckReturn('f')} type='fTocken'></Icon>
                    <div style={{marginLeft:'37px'}} onClick={()=>setCheckReturn('c')}  className={checkReturn=='c'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckReturn('c')} type='cTocken'></Icon>
                </div>

                <div className="textWithInfoHelp">
                    {t("Кількість токенів")}  <div className="infoHelp">
                            <Hint align='left' item={hint[26]}/>
                        </div>
                </div>
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                    <input className="prInputInput" id='addMoneyFrozen'  onChange={changeHandlerForm} type="text" /> </div>
               
                <div className="textWithInfoHelp">
                    {t("Тип операції")} <div className="infoHelp">
                                            <Hint align='left' item={hint[27]}/>
                                        </div>
                </div>
                <div className="dFlex">
                        <label className="radioInput">
                        <input
                            type="radio"
                            name="documentType"
                            value="add"
                            checked={selectedTypeFrozen === "add"}
                            onChange={handleChangeFrozen}
                            className='radioInputInput'
                        />
                        Додати
                        </label>
                        <label className="radioInput">
                        <input
                            type="radio"
                            name="documentType"
                            value="remove"
                            checked={selectedTypeFrozen === "remove"}
                            onChange={handleChangeFrozen}
                            className='radioInputInput'
                        />
                        Відняти
                        </label>
                    </div>
                <div className="dFlex jCC">
                    <button className="prButton prButtonMain" onClick={addClickFrozen}>{t('Виконати')}</button>
                </div>
            </div>
        </div>



        <div className="profileSection">
            <div className="prFlexRow profileSectionHeader">
                <h2 >{t('Транзакції користувача')}
                <div className="infoHelp">
                    <Hint align='left' item={hint[28]}/>
                </div></h2>
                <div className="prFlexRow" style={{gap:'25px'}}>
                    <button className="prButton prButtonMainP prButtonSecond prFlexRow roomFilterButton" onClick={() => setIsOpen(true)}>
                        <Icon type="filter" /> {t("filter_show")}
                    </button>
                </div>
                {/* <a className="profileSectionHeaderLink link">{t('all_games')}<Icon type='rightarrow'/></a> */}
            </div>
            <div className="filterBlock">
                <div className="prFlexRow">
                    <div className="prFlexRow">
                        <div className="prFlexRow filterBlockFilter">
                            <div className="filterBlockSortText">{t("filter_period")}:</div>
                            <DatePicker id="dateFrom" value={(filter.dateFrom?.length>1 && getDate(filter.dateFrom,'dd/mm/yyyy','.')) || ''} onChange={changeHandler} onBlur={initFunc} icon={true} />
                            <div>-</div>
                            <DatePicker id="dateTo" value={(filter.dateTo?.length>1 && getDate(filter.dateTo,'dd/mm/yyyy','.')) || ''} onChange={changeHandler}  onBlur={initFunc} icon={true} />
                        </div>
                        <div className="filterBlockSort prFlexRow">
                            <div className="filterBlockSortText">{t("filter_sort")}:</div>
                            <Sort
                                sort={sort}
                                sortOptions={sortOptions}
                                sortOption={sortValue}
                                page="profileAdminWalletPageSort"
                                onChange={onSortValueChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="filterBlockFilteredText">
                    {t("filter_find")} {transaction?.total} {t("filter_all")} {transaction?.totalFilter}
                </div>
            </div>


            <div className="roomList">


                {transaction?.list?.length>0 && transaction.list.map(el=>{
                    return(
                        <div className="roomItem prFlexRow userTransactionsBlock">
                            <div className="roomItemTabletBlockHeader prFlexRow">
                                <div className="roomItemLink" style={{cursor:'pointer'}}>{el.id}</div>
                                <div className="prFlexRow">
                                    <div className="roomItemBlockValue"><Icon type='time'></Icon>   {getDate(el.createdAt.replace('T',' '),'hh:mm',":")}</div>
                                    <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon> {getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                                </div>
                            </div>
                            <div className="roomItemMobileBlockHeader">
                                <div>
                                    <div className="roomItemBlockHeader">ID транзакції</div>
                                    <div className="roomItemLink" style={{cursor:'pointer'}}>{el.id}</div>
                                </div>
                                <div className="roomItemMoney">
                                    <div className="roomItemMoneyValue prFlexRow">{el.quantity} <Icon type='fTocken'></Icon></div>
                                </div>
                            </div>
                            <div className="">
                                <div className="roomItemBlockHeader">{t('transaction_id')}</div>
                                <div className="roomItemBlockValue">{el.id}</div>
                            </div>
                            <div className="roomItemBlock">
                                <div className="roomItemBlockHeader">{t('transaction_type')}</div>
                                <div className="roomItemBlockValue">{el.type=='0'?"Виведення":"Поповнення"}</div>
                            </div>
                            <div className="roomItemMoney roomItemFullBlock">
                                <div className="roomItemMoneyValue prFlexRow">{el.quantity} <Icon type='fTocken'></Icon></div>
                            </div>
                            <div className="roomItemMobileBlock">
                                <div className="roomItemBlockHeader">{t('status')}</div>
                                <div className="roomItemBlockValue">{list[el.transactionStatus]}</div>
                            </div>
                            <div className="roomItemBlock">
                                <div className="roomItemBlockHeader">{t('payment_org')}</div>
                                <div className="roomItemBlockValue">{el.paymentSystem}</div>
                            </div>
                            <div className="roomItemBlock">
                                <div className="roomItemBlockHeader">{t('time_created')}</div>
                                <div className="prFlexRow"> 
                                        <div className="roomItemBlockValue"><Icon type='time'></Icon>   {el.createdAt?getDate(el.createdAt.replace('T',' '),'hh:mm',":"):'--:--'}</div>
                                        <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon> {el.createdAt?getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',"."):'--.--.----'}</div>
                                    </div>
                            </div>
                        </div>
                    )}
                )}
            </div>
            {transaction?.total>10&&<Pagination
                className="pagination-bar"
                currentPage={pageCur}
                totalCount={transaction.total}
                pageSize={10}
                onPageChange={(page) => {
                    setPageCur(page);
                    initFunc(page);
                }}
            />}
        </div>
        {isOpen && <Filter type='transaction' resetFilter={resetFilter} accept={applyFilter} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>)

}

export default AdminProfileWalletPage;