import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Filter from "../../../components/Filter/Filter";
import { Icon } from "../../../components/Icon";
import Pagination from "../../../components/Pagination/Pagination";
import getDate from "../../../helpers/getDate";
import { createTransaction, editTransaction, getTransactionsList, returnTransaction } from "../../../redux/actions/transaction";
import Select from "../../../components/Select/Select";
import Hint from "../../../components/Hint/Hint";
import Sort from "../../../components/Sort/Sort";
import { setMessage } from "../../../redux/actions/auth";

const sortOptions = [
    { id: "createdAt", value: "За датою створення" },
];

const ProfileWalletPage=()=>{
    const { t } = useTranslation();
    const dispatch =useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const user = useSelector((state) => state.currentUser.user);
    const transaction = useSelector((state) => state.transaction);
    const list = useSelector((state) => state.list);
    const hint = useSelector((state) => state.hints);
    const system = useSelector((state) => state.system);
    const [filterApplying, setFilterApplying] = useState(false);
    
    
    const [pageCur,setPageCur]=useState(1);
    const [sort,setSort]=useState('asc');
    const [ready, setReady] = useState(false);
    const [checkReturn, setCheckReturn] = useState('f');
    const [checkAdd, setCheckAdd] = useState('f');
    
    const [sortValue,setSortValue]=useState('createdAt');
    const [form, setForm] = useState(false);
    const [errors, setErrors] = useState({});
    const [needGet, setNeedGet] = useState(false);
    const [currencyValue, setCurrencyValue] = useState({}); 

    const [filter, setFilter] = useState(() => {
        // Initialize filter from localStorage if available
        const savedFilter = localStorage.getItem("profileWalletPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...filter };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else if (id === "dateFrom" || id === "dateTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
            setNeedGet(true);
        } 
        else if (id === "all" && value.length<3 && value.length>0) {
           
        }
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }
        setFilter(updatedFilter);
    };

    const validationHandler = (e) => {
        const { id, value } = e.target;

        switch (id) {
            case 'cardNumber':
                if (value.replace(' ', '').length < 16) {
                    setErrors({...errors, [id]: 'Номер карти має містити 16 символів' })
                } else {
                    setErrors({...errors, [id]: '' })
                }
                break;
            case 'iban':
                if (value.replace(' ', '').length < 29) {
                    setErrors({...errors, [id]: 'Номер рахунку має містити 29 символів' })
                } else {
                    setErrors({...errors, [id]: '' })
                }
                break;   
            case 'cardHolder':
                if (value.replace(' ', '').length === 0) {
                    setErrors({...errors, [id]: 'Необхідне поле' })
                } else {
                    setErrors({...errors, [id]: '' })
                }
                break;
            case 'taxId':
                if (value.replace(' ', '').length > 10) {
                    setErrors({...errors, [id]: 'РНОКПП/ЄДРПОУ має містити 10 символів' })
                } else {
                    setErrors({...errors, [id]: '' })
                }
                break;
            default:
                setErrors({ ...errors, [id]: '' });
        }
    };

    const changeHandlerForm = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...form };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else 
        if (id === "dataBirthdayFrom" || id === "dataBirthdayTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
        }
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }
        setForm(updatedFilter);
    };

    const initFunc = (page = 1) => {
        let data = {};
        data.limit = 10;
        data.offset = (page - 1) * data.limit;
        data.filter = filter;
        data.sort={};
        data.sort[sortValue]=sort;

        dispatch(getTransactionsList(data)).then(() => {
            setReady(true);
        });
    };

    const addClick =()=>{
        if (form.addMoney < 20) {
            dispatch(setMessage({
                message: 'Мінімальна кількість токенів: 20',
                type: 'error',
            }))

            return;
        }

        let myData={
            "quantity": form.addMoney,
            "transactionStatus":'609ee129-b2a7-486e-966a-78a23221d301',
            "currencyType": 1,
            "frozen":0,
            "type": 1,
            "paymentSystem": 'merchant-api.sticklab'
        }
        let data={
            "merchant_request_id": '',
            "sender_account": user.id,
            "amount": form.addMoney,
            "currency": "UAH",
            "widget_id": "acq.v2",
            "redirect_url": "https://playroom.technodreams.biz/profile/wallet",
            "webhook_url": "https://playroom.technodreams.biz/api/v1/webhook",      
        }

        dispatch(createTransaction(myData)).then(res=>{
            data.merchant_request_id=res.id;
            axios({
                method: "post",
                url: 'https://mekongapi.work/v2/payin-acq2/idle',
                data: data,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNtNjljZWF4NzAwaTFjZHR0aXNmZ2VhcWEiLCJpYXQiOjE3Mzc2MzczMjJ9.7kVNZY_CYah7qk7Q5f_jgLedvkKsq9Q8LmqDnSP33w8'
                },
            }).then(response=>{
                res.idTransaction=response.data.ps_request_id;
                dispatch(editTransaction(res)).then(ress=>{
                    window.open(response.data.url, '_blank');
                })
            }).catch((e)=>{
                return  Promise.reject(e.response.data);
            });
        })
    }

    const resetFilter=()=>{
        setFilter({});
        setFilterApplying(true);
    }

    const applyFilter = () => {
        setFilterApplying(true);
    }

    const onSortValueChange= (sortOption) => {
        setSortValue(sortOption.id);
        setSort(sortOption.sort);
        localStorage.setItem('profileWalletPageSort', JSON.stringify({id: sortOption.id, sort: sortOption.sort}));
    }

    const returnClick=()=>{
        if (form.amount < system?.SettingsWallet?.lowerLimitToken) {
            dispatch(setMessage({
                message: `Нижня межа на виведення токенів: ${system?.SettingsWallet?.lowerLimitToken}`,
                type: 'error',
            }))

            return;
        }

        let data={
            "merchantRequestId": "1111", 
            "amount": form.amount,
            "recipientBank": "Other",
            "cashoutType":form.cashoutType,
            "recipientCard": form.cardNumber,
            "recipientName": form.cardHolder        
        }
        dispatch(returnTransaction(data)).then(res=>{

        })
    }

    useEffect(() => {
        if (!ready) {
            initFunc();
            const intervalId = setInterval(initFunc, 100);
            return () => clearInterval(intervalId);
        }
    }, [ready]);


    useEffect(() => {
        if (ready&& needGet) {
            setNeedGet(false)
            initFunc();
        }
    }, [needGet]);

    useEffect(() => {
        initFunc()
    }, [sort])

    useEffect(() => {
        if (filterApplying) {
            initFunc();
            setFilterApplying(false);
        }
    }, [filterApplying])

    return(<>
        <div className="profileSectionBlock prFlexRow walletBalanceBlock">
            <div className="userEditLeft">
                <h2>{t('current_money')}
                    <div className="infoHelp">
                        <Hint align='left' item={hint[3]}/>
                    </div>
                </h2>
                <div className="infoText">
                    {t('profile_wallet_current_info_text')}
                </div>
                <div className="profileSectionBlockMoneyInfo">
                    <h3>
                        <Icon type='fTocken'></Icon> {t('fiat')}
                    </h3>
                    <div class="token-info moneyInfoBlock">
                        <div class="token-row">
                            <span class="token-label">Вільні</span>
                            <span class="token-value green">{user?.userMoney?.balanceFiat}</span>
                            <span class="token-icon green">F</span>
                        </div>
                        <div class="token-row">
                            <span class="token-label">Зарезервовані</span>
                            <span class="token-value orange">{user?.userMoney?.balanceFiatFrozen}</span>
                            <span class="token-icon orange">F</span>
                        </div>
                                                        
                    </div>
                </div>
                <div className="profileSectionBlockMoneyInfo">
                    <h3>
                        <Icon type='cTocken'></Icon> {t('crypto')}
                    </h3>
                    <div class="token-info">
                        <div class="token-row">
                            <span class="token-label">Вільні</span>
                            <span class="token-value green">{user?.userMoney?.balanceCoin}</span>
                            <span class="token-icon green">C</span>
                        </div>
                        <div class="token-row">
                            <span class="token-label">Зарезервовані</span>
                            <span class="token-value orange">{user?.userMoney?.balanceCoinFrozen}</span>
                            <span class="token-icon orange">C</span>
                        </div>
                                                        
                    </div>
                </div>

            </div>
            <div className="userEditLeft">
                <h2>{t('add_money')}
                    <div className="infoHelp">
                        <Hint align='left' item={hint[4]}/>
                    </div>
                </h2>
                <div className="infoText">
                   {t("current_currency")}
                </div>
                <div className="infoText">
                    1$ = 1 <Icon type='fTocken'></Icon> = 1 <Icon type='cTocken'></Icon>
                </div>
                <br />
                <div className="infoText">
                    Тут місце для тексту із важливою інформацією. Якщо її не буде - то тут нічого не виводиться.
                </div>
                <br />
                <div className="textWithInfoHelp">
                    {t("choose_tocken_type")}
                    <div className="infoHelp">
                        <Hint align='left' item={hint[5]}/>
                    </div>
                </div>
                <div className="radioSelect">
                    <div  onClick={()=>setCheckAdd('f')} className={checkAdd=='f'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckAdd('f')} type='fTocken'></Icon>
                    <div style={{marginLeft:'37px'}} onClick={()=>setCheckAdd('c')}  className={checkAdd=='c'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckAdd('c')} type='cTocken'></Icon>
                </div>

                <div className="textWithInfoHelp">
                    {t("tocken_buy")} 
                    <div className="infoHelp">
                        <Hint align='left' item={hint[6]}/>
                    </div>
                </div>
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                    <input
                        className="prInputInput"
                        id='addMoney'
                        type="number"
                        onChange={changeHandlerForm}
                        value={form.addMoney || ''}
                    /> $ 
                    </div>
                {/* <div className="textWithInfoHelp">
                    {t("choose_payment")} 
                    <div className="infoHelp">
                        <Hint align='left' item={hint[7]}/>
                    </div>
                </div>
                <div className="prInput">
                    <Select
                        id="paymentOrg"
                        value={form.paymentOrg || ''}
                        onChange={changeHandler}
                        placeholder="merchant-api.sticklab"
                        options={[
                            {id: 'merchant-api.sticklab', title: 'merchant-api.sticklab'},
                        ]}
                    />
                </div> */}
                <div className="dFlex jCC">
                    <button className="prButton prButtonMain" onClick={addClick}>{t('add')}</button>
                </div>
                

            </div>
            <div className="userEditLeft">
                <h2>{t('return_money')}
                    <div className="infoHelp">
                        <Hint align='left' item={hint[8]}/>
                    </div>
                </h2>
                <div className="infoText">
                   {t("current_currency")}
                </div>
                <div className="infoText">
                    1$ = 1 <Icon type='fTocken'></Icon> = 1 <Icon type='cTocken'></Icon>
                </div>
                <br />
                <div className="infoText">
                    Тут місце для тексту із важливою інформацією. Якщо її не буде - то тут нічого не виводиться.
                </div>
                <br />
                <div className="textWithInfoHelp">
                    {t("choose_tocken_type")} 
                    <div className="infoHelp">
                        <Hint align='left' item={hint[9]}/>
                    </div>

                </div>
                <div className="radioSelect">
                    <div  onClick={()=>setCheckReturn('f')} className={checkReturn=='f'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckReturn('f')} type='fTocken'></Icon>
                    <div style={{marginLeft:'37px'}} onClick={()=>setCheckReturn('c')}  className={checkReturn=='c'?"radioSelectItem checked":"radioSelectItem"}></div>
                    <Icon onClick={()=>setCheckReturn('c')} type='cTocken'></Icon>
                </div>

                <div className="textWithInfoHelp">
                    {t("tocken_return")} 
                    <div className="infoHelp">
                        <Hint align='left' item={hint[10]}/>
                    </div>

                </div>
                <div className="smallInput" style={{marginBottom:'30px'}}> 
                    <input
                        id={'amount'}
                        type="number"
                        className="prInputInput"
                        min={system?.SettingsWallet?.lowerLimitToken}
                        onChange={changeHandlerForm}
                        value={form.amount || ''}
                    /> $
                </div>
                <div className="textWithInfoHelp">
                    {t("choose_payment")} 
                    <div className="infoHelp">
                        <Hint align='left' item={hint[11]}/>
                    </div>
                </div>
                <div className="prInput">
                    <Select
                        id="cashoutType"
                        value={form.cashoutType || ''}
                        onChange={changeHandlerForm}
                        placeholder="Нічого не обрано"
                        options={[
                            {id: '', title: t('empty_select')},
                            {id: 'card', title: 'Card'},
                            {id: 'iban', title: 'IBAN'},
                        ]}
                    />
                </div>
                {form.cashoutType && (
                    <div className="prInput" >
                        <label htmlFor="cardHolder" className="prInputLabel">
                            {form.cashoutType === 'card' ? (
                                t("Ім'я власника карти")
                            ) : (
                                t("Ім'я власника рахунку")
                            )}
                        </label>
                        <input
                            type="text"
                            id="cardHolder"
                            className={errors.cardHolder ? "prInputInput inputError" : "prInputInput"}
                            value={form.cardHolder || ""}
                            onChange={changeHandlerForm}
                            onBlur={validationHandler}
                        />
                        {errors.cardHolder && <div className="error">{errors.cardHolder}</div>}
                    </div>
                )}
                {form.cashoutType === 'card' && (
                    <div className="prInput" >
                        <label htmlFor="cardNumber" className="prInputLabel">{t("Номер картки")}</label>
                        <input
                            type="number"
                            id="cardNumber"
                            className={errors.cardNumber ? "prInputInput inputError" : "prInputInput"}
                            value={form.cardNumber || ""}
                            onChange={changeHandlerForm}
                            onBlur={validationHandler}
                        />
                        {errors.cardNumber && <div className="error">{errors.cardNumber}</div>}
                    </div>
                )}
                {form.cashoutType === 'iban' && (
                    <>
                        <div className="prInput" >
                            <label htmlFor="ibanNumber" className="prInputLabel">{t("Номер рахунку")}</label>
                            <input
                                type="text"
                                id="ibanNumber" 
                                className={errors.ibanNumber ? "prInputInput inputError" : "prInputInput"}
                                value={form.ibanNumber || ""}
                                onChange={changeHandlerForm}
                                onBlur={validationHandler}
                            />
                            {errors.ibanNumber && <div className="error">{errors.ibanNumber}</div>}
                        </div>
                        <div className="prInput" >
                            <label htmlFor="taxId" className="prInputLabel">{t("РНОКПП/ЄДРПОУ")}</label>
                            <input
                                type="text"
                                id="taxId" 
                                className={errors.taxId ? "prInputInput inputError" : "prInputInput"}
                                value={form.taxId || ""}
                                onChange={changeHandlerForm}
                                onBlur={validationHandler}
                            />
                            {errors.taxId && <div className="error">{errors.taxId}</div>}
                        </div>
                    </>
                )}
                <div className="dFlex jCC">
                    <button className="prButton prButtonMain" onClick={returnClick}>{t('return')}</button>
                </div>
            </div>
        </div>



        <div className="profileSection">
            <div className="prFlexRow profileSectionHeader">
                <h2 >{t('my_transaction')}
                <div className="infoHelp">
                        <Hint align='left' item={hint[12]}/>
                    </div></h2>
                <div className="prFlexRow" style={{gap:'25px'}}>
                    <button className="prButton prButtonMainP prButtonSecond prFlexRow roomFilterButton" onClick={() => setIsOpen(true)}>
                        <Icon type="filter" /> {t("filter_show")}
                    </button>
                </div>
                {/* <a className="profileSectionHeaderLink link">{t('all_games')}<Icon type='rightarrow'/></a> */}
            </div>
            <div className="filterBlock">
                <div className="prFlexRow">
                    <div className="prFlexRow">
                        <div className="prFlexRow filterBlockFilter">
                            <div className="filterBlockSortText">{t("filter_period")}:</div>
                            <DatePicker id="dateFrom" value={(filter.dateFrom?.length>1 && getDate(filter.dateFrom,'dd/mm/yyyy','.')) || ''} onChange={changeHandler} onBlur={initFunc} icon={true} />
                            <div>-</div>
                            <DatePicker id="dateTo" value={(filter.dateTo?.length>1 && getDate(filter.dateTo,'dd/mm/yyyy','.')) || ''} onChange={changeHandler}  onBlur={initFunc} icon={true} />
                        </div>
                        <div className="filterBlockSort prFlexRow">
                            <div className="filterBlockSortText">{t("filter_sort")}:</div>
                            <Sort
                                sort={sort}
                                sortOptions={sortOptions}
                                sortOption={sortValue}
                                page="profileWalletPageSort"
                                onChange={onSortValueChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="filterBlockFilteredText">
                    {t("filter_find")} {transaction?.total} {t("filter_all")} {transaction?.totalFilter}
                </div>
            </div>


            <div className="roomList">
                {transaction?.list?.length>0 && transaction.list.map(el=>{
                    return(
                        <div className="roomItem prFlexRow userTransactionsBlock">
                            <div className="roomItemTabletBlockHeader prFlexRow">
                                <div className="roomItemLink" style={{cursor:'pointer'}}>{el.id}</div>
                                <div className="prFlexRow">
                                    <div className="roomItemBlockValue"><Icon type='time'></Icon>   {getDate(el.createdAt.replace('T',' '),'hh:mm',":")}</div>
                                    <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon> {getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                                </div>
                            </div>
                            <div className="roomItemMobileBlockHeader">
                                <div>
                                    <div className="roomItemBlockHeader">ID транзакції</div>
                                    <div className="roomItemLink" style={{cursor:'pointer'}}>{el.id}</div>
                                </div>
                                <div className="roomItemMoney">
                                    <div className="roomItemMoneyValue prFlexRow">{el.quantity} <Icon type='fTocken'></Icon></div>
                                </div>
                            </div>
                            <div className="">
                                <div className="roomItemBlockHeader">{t('transaction_id')}</div>
                                <div className="roomItemBlockValue">{el.id}</div>
                            </div>
                            <div className="roomItemBlock">
                                <div className="roomItemBlockHeader">{t('transaction_type')}</div>
                                <div className="roomItemBlockValue">{el.type=='0'?"Виведення":"Поповнення"}</div>
                            </div>
                            <div className="roomItemMoney roomItemFullBlock">
                                <div className="roomItemMoneyValue prFlexRow">{el.quantity} <Icon type='fTocken'></Icon></div>
                            </div>
                            <div className="roomItemMobileBlock">
                                <div className="roomItemBlockHeader">{t('status')}</div>
                                <div className="roomItemBlockValue">{list[el.transactionStatus]}</div>
                            </div>
                            <div className="roomItemBlock">
                                <div className="roomItemBlockHeader">{t('payment_org')}</div>
                                <div className="roomItemBlockValue">{el.paymentSystem}</div>
                            </div>
                            <div className="roomItemBlock">
                                <div className="roomItemBlockHeader">{t('time_created')}</div>
                                <div className="prFlexRow dateTimeBlock"> 
                                        <div className="roomItemBlockValue"><Icon type='time'></Icon>   {el.createdAt?getDate(el.createdAt.replace('T',' '),'hh:mm',":"):'--:--'}</div>
                                        <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon> {el.createdAt?getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',"."):'--.--.----'}</div>
                                    </div>
                            </div>
                        </div>
                    )}
                )}
            </div>
            {transaction?.total>10&&<Pagination
                className="pagination-bar"
                currentPage={pageCur}
                totalCount={transaction.total}
                pageSize={10}
                onPageChange={(page) => {
                    setPageCur(page);
                    initFunc(page);
                }}
            />}
        </div>
        {isOpen && <Filter type='transaction' resetFilter={resetFilter} accept={applyFilter} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>)

}

export default ProfileWalletPage;