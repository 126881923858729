import {
  GETBLACKLIST_SUCCESS,
  GETBLACKLIST_FAIL,
  ADDBLACKLISTITEM_SUCCESS,
  ADDBLACKLISTITEM_FAIL,
  DELETEBLACKLISTITEM_SUCCESS,
  DELETEBLACKLISTITEM_FAIL,
  ERROR,
  SET_MESSAGE
} from "./types";

import BlackListService from '../services/blacklist.service'

export const getBlacklist = (form) => (dispatch) => {
    return BlackListService.getBlacklist(form).then( (response) => {
        if(response.statusText!="OK" || response.data.errors){
          dispatch({
            type: GETBLACKLIST_FAIL,
            payload: response.data,
          });
        return response.data;
        }else{
          dispatch({
            type: GETBLACKLIST_SUCCESS,
            payload: response.data,
          });
          return  response.data;
        }
      },
      (error) => {
        dispatch({
          type: GETBLACKLIST_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
  );
}

export const addBlacklistItem = (form) => (dispatch) => {
  return BlackListService.addBlacklistItem(form).then( (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: ADDBLACKLISTITEM_FAIL,
          payload: response.data,
        });
      return response.data;
      }else{
        dispatch({
          type: ADDBLACKLISTITEM_SUCCESS,
          payload: response.data,
        });

        dispatch({
          type: SET_MESSAGE,
          messageType: 'success',
          message: 'IP успішно додано'
        });

        return  response.data;
      }
    },
    (error) => {
      dispatch({
        type: ADDBLACKLISTITEM_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
    console.log(error);
      return Promise.reject();
    }
  );
}

export const deleteBlacklistItem = (form) => (dispatch) => {
  return BlackListService.deleteBlacklistItem(form).then( (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: DELETEBLACKLISTITEM_FAIL,
          payload: response.data,
        });
      return response.data;
      }else{
        dispatch({
          type: DELETEBLACKLISTITEM_SUCCESS,
          payload: response.data,
        });

        dispatch({
          type: SET_MESSAGE,
          messageType: 'success',
          message: 'IP успішно видалено'
        });

        return  response.data;
      }
    },
    (error) => {
      dispatch({
        type: DELETEBLACKLISTITEM_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
    console.log(error);
      return Promise.reject();
    }
  );
}