// "dd/mm/yyyy"
// "dd/mm/yy"
// "yyyy/mm/dd"
// "hh:mm"
// "hh:mm:ss"
// "dd/mm/yyyy hh:mm"
// FORMDATE_WITH_TIME
// "dd/mmmm/yyyy hh:mm"
// " hh:mm dd/mmmm/yyyy"

const formatDate = (date) => {
  let parts = date?.split('/');
  let d;

  const [first, second, third] = parts.map(Number);
  const isDayFirst = first < 1000;

  d = new Date(
    isDayFirst ? third : first,
    (second || 1) - 1,         
    isDayFirst ? first : third
  );

  return d;
}

const getDate = (dt, type, divider) => {
  const currentNewDate = new Date();
  const devider = divider ?? "/";

  if(typeof(dt)==='number'){
    var newstr2=dt;
  }else {
    var pattern =  /[\-\.|]/;
    var newstr = dt?.toString()?.replace(pattern, '/');
    var newstr2 = newstr?.replace(pattern, '/');
  }

  let formattedDate;
  
  if (newstr2) {
    let strParts = newstr2.split(' ');

    if (strParts.length === 2) {
      const datePart = strParts[0];
      const timePart = strParts[1];

      formattedDate = formatDate(datePart);

      const [hours, minutes, seconds] = timePart.split(':').map(Number);

      formattedDate = new Date(
        formattedDate.getFullYear(),
        formattedDate.getMonth(),
        formattedDate.getDate(),
        hours || 0,
        minutes || 0,
        seconds || 0
      );
    } else {
      formattedDate = formatDate(newstr2);
    }

    if (!(formattedDate instanceof Date) || isNaN(formattedDate.getTime())) {
      formattedDate = currentNewDate;
    }
  }

  const d = formattedDate ?? currentNewDate;

  const date = new Date(Date.UTC(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(), ));

  const addNull = (param) => {
    if (param.toString().length > 1) return param;
    else
      return `0${param}`
  }
  const retMonth =(param)=>{
    switch (param) {
      case 0:
       return 'Січня';
       case 1:
       return 'Лютого';
       case 2:
       return 'Березня';
       case 3:
       return 'Квітня';
       case 4:
       return 'Травня';
       case 5:
       return 'Червня';
       case 6:
       return 'Липня';
       case 7:
       return 'Серпня';
       case 8:
       return 'Вересня';
       case 9:
       return 'Жовтня';
       case 10:
       return 'Листопада';
       case 11:
       return 'Грудня';
       default:
         return param;
    }
  }

  switch (type) {
    case "dd/mm/yyyy":
      return `${addNull(date.getDate())}${devider}${
        addNull(date.getMonth() + 1)
      }${devider}${date.getFullYear()}`;
    case "dd/mm/yy":
      return `${addNull(date.getDate())}${devider}${
        addNull(date.getMonth() + 1)
      }${devider}${date.getYear()-100}`;
    case "yyyy/mm/dd":
      return `${addNull(date.getFullYear())}${devider}${
        addNull(date.getMonth() + 1)
      }${devider}${addNull(date.getDate())}`;
    case "hh:mm":
      return `${addNull(date.getHours())}${devider}${addNull(date.getMinutes())}`;
    case "hh:mm:ss":
      return `${addNull(date.getHours())}${devider}${addNull(date.getMinutes())}${devider}${addNull(date.getSeconds())}`;
    case "dd/mm/yyyy hh:mm":
      return `${addNull(date.getDate())}${devider}${
        addNull(date.getMonth() + 1)
      }${devider}${addNull(date.getFullYear())} ${addNull(date.getHours())}:${addNull(date.getMinutes())}`;
    case "hh:mm dd/mm/yyyy ":
      return `${addNull(date.getHours())}:${addNull(date.getMinutes())} ${addNull(date.getDate())}${devider}${
        addNull(date.getMonth() + 1)
      }${devider}${addNull(date.getFullYear())}`;
    case 'FORMDATE_WITH_TIME':
      return `${addNull(date.getFullYear())}-${
          addNull(date.getMonth() + 1)
        }-${addNull(date.getDate())}T${addNull(date.getHours())}:${addNull(date.getMinutes())}`
    case "dd/mmmm/yyyy hh:mm":
      return `${addNull(date.getDate())}${devider}${
        retMonth(date.getMonth())
        }${devider}${addNull(date.getFullYear())} ${addNull(date.getHours())}:${addNull(date.getMinutes())}`;
    case "hh:mm dd/mmmm/yyyy":
      return `${addNull(date.getHours())}:${addNull(date.getMinutes())} ${addNull(date.getDate())}${devider}${
        addNull(date.getMonth())}${devider}${addNull(date.getFullYear())}`;
    case "dd/mmmm/yyyy":
      return `${addNull(date.getDate())}${devider}${
        retMonth(date.getMonth())
          }${devider}${date.getFullYear()}`;
    default:
      return date;
  }
};

export default getDate;