import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';

const getLogs = (body) => {
  const offset = body.offset || 0;
  const limit = body.limit || 10;

  let filter = `?offset=${offset}&limit=${limit}&sort[createdAt=DESC]`;

  if(body.filter&& Object.keys(body.filter).length ){
    for (let [key, value] of Object.entries(body.filter)) {
      filter+=`&filters[${key}]=${value}`
    }
  }
  
  if(body.sort&& Object.keys(body.sort).length ){
    for (let [key, value] of Object.entries(body.sort)) {
      filter+=`&sort[${key}]=${value}`
    }
  }
  return axios
  .get(`${constants.URL}all-logs${filter}`, { headers: authHeader() })
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return  Promise.reject(e.response.data);
  });
}

export default {
  getLogs,
}