import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { Icon } from "../../../components/Icon";
import getDate from "../../../helpers/getDate";
import { editUser, getReputation, changeUserPassword } from "../../../redux/actions/user";
import Select from "../../../components/Select/Select";
import Hint from "../../../components/Hint/Hint";

const ProfileSettingsPage = (props) => {
    const { t } = useTranslation();
    const [form,setForm]=useState({});
    const [reputation,setReputation]=useState({});
    const [edit,setEdit]=useState(false);
    const [errors, setErrors] = useState({});
    const [showRepeat, setShowRepeat] = useState(false);
    const [show, setShow] = useState(false);
    const [showOld, setShowOld] = useState(false);
    const [passwordData, setPasswordData] = useState({});
    const fullList = useSelector((state) => state.fullList);
    const hint = useSelector((state) => state.hints);
    const list = useSelector((state) => state.list);
    const navigate = useNavigate();

    const user = useSelector((state) => state.currentUser?.user);
    const [validForm, setValidForm] = useState({});
    const dispatch = useDispatch();
    const [ready, setReady] = useState(false);

    const changeHandler = (event) => {
        let { id, value } = event.target;

        id = id && id.includes('Tablet') ? id.slice(0, -6) : id;

        if (event.target.tagName === 'SELECT' && event.target.accessKey) {
            setForm({ ...form, [event.target.accessKey]: value });
        }
        else if (id === 'birthday') {
            const formattedDate = getDate(value, "yyyy/mm/dd", "-");
            setForm({...form, [id]: formattedDate})
        } else {
            setForm({ ...form, [id]: value });
        }
    };

    const checkHandler = (event) => {
        const emailCheck =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{8,30}$/;
        let { id, value } = event.target;

        id = id && id.includes('Tablet') ? id.slice(0, -6) : id;

        switch (id) {
            case "email":
                if (emailCheck.test(String(value).toLowerCase())) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_email") });
                }
                break;
            case "nickname":
                if (value.length >= 3) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_nickname") });
                }
                break;
            case "code":
                if (value.length >= 6) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_code") });
                }
                break;
            case "passwordNew":
                if (passwordCheck.test(String(value))) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_password") });
                }
                break;
            case "passwordRepeat":
                if (value !== form.passwordNew) {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("password_mismatch") });
                } else {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                }
                break;
            default:
                setValidForm({ ...validForm, [id]: true });
                setErrors({ ...errors, [id]: "" });
        }
    };

    const declineClick = () => {
        setEdit(false);
    };

    const changePassword = () => {
        const data = {
            'email': form.email,
            'passwordOld': form.passwordOld,
            'passwordNew': form.passwordNew,
        }

        dispatch(changeUserPassword(data))
            .then((res) => {
                console.log(res);
            })
            .catch(e => {
                console.log('Error: ', e);
            })
    }

    const initFunc = () => {
        let data={};
        if(user?.id){
            data.id=user.id;
            dispatch(getReputation(data)).then(res=>{
                setReputation({...res});
                setForm({...user});
            })
        }
       
    };

    const onEditClick = () => {
        setEdit(!edit);
    }

    const saveClick = () => {
        let data = { ...form };
        dispatch(editUser(data)).then(res => {
            setEdit(false);
            setForm({...res});
        }).catch(e => {
            console.log(e);
        });
    };

    useEffect(() => {
        if (user) {
            initFunc();
        }
    }, [user]);

    useEffect(() => {
        setReady(true);
    }, [form]);

    return(
        <>
            {ready && (
                <div className="profileSectionBlock prFlexRow userSettingsBlock">
                    {<div className="profileSettingsTabletBlock">
                        <div className="prFlexRow">
                            <div className="userEditLeft" style={{marginBottom:'20px'}}>
                                    <h2>{t('user_info')}
                                    <div className="infoHelp">
                                        <Hint align='center' item={hint[13]}/>
                                    </div>
                                    </h2>
                                    {!edit && <div className="editButton" onClick={()=>setEdit(true)}><Icon type='edit' /></div>}
                                    {edit?<div className="prInput">
                                            <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                                            <input value={form?.nickname} onChange={changeHandler} onBlur={checkHandler} id="nickname" type="text" className={errors.nickname ? "prInputInput inputError" : "prInputInput"} placeholder={t("nickname")} />
                                            {errors.nickname && <div className="error">{errors.nickname}</div>}
                                        </div>:
                                        <div className="prInput">
                                            <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                                            <div className="noEditField">{form?.nickname}</div>
                                        </div>
                                    }
                                    {edit?<div className="prInput">
                                            <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                                            <input value={form?.email} onChange={changeHandler} onBlur={checkHandler} id="email" type="text" className={errors.email ? "prInputInput inputError" : "prInputInput"} placeholder={t("mail")} />
                                            {errors.email && <div className="error">{errors.email}</div>}
                                        </div>:
                                        <div className="prInput">
                                            <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                                            <div className="noEditField">{form?.email}</div>
                                        </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                        <DatePicker id='birthday'  onChange={changeHandler} icon={true} value={form.birthday} />
                                        {errors.birthday && <div className="error">{errors.birthday}</div>}
                                    </div>:
                                        <div className="prInput">
                                            <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                        <div className="noEditField">{form?.birthday|| '---'}</div>
                                    </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                        <Select
                                            id="sex"
                                            value={form.sex || ''}
                                            onChange={changeHandler}
                                            placeholder="Нічого не обрано"
                                            options={
                                                fullList.Sex.length > 0 &&
                                                fullList.Sex
                                            }
                                        />
                                        {errors.sex && <div className="error">{errors.sex}</div>}
                                    </div>:
                                    <div className="prInput">
                                        <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                        <div className="noEditField">{(form?.sex && list[form?.sex])|| '---'}</div>
                                    </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="firstName" className={errors.firstName ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                        <input value={form.firstName} onChange={changeHandler} onBlur={checkHandler} id="firstName" type="text" className={errors.firstName ? "prInputInput inputError" : "prInputInput"} placeholder={t("first_name")} />
                                        {errors.firstName && <div className="error">{errors.firstName}</div>}
                                    </div>:
                                        <div className="prInput">
                                            <label htmlFor="firstName" className={errors.firstName ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                            <div className="noEditField">{form?.firstName|| '---'}</div>
                                        </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="lastName" className={errors.lastName ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                        <input value={form.lastName} onChange={changeHandler} onBlur={checkHandler} id="lastName" type="text" className={errors.lastName ? "prInputInput inputError" : "prInputInput"} placeholder={t("last_name")} />
                                        {errors.lastName && <div className="error">{errors.lastName}</div>}
                                    </div>:
                                        <div className="prInput">
                                        <label htmlFor="lastName" className={errors.lastName ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                        <div className="noEditField">{form?.lastName|| '---'}</div>
                                    </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                        <Select
                                            id="country"
                                            value={form.country || ''}
                                            onChange={changeHandler}
                                            placeholder="Нічого не обрано"
                                            options={[
                                                { id: '', title: t('empty_select') },
                                                { id: 'Україна', title: 'Україна' },
                                                { id: 'Інша країна', title: 'Інша країна' },
                                            ]}
                                        />
                                        {errors.country && <div className="error">{errors.country}</div>}
                                    </div>:
                                        <div className="prInput">
                                        <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                        <div className="noEditField">{form?.country|| '---'}</div>
                                    </div>
                                    }
                                    {edit?<div className="prInput">
                                        <label htmlFor="timeZone" className={errors.timeZone ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                                        <Select
                                            id="timeZone"
                                            value={form.timeZone || ''}
                                            onChange={changeHandler}
                                            placeholder="Нічого не обрано"
                                            options={[
                                                { id: '', title: t('empty_select') },
                                                { id: '+1', title: '+1' },
                                                { id: '+2', title: '+2' },
                                            ]}
                                        />
                                        {errors.timeZone && <div className="error">{errors.timeZone}</div>}
                                    </div>:
                                        <div className="prInput">
                                        <label htmlFor="timeZone" className={errors.timeZone ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                                        <div className="noEditField">{form?.timeZone|| '---'}</div>
                                    </div>
                                    }
                                {edit&&<div className="userEditButtons">
                                        <button className="prButton prButtonSecond" onClick={declineClick}>{t('decline')}</button>
                                        <button className="prButton prButtonMain" onClick={saveClick}>{t('save')}</button>
                                    </div>}
                            </div>
                            <div className="userEditLeft">
                            <h2>{t('reputation')}
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[15]}/>
                                </div>
                            </h2>
        
                            <div className="dFlex jCC" style={{gap:'24px'}}>
                                <div className={(user?.reputation>6 && "circleReputationSettings") || (user?.reputation<7 && user?.reputation>2 && "circleReputationSettings orange") ||(user?.reputation<3 && "circleReputationSettings red")}>
                                    {user?.reputation}
                                </div>
                                <div className="reputationRightText">
                                    Тут місце для тексту із важливою інформацією. Якщо її не буде - то тут нічого не виводиться.
                                </div>
                            </div>
        
                            <h4 className="reputationH4">{t('reputation')}
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[16]}/>
                                </div>
                            </h4>
                            <div>
                                {reputation?.data?.length>0 && reputation?.data.map(el=>{
                                    return(
                                <div className={`reputationListItem dFlex ${el.symbol=='-'?'red':'green'}`} style={{gap:'20px'}}>
                                    <div className="circleReputationSettingsSmall">
                                        {el.symbol}
                                    </div>
                                    <div>
                                        <div className="reputationListItemValue">
                                            {el.symbol}{el.title} {el.title=='1'?'бал':'бали'}
                                        </div>
                                        <div className="reputationListItemRemark">
                                            {el.description}
                                        </div>
                                    </div>
                                </div>)
                                })}
                            </div>
                            </div>
                        </div>
                        <div className="prFlexRow">
                            <div className="userEditLeft">
                                <h2>{t('change_password')}
                                    <div className="infoHelp">
                                        <Hint align='center' item={hint[18]}/>
                                    </div>
                                </h2>
                                <div className="prInput prInputIcon">
                                    <label htmlFor="passwordOldTablet" className={errors.passwordOld ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                                    <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="passwordOldTablet" type={showOld ? 'text' : "password"} className={errors.passwordOld ? "prInputInput inputError" : "prInputInput"} placeholder={t("old_password")} />
                                    <div className="prInputIconIcon" onClick={() => setShowOld(!showOld)}><Icon type={showOld ? 'eyeOpen' : 'eyeClosed'} /></div>
                                    {errors.passwordOld && <div className="error">{errors.passwordOld}</div>}
                                </div>
                                <div className="prInput prInputIcon">
                                    <label htmlFor="passwordNewTablet" className={errors.passwordNew ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                                    <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="passwordNewTablet" type={show ? 'text' : "password"} className={errors.passwordNew ? "prInputInput inputError" : "prInputInput"} placeholder={t("password")} />
                                    <div className="prInputIconIcon" onClick={() => setShow(!show)}><Icon type={show ? 'eyeOpen' : 'eyeClosed'} /></div>
                                    {errors.passwordNew && <div className="error">{errors.passwordNew}</div>}
                                </div>
                                <div className="prInput prInputIcon">
                                    <label htmlFor="passwordRepeatTablet" className={errors.passwordRepeat ? "prInputLabel inputError" : "prInputLabel"}>{t("password_repeat")}</label>
                                    <input minLength={8} onChange={changeHandler} onBlur={checkHandler} id="passwordRepeatTablet" type={showRepeat ? 'text' : "password"} className={errors.passwordRepeat ? "prInputInput inputError" : "prInputInput"} placeholder={t("password_repeat")} />
                                    <div className="prInputIconIcon" onClick={() => setShowRepeat(!showRepeat)}><Icon type={showRepeat ? 'eyeOpen' : 'eyeClosed'} /></div>
                                    {errors.passwordRepeat && <div className="error">{errors.passwordRepeat}</div>}
                                </div>
                                <button
                                    style={{margin:'13px 0 13px 47px'}}
                                    className="prButton prButtonMain"
                                    onClick={changePassword}
                                >
                                    {t('save_password')}
                                </button>
                            </div>
                            <div className="userEditLeft" style={{marginBottom:'20px'}}>
                                <h2>{t('personal_info')}
                                    <div className="infoHelp">
                                        <Hint align='center' item={hint[14]}/>
                                    </div>
                                </h2>
                                {
                                    props.isVerified ? (
                                        <>
                                            <div className="simpleHeaderPersonal">
                                                {t("Дата реєстрації")}
                                            </div>
                                            <div className="simpleTextPersonal mb">
                                                {getDate(user?.createdAt.replace('T',' '),'dd/mm/yyyy','.')} 
                                            </div>
                                            <div className="simpleHeaderPersonal">
                                                {t("last_activity")}
                                            </div>
                                            <div className="simpleTextPersonal mb">
                                                {getDate(user?.createdAt.replace('T',' '),'dd/mm/yyyy','.')} 
                                            </div>
                                            {user?.verificationData.photos &&<div className="simpleHeaderPersonal" style={{marginBottom:'11px'}}>
                                                {t("files_for_verification")}
                                            </div>}
                                            <div className="simpleTextPersonal mb dFlex" style={{'gap':'7px'}}>
                                                {
                                                    user?.verificationData.photos && user?.verificationData.photos.map( photo => (
                                                        <a href={photo.url} target="_blank" rel="noreferrer">
                                                            <div className="documentImage">
                                                                <img src={photo.url} alt={photo.name} />
                                                            </div>
                                                        </a>
                                                    ))
                                                }
                                            </div>
                                            <div className="simpleHeaderPersonal">
                                                {t("last_ips")}
                                            </div>
                                            <div className="simpleTextPersonal">
                                                {user?.ip}
                                            </div>
                                        </>
                                    ) : +user?.isStatus==1?
                                    <>
                                         <button
                                            className="prButton prButtonMain"
                                            style={{marginTop:"20px", width: '100%'}}
                                            disabled={true}
                                        >
                                           Очікуйте на верифікацію
                                        </button>
                                    </>
                                    : (
                                        <button
                                            className="prButton prButtonMain"
                                            style={{marginTop:"20px", width: '100%'}}
                                            onClick={() => navigate('/verification')}
                                        >
                                            Верифікація акаунту
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                        <div className="userEditLeft">
                            <h2>{t('delete_personal_info')}
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[17]}/>
                                </div>
                            </h2>
                            <div className="simpleTextPersonal mb">
                                {t('delete_1')}
                            </div>
                            <div className="simpleTextPersonal mb">
                                {t('delete_2')}
                            </div>
                            <button className="prButton prButtonMain" style={{margin:"20px"}}>
                                {t('delete_personal_button')}
                            </button>
                        </div>
                    </div>}
                    <div style={{flex:'0 0 32%'}}>
                        <div className="userEditLeft" style={{marginBottom:'20px'}}>
                            <h2>{t('user_info')}
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[13]}/>
                                </div>
                            </h2>
                            {!edit && <div className="editButton" onClick={onEditClick}><Icon type='edit' /></div>}
                            {edit?<div className="prInput">
                                    <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                                    <input value={form?.nickname} onChange={changeHandler} onBlur={checkHandler} id="nickname" type="text" className={errors.nickname ? "prInputInput inputError" : "prInputInput"} placeholder={t("nickname")} />
                                    {errors.nickname && <div className="error">{errors.nickname}</div>}
                                </div>:
                                <div className="prInput">
                                    <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                                    <div className="noEditField">{form?.nickname}</div>
                                </div>
                            }
                            {edit?<div className="prInput">
                                    <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                                    <input value={form?.email} onChange={changeHandler} onBlur={checkHandler} id="email" type="text" className={errors.email ? "prInputInput inputError" : "prInputInput"} placeholder={t("mail")} />
                                    {errors.email && <div className="error">{errors.email}</div>}
                                </div>:
                                <div className="prInput">
                                    <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                                    <div className="noEditField">{form?.email}</div>
                                </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                <DatePicker id='birthday'  onChange={changeHandler} icon={true} value={form.birthday} />
                                {errors.birthday && <div className="error">{errors.birthday}</div>}
                            </div>:
                                <div className="prInput">
                                    <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                <div className="noEditField">{form?.birthday|| '---'}</div>
                            </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                <Select
                                    id="sex"
                                    value={form.sex || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={
                                        fullList.Sex.length > 0 &&
                                        fullList.Sex
                                    }
                                />
                                {errors.sex && <div className="error">{errors.sex}</div>}
                            </div>:
                            <div className="prInput">
                                <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                <div className="noEditField">{(form?.sex && list[form?.sex])|| '---'}</div>
                            </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="firstName" className={errors.firstName ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                <input value={form.firstName} onChange={changeHandler} onBlur={checkHandler} id="firstName" type="text" className={errors.firstName ? "prInputInput inputError" : "prInputInput"} placeholder={t("first_name")} />
                                {errors.firstName && <div className="error">{errors.firstName}</div>}
                            </div>:
                                <div className="prInput">
                                    <label htmlFor="firstName" className={errors.firstName ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                    <div className="noEditField">{form?.firstName|| '---'}</div>
                                </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="lastName" className={errors.lastName ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                <input value={form.lastName} onChange={changeHandler} onBlur={checkHandler} id="lastName" type="text" className={errors.lastName ? "prInputInput inputError" : "prInputInput"} placeholder={t("last_name")} />
                                {errors.lastName && <div className="error">{errors.lastName}</div>}
                            </div>:
                                <div className="prInput">
                                <label htmlFor="lastName" className={errors.lastName ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                <div className="noEditField">{form?.lastName|| '---'}</div>
                            </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                <Select
                                    id="country"
                                    value={form.country || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={[
                                        { id: '', title: t('empty_select') },
                                        { id: 'Україна', title: 'Україна' },
                                        { id: 'Інша країна', title: 'Інша країна' },
                                    ]}
                                />
                                {errors.country && <div className="error">{errors.country}</div>}
                            </div>:
                                <div className="prInput">
                                <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                <div className="noEditField">{form?.country|| '---'}</div>
                            </div>
                            }
                            {edit?<div className="prInput">
                                <label htmlFor="timeZone" className={errors.timeZone ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                                <Select
                                    id="timeZone"
                                    value={form.timeZone || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={[
                                        { id: '', title: t('empty_select') },
                                        { id: '+1', title: '+1' },
                                        { id: '+2', title: '+2' },
                                    ]}
                                />
                                {errors.timeZone && <div className="error">{errors.timeZone}</div>}
                            </div>:
                                <div className="prInput">
                                <label htmlFor="timeZone" className={errors.timeZone ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                                <div className="noEditField">{form?.timeZone || '---'}</div>
                            </div>
                            }
                        {edit&&<div className="userEditButtons">
                                <button className="prButton prButtonSecond" onClick={declineClick}>{t('decline')}</button>
                                <button className="prButton prButtonMain" onClick={saveClick}>{t('save')}</button>
                            </div>}
                        </div>      
                        <div className="userEditLeft">
                            <h2>{t('change_password')}
                                    <div className="infoHelp">
                                        <Hint align='center' item={hint[18]}/>
                                    </div>
                            </h2>
                            <div className="prInput prInputIcon">
                                <label htmlFor="passwordOld" className={errors.passwordOld ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                                <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="passwordOld" type={showOld ? 'text' : "password"} className={errors.passwordOld ? "prInputInput inputError" : "prInputInput"} placeholder={t("old_password")} />
                                <div className="prInputIconIcon" onClick={() => setShowOld(!showOld)}><Icon type={showOld ? 'eyeOpen' : 'eyeClosed'} /></div>
                                {errors.passwordOld && <div className="error">{errors.passwordOld}</div>}
                            </div>
                            <div className="prInput prInputIcon">
                                <label htmlFor="passwordNew" className={errors.passwordNew ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                                <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="passwordNew" type={show ? 'text' : "password"} className={errors.passwordNew ? "prInputInput inputError" : "prInputInput"} placeholder={t("password")} />
                                <div className="prInputIconIcon" onClick={() => setShow(!show)}><Icon type={show ? 'eyeOpen' : 'eyeClosed'} /></div>
                                {errors.passwordNew && <div className="error">{errors.passwordNew}</div>}
                            </div>
                            <div className="prInput prInputIcon">
                                <label htmlFor="passwordRepeat" className={errors.passwordRepeat ? "prInputLabel inputError" : "prInputLabel"}>{t("password_repeat")}</label>
                                <input minLength={8} onChange={changeHandler} onBlur={checkHandler} id="passwordRepeat" type={showRepeat ? 'text' : "password"} className={errors.passwordRepeat ? "prInputInput inputError" : "prInputInput"} placeholder={t("password_repeat")} />
                                <div className="prInputIconIcon" onClick={() => setShowRepeat(!showRepeat)}><Icon type={showRepeat ? 'eyeOpen' : 'eyeClosed'} /></div>
                                {errors.passwordRepeat && <div className="error">{errors.passwordRepeat}</div>}
                            </div>
                            <button
                                style={{margin:'13px 0 13px 47px'}}
                                className="prButton prButtonMain"
                                onClick={changePassword}
                            >
                                {t('save_password')}
                            </button>
                        </div>
                    </div>
                    <div className="userEditLeft">
                        <h2>{t('reputation')}
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[15]}/>
                                </div>
                        </h2>
        
                        <div className="dFlex jCC" style={{gap:'24px'}}>
                            <div className={(user?.reputation>6 && "circleReputationSettings") || (user?.reputation<7 && user?.reputation>2 && "circleReputationSettings orange") ||(user?.reputation<3 && "circleReputationSettings red")}>
                                {user?.reputation}
                            </div>
                            <div className="reputationRightText">
                                Тут місце для тексту із важливою інформацією. Якщо її не буде - то тут нічого не виводиться.
                            </div>
                        </div>
        
                        <h4 className="reputationH4">{t('reputation')}
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[16]}/>
                                </div>
                        </h4>
                        <div>
                            {reputation?.data?.length>0 && reputation?.data.map(el=>{
                                    return(
                                <div className={`reputationListItem dFlex ${el.symbol=='-'?'red':'green'}`} style={{gap:'20px'}}>
                                    <div className="circleReputationSettingsSmall">
                                        {el.symbol}
                                    </div>
                                    <div>
                                        <div className="reputationListItemValue">
                                            {el.symbol}{el.title} {el.title=='1'?'бал':'бали'}
                                        </div>
                                        <div className="reputationListItemRemark">
                                            {el.description}
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                    <div>
                        <div className="userEditLeft" style={{marginBottom:'20px'}}>
                            <h2>{t('personal_info')}
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[14]}/>
                                </div>
                            </h2>
                            {
                                props.isVerified ? (
                                    <>
                                        <div className="simpleHeaderPersonal">
                                            {t("Дата реєстрації")}
                                        </div>
                                        <div className="simpleTextPersonal mb">
                                            {getDate(user?.createdAt.replace('T',' '),'dd/mm/yyyy','.', 'HERE WE GO')}                                        </div>
                                        <div className="simpleHeaderPersonal">
                                            {t("last_activity")}
                                        </div>
                                        <div className="simpleTextPersonal mb">
                                            {getDate(user?.createdAt.replace('T',' '),'dd/mm/yyyy','.', 'HERE WE GO')}
                                        </div>
                                        { user?.verificationData.photos && <div className="simpleHeaderPersonal" style={{marginBottom:'11px'}}>
                                                {t("files_for_verification")}
                                            </div>}
                                            <div className="simpleTextPersonal mb dFlex" style={{'gap':'7px'}}>
                                                {
                                                    user?.verificationData.photos && user?.verificationData.photos.map( photo => (
                                                        <a href={photo.url} target="_blank" rel="noreferrer">
                                                            <div className="documentImage">
                                                                <img src={photo.url} alt={photo.name} />
                                                            </div>
                                                        </a>
                                                    ))
                                                }
                                            </div>
                                        <div className="simpleHeaderPersonal">
                                            {t("last_ips")}
                                        </div>
                                        <div className="simpleTextPersonal">
                                            {user.ip}
                                        </div>
                                    </>
                                ) : +user?.isStatus==1?
                                <>
                                     <button
                                        className="prButton prButtonMain"
                                        style={{marginTop:"20px", width: '100%'}}
                                        disabled={true}
                                    >
                                       Очікуйте на верифікацію
                                    </button>
                                </>
                                : (
                                    <button
                                        className="prButton prButtonMain"
                                        style={{marginTop:"20px", width: '100%'}}
                                        onClick={() => navigate('/verification')}
                                    >
                                        Верифікація акаунту
                                    </button>
                                )
                            }
                        </div>
                        <div className="userEditLeft">
                            <h2>{t('delete_personal_info')}
                                <div className="infoHelp">
                                    <Hint align='center' item={hint[17]}/>
                                </div>
                            </h2>
                            <div className="simpleTextPersonal mb">
                                {t('delete_1')}
                            </div>
                            <div className="simpleTextPersonal mb">
                                {t('delete_2')}
                            </div>
                            <button className="prButton prButtonMain" style={{margin:"20px"}}>
                                {t('delete_personal_button')}
                            </button>
                        </div>
                    </div> 
                </div>
            )}
        </>
    )

}

export default ProfileSettingsPage;