import { useMemo } from "react";

import { ReactComponent as FB } from "../../assets/images/fb.svg";
import { ReactComponent as FBAuth } from "../../assets/images/fb-auth.svg";
import { ReactComponent as Insta } from "../../assets/images/insta.svg";
import { ReactComponent as Telegram } from "../../assets/images/telegram.svg";
import { ReactComponent as TikTok } from "../../assets/images/tiktok.svg";
import { ReactComponent as EyeClosed } from "../../assets/images/ph_eye-closed.svg";
import { ReactComponent as EyeOpen } from "../../assets/images/eye-open.svg";
import { ReactComponent as Google } from "../../assets/images/google.svg";
import { ReactComponent as Send } from "../../assets/images/send.svg";
import { ReactComponent as LightTheme } from "../../assets/images/lightTheme.svg";
import { ReactComponent as DarkTheme } from "../../assets/images/darkTheme.svg";
import { ReactComponent as PlayController } from "../../assets/images/playControlle.svg";
import { ReactComponent as Plus } from "../../assets/images/plus.svg";
import { ReactComponent as Wallet } from "../../assets/images/wallet.svg";
import { ReactComponent as FTocken } from "../../assets/images/fTocken.svg";
import { ReactComponent as CTocken } from "../../assets/images/cToken.svg";
import { ReactComponent as Avatar } from "../../assets/images/avatar.svg";
import { ReactComponent as Support } from "../../assets/images/support.svg";
import { ReactComponent as Notification } from "../../assets/images/notification.svg";
import { ReactComponent as More } from "../../assets/images/more.svg";
import { ReactComponent as PhotoUpload } from "../../assets/images/photoUpload.svg";
import { ReactComponent as Edit } from "../../assets/images/edit.svg";
import { ReactComponent as Passport } from "../../assets/images/passport.svg";
import { ReactComponent as Win } from "../../assets/images/win.svg";
import { ReactComponent as Loose } from "../../assets/images/loose.svg";
import { ReactComponent as Draft } from "../../assets/images/draft.svg";
import { ReactComponent as Info } from "../../assets/images/info.svg";
import { ReactComponent as PS } from "../../assets/images/ps.svg";
import { ReactComponent as PC } from "../../assets/images/d.svg";
import { ReactComponent as XBOX } from "../../assets/images/xb.svg";
import { ReactComponent as BigPlus } from "../../assets/images/bigplus.svg";
import { ReactComponent as RightArrow } from "../../assets/images/rightarrow.svg";
import { ReactComponent as PagArrowRight } from "../../assets/images/pagArrow.svg";
import { ReactComponent as PagArrowLeft } from "../../assets/images/pagArrowLeft.svg";
import { ReactComponent as EditName } from "../../assets/images/editName.svg";
import { ReactComponent as Exit } from "../../assets/images/exit.svg";
import { ReactComponent as FilterArrow } from "../../assets/images/filterArrow.svg";
import { ReactComponent as SelectArrow } from "../../assets/images/selectArrow.svg";
import { ReactComponent as Filter } from "../../assets/images/filter.svg";
import { ReactComponent as Calendar } from "../../assets/images/calendar.svg";
import { ReactComponent as Clear } from "../../assets/images/clear.svg";
import { ReactComponent as Close } from "../../assets/images/close.svg";
import { ReactComponent as ArrowForward } from "../../assets/images/arrowForward.svg";
import { ReactComponent as Accept } from "../../assets/images/accept.svg";
import { ReactComponent as Warning } from "../../assets/images/warning.svg";
import { ReactComponent as Time } from "../../assets/images/time.svg";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Files } from "../../assets/images/files.svg";
import { ReactComponent as Cloud } from "../../assets/images/cloud.svg";
import { ReactComponent as Square } from "../../assets/images/square.svg";
import { ReactComponent as Triangular } from "../../assets/images/triangular.svg";
import { ReactComponent as Download } from "../../assets/images/download.svg";
import { ReactComponent as Verification } from "../../assets/images/verification.svg";
import { ReactComponent as Arbitr } from "../../assets/images/arbitr.svg";
import { ReactComponent as Addminreq } from "../../assets/images/addminreq.svg";
import { ReactComponent as InfoLG } from "../../assets/images/InfoLG.svg";
import { ReactComponent as RightArrowLG } from "../../assets/images/rightArrowLG.svg";
import { ReactComponent as ArrowReturn } from "../../assets/images/arrowReturn.svg";
import { ReactComponent as RivalsRoomLogoText } from "../../assets/images/rivalsroomLogo.svg";
import { ReactComponent as LogoNew } from "../../assets/images/logoNew.svg";
import { ReactComponent as Refresh } from "../../assets/images/refresh.svg";
import { ReactComponent as Copy } from "../../assets/images/copy.svg";
import { ReactComponent as Invite } from "../../assets/images/addPerson.svg";


export const Icon = (props) => {
  const width = props.width || '';

  const icon = useMemo(() => {
    switch (props.type) {
     
      case "logo":
        return <LogoNew />;
      case "rivalsLogoText":
        return <RivalsRoomLogoText />;
      case "fb":
        return <FB />;   
      case "google":
        return <Google />;   
      case "fbAuth":
        return <FBAuth />;      
      case "insta":
        return <Insta />;      
      case "telegram":
        return <Telegram />;
      case "tikTok":
        return <TikTok />;
      case "eyeClosed":
        return <EyeClosed />;  
      case "eyeOpen":
        return <EyeOpen />;  
      case "send":
        return <Send />;
      case "lightTheme":
        return <LightTheme />;  
      case "darkTheme":
        return <DarkTheme />;    
      case "playController":
        return <PlayController />;   
      case "plus":
        return <Plus/>; 
      case "wallet":
        return <Wallet/>; 
      case "fTocken":
        return <FTocken/>;  
      case "cTocken":
        return <CTocken/>; 
      case "avatar":
        return <Avatar/>;    
      case "support":
        return <Support/>;        
      case "notification":
        return <Notification/>;       
      case "more":
        return <More/>;
      case "photoUpload":
        return <PhotoUpload/>; 
      case "edit":
        return <Edit/>;   
      case "passport":
        return <Passport/>;
      case "win":
        return <Win/>;            
      case "loose":
        return <Loose/>;            
      case "draft":
        return <Draft/>;
      case "info":
        return <Info/>;
      case "pc":
        return <PC/>;   
      case "ps":
        return <PS/>;
      case "xbox":
        return <XBOX/>;   
      case "bigplus":
        return <BigPlus/>;   
      case "rightarrow":
        return <RightArrow/>;    
      case "pagArrow":
        return <PagArrowRight/>;      
      case "pagArrowLeft":
        return <PagArrowLeft/>;   
      case "editName":
        return <EditName/>;  
      case "exit":
        return <Exit/>;          
      case "filterArrow":
        return <FilterArrow/>;           
      case "selectArrow":
        return <SelectArrow/>;           
      case "filter":
        return <Filter/>;           
      case "calendar":
        return <Calendar/>;       
      case "clear":
        return <Clear/>;         
      case "close":
        return <Close/>;    
      case "arrowForward":
        return <ArrowForward/>;   
      case "accept":
        return <Accept/>;   
      case "warning":
        return <Warning/>; 
      case "time":
        return <Time/>; 
      case "search":
        return <Search/>; 
      case "files":
        return <Files/>; 
      case "cloud":
        return <Cloud/>; 
      case "square":
        return <Square/>; 
      case "triangular":
        return <Triangular/>; 
      case "download":
        return <Download/>; 
      case "verification":
        return <Verification/>;  
      case "arbitr":
        return <Arbitr/>;  
      case "addminreq":
        return <Addminreq/>;  
      case "infolg":
        return <InfoLG />
      case "rightArrowLg":
        return <RightArrowLG />
      case "arrowReturn":
        return <ArrowReturn />
      case "refresh":
        return <Refresh />
      case "copy":
        return <Copy />
      case "invite":
        return <Invite />
      default:
        return null;
    }
  }, [props.type]);

  if (props.link)
    return (
      <a className="prSvgIcon" href={props.link} target="_blank">
        {icon}
      </a>
    );


  return (
    <div
      className={props.onClick?"iconDiv button":"iconDiv"}
      onClick={props.onClick}
      style={ width ? {width: `${width}`} : null }
    >
      {icon}
    </div>);
};
