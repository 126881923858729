import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBlacklist, addBlacklistItem, deleteBlacklistItem } from "../../redux/actions/blacklist";
import { setMessage } from "../../redux/actions/auth";

import WarningModal from "../../components/WarningModal/WarningModal";
import Hint from "../../components/Hint/Hint";
import { Icon } from "../../components/Icon";
import Pagination from '../../components/Pagination/Pagination';

const BlacklistPage = () => {
  const [add, setAdd] = useState(false);
  const [deleteItem,setDelete]=useState('');
  const [list, setList] = useState([]);
  const [pageCur, setPageCur] = useState(1)
  const [newIp, setNewIp] = useState('');
  const dispatch = useDispatch();

  const hint = useSelector(state=>state.hints)

  const changeHandler = (e) => {
    setNewIp(e.target.value);
  };

  const addClick = () => {
    if (!newIp) {
      dispatch(setMessage({
        message: 'Поле не може бути пустим',
        type: 'error',
      }))

      return;
    }

    dispatch(addBlacklistItem({ip: newIp}))
      .then( res => {
        initFunc();
        setNewIp('');
      })
      .catch( err => {
        console.log('err: ', err);
      })
  };

  const deleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setDelete(e.currentTarget.id)
  }

  const deleteApprove = () => {
    let data={
      id:deleteItem
    }
    dispatch(deleteBlacklistItem(data))
      .then( res=> {
        initFunc();
      })
      .catch(e => {
        console.log('err: ', e);
      })
  }

  const initFunc = (page = 1) => {
    const data = {};
    data.limit = 10;
    data.offset = (page - 1) * data.limit;

    dispatch(getBlacklist(data))
      .then( res => {
        setList(res);
      })
      .catch( error => {
        console.log('err: ', error);
      })
  }

  useEffect(() => {
    initFunc();
  }, []);

  return (
    <div className="profileSection gamesPage" style={{marginBottom:'0px'}}>
      <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
          <h2 >Чорний список
            <div className="infoHelp"> <Hint align='left' item={hint[36]}/></div>
          </h2>
          <button
            className="prButton prButtonMain prFlexRow"
            style={{gap:'8px', fill:"white"}}
            onClick={() => setAdd(prev => !prev)}
        >
          <Icon type="plus"/>
          Додати IP
        </button>
      </div>
      {add && (
        <div className="directoryElememt prFlexRow">
            <div className="directoryElementText">
                <input
                  id='ip'
                  className="prInputInput"
                  type="text"
                  placeholder="Назва елементу"
                  onChange={changeHandler}
                  value={newIp}
                />
            </div>
            <div className="prFlexRow directoryElementButton">
                <div className="prIconButton"  onClick={addClick}>
                    <Icon type='accept'></Icon>
                </div>
                <div className="prIconButton" onClick={()=>setAdd(prev => !prev)} >
                    <Icon type='close'></Icon>
                </div>
            </div>
        </div>
      )}

      {list?.all?.length > 0 && list?.all?.map(ip => (
        <div key={ip.id} className="directoryElememt prFlexRow">
          <>
            <div className="directoryElementText">
              {ip.ip}
            </div>
            <div className="prFlexRow directoryElementButton">
              {/* <div className="prIconButton" id={ip.id} onClick={editClick}>
                  <Icon type='edit'></Icon>
              </div> */}
              <div className="prIconButton" id={ip.id} onClick={deleteClick}>
                  <Icon type='close'></Icon>
              </div>
            </div>
          </>
        </div>
      ))}
      
      {list.total>list.limit && 
        <Pagination
          className="pagination-bar"
          currentPage={pageCur}
          totalCount={list.total}
          pageSize={list.limit}
          onPageChange={(page) => {
              setPageCur(page);
              initFunc(page);
          }}
        />
      }
      {deleteItem?.length>0 &&
        <WarningModal warningClick={deleteApprove} warningText={'are_you_sure_delete'} warningButton={'delete'} closeClick={()=>setDelete('')}></WarningModal>
      }
    </div>
  )
}

export default BlacklistPage;