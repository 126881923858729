import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "../../components/DatePicker/DatePicker";
import { Icon } from "../../components/Icon";
import SmallModal from "../../components/SmallModal/SmallModal";
import { editUser, getUserDetail } from "../../redux/actions/user";
import { changePassword, setMessage } from "../../redux/actions/auth";
import Select from "../../components/Select/Select";
import FileUploadAndCrop from "../../components/FileUploadAndCrop/FileUploadAndCrop";

const coverImg ={
    initialAspectRatio: 35 / 10,
    aspectRatio: 35 / 10,
    autoCropArea: 35 / 10,
    height: '200px',
    width: '320px',
    size: 10,
    type: 'coverImg',
    disabled: false
};

const avatarImg ={
    initialAspectRatio: 5 / 5,
    aspectRatio: 1,
    autoCropArea: 1,
    height: '50px',
    width: '50px',
    size: 10,
    type: 'avatarImg',
    text: "блог",
    disabled: false
};

const UserEditPage = () => {
    const user = useSelector((state) => state.user?.currentUser?.user || {});
    const fullList = useSelector((state) => state.fullList);
    const [ready, setReady] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});
    const [validForm, setValidForm] = useState({});
    const [showRepeat, setShowRepeat] = useState(false);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentId = useParams().id;
    const { t } = useTranslation();

    const changeHandler = (event) => {
        const { id, value } = event.target;
        if (event.target.tagName === 'SELECT' && event.target.accessKey) {
            setForm({ ...form, [event.target.accessKey]: value });
        } else {
            setForm({ ...form, [id]: value });
        }
    };

    const checkHandler = (event) => {
        const emailCheck =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{8,30}$/;
        const { id, value } = event.target;

        switch (id) {
            case "email":
                if (emailCheck.test(String(value).toLowerCase())) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_email") });
                }
                break;
            case "nickname":
                if (value.length >= 3) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_nickname") });
                }
                break;
            case "code":
                if (value.length >= 6) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_code") });
                }
                break;
            case "password":
                if (passwordCheck.test(String(value))) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_password") });
                }
                break;
            case "passwordRepeat":
                if (value !== form.password) {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("password_mismatch") });
                } else {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                }
                break;
            default:
                setValidForm({ ...validForm, [id]: true });
                setErrors({ ...errors, [id]: "" });
        }
    };

    const initFunc = () => {
        dispatch(getUserDetail({ id: currentId })).then(res => {
            setReady(true);
            setForm(res.user);
        }).catch(e => {
            setReady(true);
            console.log(e);
        });
    };

    const saveClick = () => {
        let data = { ...form };
        dispatch(editUser(data)).then(res => {
            dispatch(setMessage({
                type: 'success',
                message: 'Дані успішно оновлено'
            }))
        }).catch(e => {
            console.log(e);
        });
    };

    const saveEdit = useCallback((data) => {
        let body = { ...data };
        dispatch(editUser(body)).then(res => {});
    }, []);

    const declineClick = () => {
        navigate('/users');
    };

    const checkPasswordFields = () => {
        return form.password &&
               form.passwordRepeat &&
               (form.password === form.passwordRepeat)
    }

    const changeUserPassword = () => {
        const data = {
            'userId': user.id,
            'password': form.password,
        }

        dispatch(changePassword(data))
            .then((res) => {
                dispatch(setMessage({
                    type: 'success',
                    message: 'Пароль успішно оновлено',
                }))
            })
            .catch((error) => {
                console.log('Error: ', error)
            })
    }

    useEffect(() => {
        if (!ready) {
            initFunc();
        }
    }, [ready]);

    return (
        <>
            {ready && (
                <div className="profileMainBlock prFlexRow">
                    <div className="photoUploadCover">
                        <FileUploadAndCrop key={`cover-${coverImg.type}`} setForm={setForm} saveEdit={saveEdit} form={form} options={coverImg}></FileUploadAndCrop>
                    </div>
                    <div className="profileMainBlockInfo prFlexRow">
                        <div className="headerUserAvatarBlock">
                            <div className='headerUserAvatar' style={{backgroundImage:`url(${user?.avatarImg})`}}>
                                {!user?.avatarImg && <Icon type='avatar'></Icon>}
                            </div>
                            <div className="headerUserAvatarUpload">
                                <FileUploadAndCrop key={`avatar-${avatarImg.type}`}  setForm={setForm} saveEdit={saveEdit} form={form} options={avatarImg}></FileUploadAndCrop>
                            </div>
                        </div>
                        <div className="profileMainBlockInfoRight">
                            <div className="prFlexRow profileMainBlockInfoRightText">
                                <div className='prFlexRow'>
                                    <div 
                                    className={
                                        (user?.reputation > 6 && "reputationNumber") ||
                                        ((user?.reputation < 7 && user?.reputation > 3) && "reputationNumber caution") ||
                                        (user?.reputation < 4 && "reputationNumber error")
                                    }
                                    >{user.reputation}</div>
                                </div>
                                <div className="profileFio">{t('last_name')} {t('first_name')}</div>
                                <div style={{ paddingTop: '3px' }} className='profileFioEditName'>
                                    <Icon type='editName' />
                                </div>
                            </div>
                            <div
                                className="profileMainBlockInfoRightNick"
                                onClick={() => navigate(`/profile/wallet/${user.id}`)}
                                style={{ cursor: 'pointer' }}
                            >
                                @{user?.nickname}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="userEditBlock" style={{ width: '100%' }}>
                <div className="userEditLeft">
                    <h2>{t('user_info')}
                        <div className="infoHelp"><Icon type='info' /></div>
                    </h2>
                    <div className="prFlexRow">
                        <div style={{ width: '100%' }}>
                            <div className="prInput">
                                <label htmlFor="role" className={errors.role ? "prInputLabel inputError" : "prInputLabel"}>{t("role")}</label>
                                <Select
                                    id="role"
                                    value={form.role || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={
                                        fullList.UserRoles.length > 0 &&
                                        fullList.UserRoles
                                    }
                                />
                                {errors.role && <div className="error">{errors.role}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                                <input value={form?.nickname} onChange={changeHandler} onBlur={checkHandler} id="nickname" type="text" className={errors.nickname ? "prInputInput inputError" : "prInputInput"} placeholder={t("nickname")} />
                                {errors.nickname && <div className="error">{errors.nickname}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                                <input value={form?.email} onChange={changeHandler} onBlur={checkHandler} id="email" type="text" className={errors.email ? "prInputInput inputError" : "prInputInput"} placeholder={t("mail")} />
                                {errors.email && <div className="error">{errors.email}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                <DatePicker id='birthday'  onChange={changeHandler} icon={true} value={form.birthday} />
                                {errors.birthday && <div className="error">{errors.birthday}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                <Select
                                    id="sex"
                                    value={form.sex || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={
                                        fullList.Sex.length > 0 &&
                                        fullList.Sex
                                    }
                                />
                                {errors.sex && <div className="error">{errors.sex}</div>}
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            <div className="prInput">
                                <label htmlFor="firstName" className={errors.firstName ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                <input value={form.firstName} onChange={changeHandler} onBlur={checkHandler} id="firstName" type="text" className={errors.firstName ? "prInputInput inputError" : "prInputInput"} placeholder={t("first_name")} />
                                {errors.firstName && <div className="error">{errors.firstName}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="lastName" className={errors.lastName ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                <input value={form.lastName} onChange={changeHandler} onBlur={checkHandler} id="lastName" type="text" className={errors.lastName ? "prInputInput inputError" : "prInputInput"} placeholder={t("last_name")} />
                                {errors.lastName && <div className="error">{errors.lastName}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                <Select
                                    id="country"
                                    value={form.country || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={[
                                        { id: '', title: t('empty_select') },
                                        { id: 'Україна', title: 'Україна' },
                                        { id: 'Інша країна', title: 'Інша країна' },
                                    ]}
                                />
                                {errors.country && <div className="error">{errors.country}</div>}
                            </div>
                            <div className="prInput">
                                <label htmlFor="time_line" className={errors.time_line ? "prInputLabel inputError" : "prInputLabel"}>{t("time_line")}</label>
                                <Select
                                    id="time_line"
                                    value={form.time_line || ''}
                                    onChange={changeHandler}
                                    placeholder="Нічого не обрано"
                                    options={[
                                        { id: '', title: t('empty_select') },
                                        { id: '+1', title: '+1' },
                                        { id: '+2', title: '+2' },
                                    ]}
                                />
                                {errors.time_line && <div className="error">{errors.time_line}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="userEditRight">
                    <h2>{t('set_password')}
                        <div className="infoHelp"><Icon type='info' /></div>
                    </h2>
                    <div className="prInput prInputIcon">
                        <label htmlFor="password" className={errors.password ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                        <input minLength={8} maxLength={30} onChange={changeHandler} onBlur={checkHandler} id="password" type={show ? 'text' : "password"} className={errors.password ? "prInputInput inputError" : "prInputInput"} placeholder={t("password")} />
                        <div className="prInputIconIcon" onClick={() => setShow(!show)}><Icon type={show ? 'eyeOpen' : 'eyeClosed'} /></div>
                        {errors.password && <div className="error">{errors.password}</div>}
                    </div>
                    <div className="prInput prInputIcon">
                        <label htmlFor="passwordRepeat" className={errors.passwordRepeat ? "prInputLabel inputError" : "prInputLabel"}>{t("password_repeat")}</label>
                        <input minLength={8} onChange={changeHandler} onBlur={checkHandler} id="passwordRepeat" type={showRepeat ? 'text' : "password"} className={errors.passwordRepeat ? "prInputInput inputError" : "prInputInput"} placeholder={t("password_repeat")} />
                        <div className="prInputIconIcon" onClick={() => setShowRepeat(!showRepeat)}><Icon type={showRepeat ? 'eyeOpen' : 'eyeClosed'} /></div>
                        {errors.passwordRepeat && <div className="error">{errors.passwordRepeat}</div>}
                    </div>
                    <button
                        className="prButton prButtonMain"
                        onClick={changeUserPassword}
                        disabled={!checkPasswordFields()}
                    >
                        {t('save_password')}
                    </button>
                </div>
            </div>
            <div className="userEditButtons userEditPage">
                <button className="prButton prButtonSecond" onClick={declineClick}>{t('decline')}</button>
                <button className="prButton prButtonMain" onClick={saveClick}>{t('save')}</button>
            </div>
        </>
    );
};

export default UserEditPage;
