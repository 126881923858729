import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../components/DatePicker/DatePicker";
import FileUpload from "../../components/FileUpload/FileUpload";
import { sendVerification } from "../../redux/actions/user";
import Select from "../../components/Select/Select";
import Hint from "../../components/Hint/Hint";

const VerificationPage =()=>{
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});
    const [validForm, setValidForm] = useState({});    const { t } = useTranslation();
    const fullList = useSelector((state) => state.fullList);
    const user = useSelector((state) => state.currentUser?.user);
    const hint = useSelector((state) => state.hints);
    
    const dispatch =useDispatch();
    const navigate= useNavigate();
    const [selectedType, setSelectedType] = useState("0");

    const handleChange = (event) => {
      setSelectedType(event.target.value);
    };

    const checkHandler = (event) => {
        const emailCheck =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{8,30}$/;
        const { id, value } = event.target;

        switch (id) {
            case "email":
                if (emailCheck.test(String(value).toLowerCase())) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_email") });
                }
                break;
            case "nickname":
                if (value.length >= 3) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_nickname") });
                }
                break;
            case "code":
                if (value.length >= 6) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_code") });
                }
                break;
            case "password":
                if (passwordCheck.test(String(value))) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_password") });
                }
                break;
            case "passwordRepeat":
                if (value !== form.password) {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("password_mismatch") });
                } else {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                }
                break;
            default:
                setValidForm({ ...validForm, [id]: true });
                setErrors({ ...errors, [id]: "" });
        }
    };
    
    const changeHandler = (event) => {
        setForm({ ...form, [event.target.id]: event.target.value });
    };

    const createClick=()=>{
        let data={...form};
            data.type=selectedType;
            dispatch(sendVerification(data)).then(res=>{
            navigate('/profile/settings');
        })
    }

    // Setting initial state of the form
    useEffect(() => {
        if (user) {
            setForm({
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                secondName: user.secondName || '',
                country: user.country || '',
                sex: user.sex || '',
                birthday: user.birthday || '',
            })
        }
    }, [user]);

    return(
        <>
            {
                user && (
                    <div className="profileSection verification" style={{marginBottom:'0px'}}>
                        <div>
                            <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
                                <h2 >
                                    Верифікація облікового запису
                                </h2>
                            </div>
                            <div className="simpleVerification">
                                Для того, щоб Ви могли використовувати весь функціонал платформи, и повинні підтвердити Вашу особу.
                            </div>
                            <div className="simpleVerification">
                            Детальніше можете прочитати в розділі Бази знань <a href="#">Верифікація облікового запису</a>
                            </div>
                            <h5>
                            Виконайте наведені нижче дії:
                            </h5>


                            <div className="dFlex verBlock">
                                <div className="leftVer">
                                    <h3>1. Заповніть персональну інформацію</h3>
                                    <div className="verBlockItem">
                                        <h4>
                                            Персональні дані
                                        </h4>
                                        <div className="" style={{ gap: '20px' }}>
                                            <div className="prFlexRow">
                                                <div className="prInput">
                                                    <label htmlFor="firstName" className={errors.firstName ? "prInputLabel inputError" : "prInputLabel"}>{t("first_name")}</label>
                                                    <input value={form.firstName} onChange={changeHandler} onBlur={checkHandler} id="firstName" type="text" className={errors.firstName ? "prInputInput inputError" : "prInputInput"} placeholder={t("first_name")} />
                                                    {errors.firstName && <div className="error">{errors.firstName}</div>}
                                                </div>
                                                <div className="prInput">
                                                    <label htmlFor="lastName" className={errors.lastName ? "prInputLabel inputError" : "prInputLabel"}>{t("last_name")}</label>
                                                    <input value={form.lastName} onChange={changeHandler} onBlur={checkHandler} id="lastName" type="text" className={errors.lastName ? "prInputInput inputError" : "prInputInput"} placeholder={t("last_name")} />
                                                    {errors.lastName && <div className="error">{errors.lastName}</div>}
                                                </div>
                                            </div>
                                            <div className="prFlexRow">
                                                <div className="prInput">
                                                    <label htmlFor="secondName" className={errors.secondName ? "prInputLabel inputError" : "prInputLabel"}>{t("second_name")}</label>
                                                    <input value={form.secondName} onChange={changeHandler} onBlur={checkHandler} id="secondName" type="text" className={errors.secondName ? "prInputInput inputError" : "prInputInput"} placeholder={t("second_name")} />
                                                    {errors.secondName && <div className="error">{errors.secondName}</div>}
                                                </div>
                                                
                                                <div className="prInput">
                                                    <label htmlFor="country" className={errors.country ? "prInputLabel inputError" : "prInputLabel"}>{t("country")}</label>
                                                    <Select
                                                        id="country"
                                                        value={form.country || ''}
                                                        onChange={changeHandler}
                                                        placeholder="Нічого не обрано"
                                                        options={[
                                                            { id: '', title: t('empty_select') },
                                                            { id: 'Україна', title: 'Україна' },
                                                            { id: 'Інша країна', title: 'Інша країна' },
                                                        ]}
                                                    />
                                                    {errors.country && <div className="error">{errors.country}</div>}
                                                </div>
                                            </div>
                                            <div className="prFlexRow">
                                                <div className="prInput">
                                                    <label htmlFor="sex" className={errors.sex ? "prInputLabel inputError" : "prInputLabel"}>{t("gender")}</label>
                                                    <Select
                                                        id="sex"
                                                        value={form.sex || ''}
                                                        onChange={changeHandler}
                                                        placeholder="Нічого не обрано"
                                                        options={
                                                            fullList.Sex.length > 0 &&
                                                            fullList.Sex
                                                        }
                                                    />
                                                    {errors.sex && <div className="error">{errors.sex}</div>}
                                                </div>
                                                <div className="prInput">
                                                    <label htmlFor="birthday" className={errors.birthday ? "prInputLabel inputError" : "prInputLabel"}>{t("birthday")}</label>
                                                    <DatePicker id='birthday'  onChange={changeHandler} onBlur={()=>{}} icon={true} value={form.birthday} />
                                                    {errors.birthday && <div className="error">{errors.birthday}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="verBlockItem">
                                        <h4>
                                            Тип документу                   
                                        </h4>
                                        <div className="dFlex verBlockItemRadio">
                                            <label className="radioInput">
                                            <input
                                                type="radio"
                                                name="documentType"
                                                value="0"
                                                checked={selectedType === "0"}
                                                onChange={handleChange}
                                                className='radioInputInput'
                                            />
                                            ID-карта
                                            </label>
                                            <label className="radioInput">
                                            <input
                                                type="radio"
                                                name="documentType"
                                                value="1"
                                                checked={selectedType === "1"}
                                                onChange={handleChange}
                                                className='radioInputInput'
                                            />
                                            Паспорт громадянина України
                                            </label>
                                            <label className="radioInput">
                                            <input
                                                type="radio"
                                                name="documentType"
                                                value="2"
                                                checked={selectedType === "2"}
                                                onChange={handleChange}
                                                className='radioInputInput'
                                            />
                                            Закордонний паспорт
                                            </label>
                                        </div>
                                    </div>
                                    <div className="verBlockItem">
                                        <div className="" style={{ gap: '20px' }}>
                                            <div className="prFlexRow">
                                                <div
                                                    className="prInput"
                                                    style={selectedType === "0" ? { display: 'none' } : {}}
                                                >
                                                    <label htmlFor="serial" className={errors.serial ? "prInputLabel inputError" : "prInputLabel"}>{t("Серія")}</label>
                                                    <input value={form.serial} onChange={changeHandler} onBlur={checkHandler} id="serial" type="text" className={errors.serial ? "prInputInput inputError" : "prInputInput"} placeholder={t("Серія")} />
                                                    {errors.serial && <div className="error">{errors.serial}</div>}
                                                </div>
                                                <div className="prInput">
                                                    <label htmlFor="number" className={errors.number ? "prInputLabel inputError" : "prInputLabel"}>{t("Номер")}</label>
                                                    <input value={form.number} onChange={changeHandler} onBlur={checkHandler} id="number" type="text" className={errors.number ? "prInputInput inputError" : "prInputInput"} placeholder="Номер" />
                                                    {errors.number && <div className="error">{errors.number}</div>}
                                                </div>
                                            </div>
                                            <div className="prFlexRow">
                                                <div className="prInput">
                                                    <label htmlFor="authority" className={errors.authority ? "prInputLabel inputError" : "prInputLabel"}>{t("Ким виданий")}</label>
                                                    <input value={form.authority} onChange={changeHandler} onBlur={checkHandler} id="authority" type="text" className={errors.authority ? "prInputInput inputError" : "prInputInput"} placeholder={t("Ким виданий")} />
                                                    {errors.authority && <div className="error">{errors.authority}</div>}
                                                </div>
                                                <div className="prInput">
                                                    <label htmlFor="date" className={errors.date ? "prInputLabel inputError" : "prInputLabel"}>{t("Дата видачі")}</label>
                                                    <DatePicker id='date'  onChange={changeHandler} onBlur={()=>{}} icon={true} value={form.date} />
                                                    {errors.date && <div className="error">{errors.date}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>2. Завантажте фото посвідчення особи</h3>
                                    <div className="verFile">
                                        <FileUpload id={'img'} form={form} setForm={setForm} maxFiles={3}></FileUpload>
                                    </div>
                                    <div className="buttonsGap">
                                        <button className="prButton prButtonSecond" onClick={()=>navigate('/')}>Відмінити</button>
                                        <button className="prButton prButtonMain" onClick={createClick}>Надіслати</button>
                                    </div>
                                </div>
                                <div className="rightVer">
                                    <h4 style={{marginBottom:'15px'}}>
                                        Підготовка документів
                                        <div className="infoHelp">
                                            <Hint align='right' item={hint[94]}/>
                                        </div>
                                    </h4>
                                    <div className="simpleTextPersonal" style={{marginBottom:'30px'}}>
                                        Супровідний текст для підготовки документів. Наприклад, якщо фотографія буде не якісною, ми не зможемо верифікувати обліковий запис
                                    </div>
                                    <div className="verImageBlock">
                                        <div className="verImageBlockText">
                                            ID-карта
                                        </div>
                                        <img src="/images/accept.jpeg"/>
                                    </div>

                                    <div className="verImageBlock">
                                        <div className="verImageBlockText">
                                        Паспорт громадянина України
                                        </div>
                                        <img src="/images/accept.jpeg"/>
                                    </div>

                                    <div className="verImageBlock">
                                        <div className="verImageBlockText">
                                            Закордонний паспорт
                                        </div>
                                        <img src="/images/accept.jpeg"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )

}


export default VerificationPage;