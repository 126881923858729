import React, { useEffect, useState } from "react";
import { Icon } from "../../components/Icon";
import DatePicker from "../../components/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList, createUser } from "../../redux/actions/user";
import { useNavigate } from "react-router-dom";
import Filter from "../../components/Filter/Filter";
import getDate from "../../helpers/getDate";
import Hint from "../../components/Hint/Hint";
import checkRole from "../../helpers/checkRole";
import Sort from "../../components/Sort/Sort";

const sortOptions = [
    { id: "createdAt", value: "За датою створення" },
];

const UsersPage = () => {
    const { t } = useTranslation();
    const [ready, setReady] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [pageCur, setPageCur] = useState(1);
    const [filter, setFilter] = useState(() => {
        // Initialize filter from localStorage if available
        const savedFilter = localStorage.getItem("usersPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const [sort,setSort]=useState('desc');
    const [sortValue,setSortValue]=useState('createdAt');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const hint = useSelector((state) => state.hints);
    const currentUser = useSelector((state) => state.currentUser.user);
    const [filterApplying, setFilterApplying] = useState(false);

    

    const initFunc = (page = 1,fil) => {
        let filt={...filter}
        if(fil){
            if (fil.target.id === "dateFrom" || fil.target.id === "dateTo") {
                filt[fil.target.id]= getDate(fil.target.value, "yyyy/mm/dd", "-");
            } 
        }
        let data = {};
        data.limit = 20;
        data.offset = (page - 1) * data.limit;
        data.filter = filt;
        data.sort={};
        data.sort[sortValue]=sort;

        dispatch(getUsersList(data)).then(() => {
            setReady(true);
        });
    };

    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...filter };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else if (id === "dataBirthdayFrom" || id === "dataBirthdayTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
        } 
        else if (id === "all" && value.length<3 && value.length>0) {
           
        } 
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }
        setFilter(updatedFilter);
    };

    const savefilter =()=>{
        localStorage.setItem("usersPage", JSON.stringify(filter));
    }

    const applyFilter =()=>{
        localStorage.setItem("usersPage", JSON.stringify(filter));
        setFilterApplying(true);
    }

    const resetFilter=()=>{
        setFilter({});
        setFilterApplying(true);
    }

    const createClick = () => {
        dispatch(createUser())
            .then((res) => {
                navigate(`/userEdit/${res.id}`);
            })
            .catch((e) => {
                console.error(e);
            });
    };

    const onSortValueChange= (sortOption) => {
        setSortValue(sortOption.id);
        setSort(sortOption.sort);
        localStorage.setItem('usersPageSort', JSON.stringify({id: sortOption.id, sort: sortOption.sort}));
    }

    const editClick = (e, id) => {
        e.preventDefault();
        e.stopPropagation();

        navigate(`/userEdit/${id}`);
    };

    const openSettings = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        
        navigate(`/profile/settings/${id}`);
    };

    useEffect(() => {
        if (!ready) {
            initFunc();

            return
        }

        initFunc();
    }, [ready, sort]);

    useEffect(() => {
        if (filterApplying) {
            initFunc();
            setFilterApplying(false);
        }
    }, [filterApplying])

    return (
        <div className="profileSection usersPage" style={{ marginBottom: "0px" }}>
            <div className="prFlexRow profileSectionHeader">
                <h2>
                    {t("users_all")}
                    <div className="infoHelp"> <Hint align='left' item={hint[30]}/></div>

                </h2>
                {currentUser && checkRole(currentUser,'superAdmin') && <button className="prButton prButtonMain" onClick={createClick}>
                    {t("users_create")}
                </button>}
                <button className="prButton prButtonSecond prFlexRow roomFilterButton roomFilterMobileButton" onClick={() => setIsOpen(true)}>
                    <Icon type="filter" /> {t("filter_show")}
                </button>
            </div>

            <div className="filterBlock">
                <div className="prFlexRow">
                    <div className="prFlexRow">
                        <div className="prFlexRow filterBlockFilter">
                            <div className="filterBlockSortText">{t("filter_period")}:</div>
                            <DatePicker id="dataBirthdayFrom" value={(filter.dataBirthdayFrom?.length>1 && getDate(filter.dataBirthdayFrom,'dd/mm/yyyy','.')) || ''} onChange={changeHandler} onBlur={initFunc} icon={true} />
                            <div>-</div>
                            <DatePicker id="dataBirthdayTo" value={(filter.dataBirthdayTo?.length>1 && getDate(filter.dataBirthdayTo,'dd/mm/yyyy','.')) || ''} onChange={changeHandler}  onBlur={initFunc} icon={true} />
                        </div>
                        <div className="filterBlockSort prFlexRow">
                            <div className="filterBlockSortText">{t("filter_sort")}:</div>
                            <Sort
                                page="usersPageSort"
                                onChange={onSortValueChange}
                                sort={sort}
                                sortOptions={sortOptions}
                                sortOption={sortValue}
                            />
                        </div>
                    </div>
                    <div>
                        <button className="prButton prButtonSecond prFlexRow roomFilterButton" onClick={() => setIsOpen(true)}>
                            <Icon type="filter" /> {t("filter_show")}
                        </button>
                    </div>
                </div>
                <div className="filterBlockFilteredText">
                    {t("filter_find")} {user.totalFilter} {t("filter_all")} {user.total}
                </div>
            </div>

            {currentUser && checkRole(currentUser, 'superAdmin') && (
                <button className="prButton prButtonMain createUserButtonMobile" onClick={createClick}>
                    {t("users_create")}
                </button>
            )}

            {ready && (
                <>
                    <div className="userList">
                        {user.list?.length > 0 &&
                            user.list.map((el) => (
                                <div className="userItem pointer" key={el.id} onClick={(e) => openSettings(e, el.id)}>
                                    <div className="headerUserAvatar" style={{backgroundImage:`url(${el?.avatarImg})`}}>
                                        {!el?.avatarImg && <Icon type="avatar" />}
                                        <div
                                            className={
                                                (el?.reputation > 6 && "headerUserAvatarReputation") ||
                                                ((el?.reputation < 7 && el?.reputation > 3) && "headerUserAvatarReputation caution") ||
                                                (el?.reputation < 4 && "headerUserAvatarReputation error")
                                            }
                                        >
                                            {el.reputation}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="userItemNick">
                                            <span
                                                style={{
                                                    maxWidth: '100px',
                                                    width: 'fit-content',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {el.nickname}
                                            </span>
                                            <Icon
                                                type="edit"
                                                onClick={(e) => editClick(e, el.id)}
                                            />
                                        </div>
                                        <div className="prFlexRow statistic">
                                            <div className="prFlexRow white">
                                                <Icon type="playController" width="17px" />
                                                <div className="statisticValue">&nbsp;: {el?.statistics.total || '0'}</div>
                                            </div>
                                            <div className="prFlexRow green">
                                                <Icon type="win" width="9px" />
                                                <div className="statisticValue">&nbsp;: {el?.statistics.win || '0'}</div>
                                            </div>
                                            <div className="prFlexRow red">
                                                <Icon type="loose" width="9px" />
                                                <div className="statisticValue">&nbsp;: {el?.statistics.loss || '0'}</div>
                                            </div>
                                            <div className="prFlexRow orange">
                                                <Icon type="draft" width="5px" />
                                                <div className="statisticValue">&nbsp;: {el?.statistics.draw || '0'}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>

                    {user.totalFilter>user.limit&& <Pagination
                        className="pagination-bar"
                        currentPage={pageCur}
                        totalCount={user.totalFilter}
                        pageSize={user.limit}
                        onPageChange={(page) => {
                            setPageCur(page);
                            initFunc(page);
                        }}
                    />}

                    {isOpen && <Filter type='users' savefilter={savefilter} resetFilter={resetFilter} accept={applyFilter} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}
                </>
            )}
        </div>
    );
};

export default UsersPage;
