import {
  GETUSERDETAIL_FAIL,
  GETUSERDETAIL_SUCCESS,
  GETCURRENTUSERDETAIL_SUCCESS,
  GETCURRENTUSERDETAIL_FAIL,
  GETUSERSLIST_FAIL,
  GETUSERSLIST_SUCCESS,
  CREATEUSER_FAIL,
  CREATEUSER_SUCCESS,
  EDITUSER_SUCCESS,
  EDITUSER_FAIL,
  ERROR,
  GETUSERREPUTATION_SUCCESS,
  GETUSERREPUTATION_FAIL,
  CHANGEUSERPASSWORD_SUCCESS,
  CHANGEUSERPASSWORD_FAIL,
  GETUSERSSEARCH_SUCCESS,
  GETUSERSSEARCH_FAIL,
} from "./types";
  
import UserService from "../services/user.service";

export const getUserDetail =(form)=>(dispatch)=>{
  return UserService.getUserDetail(form).then( (response) => {
    if(response.statusText!="OK" || response.data.errors){
      dispatch({
        type: GETUSERDETAIL_FAIL,
        payload: response.data,
      });
    return response.data;
    }else{
      dispatch({
        type: GETUSERDETAIL_SUCCESS,
        payload: response.data,
      });
      return  response.data;
    }
  },
  (error) => {
    dispatch({
      type: GETUSERDETAIL_FAIL,
    });
    dispatch({
      type: ERROR,
      message:error.message
    });
    console.log(error);
    return Promise.reject();
  }
  );
}

export const getUsersSearch = (form, url) => (dispatch) => {
  return UserService.getUsersListSearch(form, url)
    .then( response => {
      return response
    })
    .catch( error => {
      dispatch({
        type: ERROR,
        message:error.message
      });
      console.log(error);
      return Promise.reject();
    })
}

export const setUser =(form)=>(dispatch)=>{
        dispatch({
          type: GETCURRENTUSERDETAIL_SUCCESS,
          payload: {user:form}
        });
}

export const getUserReputation =(form)=>(dispatch)=>{
  return UserService.getUserReputation(form).then( (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: GETUSERREPUTATION_FAIL,
          payload: response.data,
        });
      return response.data;
      }else{
        dispatch({
          type: GETUSERREPUTATION_SUCCESS,
          payload: response.data,
        });
        return  response.data;
      }
    },
    (error) => {
      dispatch({
        type: GETUSERREPUTATION_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
    console.log(error);
      return Promise.reject();
    }
  );
}

export const getNicknames =(form)=>(dispatch)=>{
    return UserService.getNicknames(form).then( (response) => {
        if(!response|| response.errors){
          dispatch({
            type: GETUSERSLIST_FAIL,
            payload: response.data,
          });
        return response.data;
        }else{
          response.users=response.all
          dispatch({
            type: GETUSERSLIST_SUCCESS,
            payload: response,
          });
          return  response;
        }
      },
      (error) => {
        dispatch({
          type: GETUSERSLIST_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
}

export const getCurrentUserDetail =(form)=>(dispatch)=>{
    return UserService.getUserDetail(form).then( (response) => {
        if(response.statusText!="OK" || response.data.errors){
          dispatch({
            type: GETCURRENTUSERDETAIL_FAIL,
            payload: response.data,
          });
        return response.data;
        }else{
          dispatch({
            type: GETCURRENTUSERDETAIL_SUCCESS,
            payload: response.data,
          });
          return  response.data;
        }
      },
      (error) => {
        dispatch({
          type: GETCURRENTUSERDETAIL_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
}

export const getUsersList=(form)=>(dispatch)=>{
    return UserService.getUsersList(form).then( (response) => {
        if(response.statusText!="OK" || response.data.errors){
          dispatch({
            type: GETUSERSLIST_FAIL,
            payload: response.data,
          });
        return response.data;
        }else{
          dispatch({
            type: GETUSERSLIST_SUCCESS,
            payload: response.data,
          });
          return  response.data;
        }
      },
      (error) => {
        dispatch({
          type: GETUSERSLIST_FAIL,
        });
        dispatch({
          type: ERROR,
          message:error.message
      });
      console.log(error);
        return Promise.reject();
      }
    );
}

export const createUser=(form)=>(dispatch)=>{
  return UserService.createUser(form).then( (response) => {
        dispatch({
          type: CREATEUSER_SUCCESS,
          payload: response.user,
        });
        return  response.user;
    },
    (error) => {
      dispatch({
        type: CREATEUSER_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
    console.log(error);
      return Promise.reject();
    }
  );
}

export const editUser=(form)=>(dispatch)=>{
  return UserService.editUser(form).then( (response) => {
      if(response.statusText!="OK" || response.data.errors){
        dispatch({
          type: EDITUSER_FAIL,
          payload: response.user,
        });
        return response.user;
      }else{
        dispatch({
          type: EDITUSER_SUCCESS,
          payload: response.user,
        });
        return  response.user;
      }
    },
    (error) => {
      dispatch({
        type: EDITUSER_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
      console.log(error);
      return Promise.reject();
    }
  );
}

export const getReputation=(form)=>(dispatch)=>{
  return UserService.getReputation(form).then( (response) => {
        
        return  response;
     
    },
    (error) => {
      dispatch({
        type: EDITUSER_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
      console.log(error);
      return Promise.reject();
    }
  );
}
export const editReputation=(form)=>(dispatch)=>{
  return UserService.editReputation(form).then( (response) => {
        
        return  response;
     
    },
    (error) => {
      dispatch({
        type: EDITUSER_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
      console.log(error);
      return Promise.reject();
    }
  );
}

export const sendVerification=(form)=>(dispatch)=>{
  return UserService.sendVerification(form).then( (response) => {
        return  response;
    },
    (error) => {
      dispatch({
        type: EDITUSER_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
      console.log(error);
      return Promise.reject();
    }
  );
}

export const userEvent=(form)=>(dispatch)=>{
  return UserService.userEvent(form).then( (response) => {
        return  response;
    },
    (error) => {
      dispatch({
        type: EDITUSER_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
      console.log(error);
      return Promise.reject();
    }
  );
}

export const getVerification=(form)=>(dispatch)=>{
  return UserService.getVerification(form).then( (response) => {
        return  response;
    },
    (error) => {
      dispatch({
        type: GETUSERSLIST_FAIL,
      });
      dispatch({
        type: ERROR,
        message:error.message
    });
      console.log(error);
      return Promise.reject();
    }
  );
}

export const changeUserPassword = (form) => (dispatch) => {
  return UserService.changePassword(form).then( (response) => {
    if(response.status != "OK" || response.data.errors) {
      dispatch({
        type: CHANGEUSERPASSWORD_FAIL,
      });

      return response;
    } else {
      dispatch({
        type: CHANGEUSERPASSWORD_SUCCESS,
      });

      return response;
    }
  },
  (error) => {
    dispatch({
      type: CHANGEUSERPASSWORD_FAIL,
    });
    dispatch({
      type: ERROR,
      message:error.message
    });
    console.log(error);
    return Promise.reject();
  }
);
}










  


  
  
  