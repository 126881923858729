import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../../components/Icon";
import { register, registerCode, setMessage } from "../../../redux/actions/auth";
import { removeRestrictedCharacters } from "../../../helpers/removeRestrictedCharacters";
import { formatTime } from '../../../helpers/formatTime';

const RegistrationPage = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [checked, setChecked] = useState(false);
    const [form, setForm] = useState({});
    const [validForm, setValidForm] = useState({});
    const [errors, setErrors] = useState({});
    const [showRepeat, setShowRepeat] = useState(false);
    const [timer, setTimer] = useState(0);
    const [isTimerActive, setIsTimerActive] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const changeHandler = (event) => {
        const { id, value } = event.target;
        let sanitizedValue = removeRestrictedCharacters(value);

        if (event.target.type === 'password' && sanitizedValue !== value) {
            dispatch(setMessage({
                message: 'Введено заборонений символ "<, >, /, \\, [, ], $, ; *"',
                type: 'error'
            }))
        }

        setForm({ ...form, [id]: sanitizedValue });
    };

    const checkHandler = (event) => {
        const emailCheck =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const passwordCheck = /^(?=.*\d)(?=.*?[a-z])(?=.*?[A-Z]).{8,30}$/;
        const { id, value } = event.target;

        switch (id) {
            case "email":
                if (emailCheck.test(String(value).toLowerCase())) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_email") });
                }
                break;
            case "nickname":
                if (value.length >= 3) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_nickname") });
                }
                break;
            case "code":
                if (value.length >= 6) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_code") });
                }
                break;
            case "password":
                if (passwordCheck.test(String(value))) {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                } else {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("invalid_password") });
                }
                break;
            case "passwordRepeat":
                if (value !== form.password) {
                    setValidForm({ ...validForm, [id]: false });
                    setErrors({ ...errors, [id]: t("password_mismatch") });
                } else {
                    setValidForm({ ...validForm, [id]: true });
                    setErrors({ ...errors, [id]: "" });
                }
                break;
            default:
                setValidForm({ ...validForm, [id]: true });
                setErrors({ ...errors, [id]: "" });
        }
    };

    const registerHandler = (event) => {
        dispatch(register(form))
            .then((res) => {
                dispatch(setMessage({
                    message: 'Вас успішно зареєстровано',
                    type: 'success',
                  })).then(ress=>{
                    navigate('/login');
                })
            })
            .catch((e) => {
                // handle error
            });
    };
    const sendCode=()=>{
        if(!form.email){
            setValidForm({ ...validForm, 'email': false });
            setErrors({ ...errors, 'email':  t("invalid_email") });
        }else{
            dispatch(registerCode({'email':form.email}))
            .then((res) => {
                setTimer(60);
                setIsTimerActive(true);
            })
            .catch((e) => {
                if (e.time) {
                    setTimer(e.time);
                    setIsTimerActive(true);
                }
            });
        }
        
    }

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setIsTimerActive(false);
        }
    }, [timer]);

    const hasErrors = Object.values(errors).some(error => error !== "");
    const allFieldsFilled = ["email", "nickname", "password", "passwordRepeat", "code"].every(field => form[field]);

    return (
        <div className="prFullHeight">
            <div className="prHeaderAuth"> {t("auth_registration" )} 
                <div className="infoHelp"><Icon type='info'/></div>
            </div>
            <div className="prInput">
                <label htmlFor="email" className={errors.email ? "prInputLabel inputError" : "prInputLabel"}>{t("email")}</label>
                <input
                    onChange={changeHandler}
                    onBlur={checkHandler}
                    value={form.email || ''}
                    id="email"
                    type="text"
                    className={errors.email ? "prInputInput inputError" : "prInputInput"}
                    placeholder={t("mail")}
                />
                {errors.email && <div className="error">{errors.email}</div>}
            </div>

            <div className="prInput">
                <label htmlFor="code" className={errors.code ? "prInputLabel inputError" : "prInputLabel"}>{t("register_code")}</label>
                <div className="prFlexRow" style={{ gap: "10px" }}>
                    <button
                        className="prButton prButtonMain prButtonhalfAuth"
                        onClick={sendCode}
                        disabled={isTimerActive}
                    >
                        {!isTimerActive ? t('register_code_tooltip') : formatTime(timer)}
                    </button>
                    <div className="halfWidth">
                        <input maxLength={6} onChange={changeHandler} onBlur={checkHandler} id="code" type={'text'} className={errors.code ? "prInputInput inputError" : "prInputInput"} placeholder={t("register_code")} />
                    </div>
                </div>
                {errors.code && <div className="error">{errors.code}</div>}
            </div>

            <div className="prInput">
                <label htmlFor="nickname" className={errors.nickname ? "prInputLabel inputError" : "prInputLabel"}>{t("nickname")}</label>
                <input
                    onChange={changeHandler}
                    onBlur={checkHandler}
                    value={form.nickname || ''}
                    id="nickname"
                    type="text"
                    className={errors.nickname ? "prInputInput inputError" : "prInputInput"}
                    placeholder={t("nickname")}
                />
                {errors.nickname && <div className="error">{errors.nickname}</div>}
            </div>
            <div className="prInput prInputIcon">
                <label htmlFor="password" className={errors.password ? "prInputLabel inputError" : "prInputLabel"}>{t("password")}</label>
                <input
                    minLength={8}
                    maxLength={30}
                    onChange={changeHandler}
                    onBlur={checkHandler}
                    value={form.password || ''}
                    id="password"
                    type={show ? 'text' : "password"}
                    className={errors.password ? "prInputInput inputError" : "prInputInput"}
                    placeholder={t("password")}
                />
                <div className="prInputIconIcon" onClick={() => setShow(!show)}><Icon type={show ? 'eyeOpen' : 'eyeClosed'} /></div>
                {errors.password && <div className="error">{errors.password}</div>}
            </div>
            <div className="prInput prInputIcon">
                <label htmlFor="passwordRepeat" className={errors.passwordRepeat ? "prInputLabel inputError" : "prInputLabel"}>{t("password_repeat")}</label>
                <input
                    minLength={8}
                    onChange={changeHandler}
                    onBlur={checkHandler}
                    value={form.passwordRepeat || ''}
                    id="passwordRepeat"
                    type={showRepeat ? 'text' : "password"}
                    className={errors.passwordRepeat ? "prInputInput inputError" : "prInputInput"}
                    placeholder={t("password_repeat")}
                />
                <div className="prInputIconIcon" onClick={() => setShowRepeat(!showRepeat)}><Icon type={showRepeat ? 'eyeOpen' : 'eyeClosed'} /></div>
                {errors.passwordRepeat && <div className="error">{errors.passwordRepeat}</div>}
            </div>
            <div className="checkInput prFlexRow" style={{ marginTop: '9px', marginBottom: '40px' }}>
                <div onClick={() => setChecked(!checked)} className={checked ? "prCheckBox checked" : "prCheckBox"} >
                </div>
                <div style={{ textAlign: 'left' }} onClick={() => setChecked(!checked)}>
                    {t("agree_with")} <a href="#" className="link"> {t("rules_web")}</a>,
                    <a href="#" className="link"> {t("confidential")}</a> {t("and")}
                    <a href="#" className="link"> {t("offer")}</a>
                </div>
            </div>
            {/*Temporary removed oath block*/}
            {/* <div className="prFlexRow authProvider" style={{gap:'24px', marginTop:'40px',marginBottom:'40px'}}>
                <div className="pointer"><Icon type='google'></Icon></div>
                <div className="pointer"><Icon type='fbAuth'></Icon></div>
            </div> */}
            <button className="prButton prButtonMain" disabled={!checked || form.password !== form.passwordRepeat || hasErrors || !allFieldsFilled} onClick={registerHandler} style={{ marginBottom: '49px', marginTop: '0px' }}>{t("register")}</button>
            <div className="prLineAuth"></div>
            <div className="prLinkAuth"> 
                <div style={{marginBottom:'14px'}}>{t("have_acc")}</div>
                <a onClick={() => navigate('/login')} className="link"> {t("auth")}</a>
            </div>
        </div>
    )
}

export default RegistrationPage;
