import React, { useEffect, useState } from 'react';
import UseRouts from './routes';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import HeaderAuth from './components/Header/HeaderAuth';
import { socket } from './socket';
import { setRoomList } from './redux/actions/room';
import { getCurrentUserDetail, setUser } from './redux/actions/user';
import { getSystemSettings } from './redux/actions/system';
import { getFullList } from './redux/actions/dictionaries';
import { errorFree } from './redux/actions/auth';
import { useTranslation } from 'react-i18next';
import SmallModal from './components/SmallModal/SmallModal';
import { getHints } from './redux/actions/hints';
import { useLocation } from 'react-router-dom';



function App() {
  const auth = useSelector((state) => state.auth);
  const {message, type} = useSelector((state) => state.error);
  const [ready, setReady] = useState(false);
  const [topic, setTopic] = useState('black');
  const [errorBack, setErrorBack] = useState('');
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);
  const [itemList, setItemList] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const errorClose =()=>{
    dispatch(errorFree()).then(res=>{
      setErrorBack('')
    })
  }
  useEffect(() => {
    if(message?.length>0){
      setErrorBack(message);
    }
  }, [message]);

  useEffect(() => {
    if(localStorage.getItem('Theme')){
      setTopic(localStorage.getItem('Theme'));
    }else{
      setTopic('black');
      localStorage.setItem('Theme','black')
    }
  }, []);

  useEffect(() => {
    function onConnect() {
      console.log('connected');
      setIsConnected(true);
    }
    function onDisconnect() {
      console.log('disconnected');
      setIsConnected(false);
    }
    function onFooEvent(value) {
      console.log('foo');
      console.log(value);
      setFooEvents((previous) => [...previous, value]);
    }
    function onItemList(data) {
      // console.log('Received item list:', data);
      dispatch(setRoomList(data));
      setItemList(data);
    }
    function onСurrentUser(data) {
      // console.log('Received item list:', data);
      dispatch(setUser(data));
      setItemList(data);
    }

    
    function onError(data){
      alert(data);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('foo', onFooEvent);
    socket.on('roomsList', onItemList);
    socket.on('currentUser', onСurrentUser);
    socket.on('error', onError);

    
    


    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('foo', onFooEvent);
    };
  }, []);

  useEffect(() => {
    if (!ready) {
      dispatch(getSystemSettings()).then(res => {
      });
      dispatch(getHints()).then(res => {
      });

      dispatch(getFullList()).then(res=>{
        setReady(true);
      })
    }
  }, []);

  useEffect(() => {
    if (auth.isLoggedIn ) {
      socket.emit('userLoggedIn', { isLoggedIn: auth.isLoggedIn, accessToken:auth.user.accessToken });
      socket.emit('getMyRooms',{});
      dispatch(getCurrentUserDetail({id:auth.user.user.id}))
    } else {
      socket.emit('userLoggedOut', { isLoggedIn: auth.isLoggedIn });
    }
  }, [auth.isLoggedIn,isConnected]);

  return (
    <div className={topic=='white'?"App":"App DarkTopic"} >
      {!auth.isLoggedIn && <Header  topic={topic} setTopic={setTopic} isConnected={isConnected}/>}
      {auth.isLoggedIn && location.pathname !== '/conditions' && <HeaderAuth  topic={topic} setTopic={setTopic} isConnected={isConnected}/>}
      <div className={auth.isLoggedIn?"main auth":"main"}>
        {ready && <UseRouts  isLoggedIn={auth.isLoggedIn}/>}
        {/* <UseRouts  isLoggedIn={auth.isLoggedIn}/> */}
      </div>
      {location.pathname !== '/conditions' && <Footer />}

      {errorBack && 
        <SmallModal text={errorBack} class={type} setSuccess={errorClose}></SmallModal>
      }
      
    </div>
  );
}

export default App;
