import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../redux/actions/file";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Icon } from "../Icon";
import Hint from "../Hint/Hint";
import { setMessage } from "../../redux/actions/auth";

function FileUploadAndCrop(props) {
  const [loading, setLoading] = useState(false);
  const hint = useSelector((state) => state.hints);
  const [croppedImage, setCroppedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [resize, setResize] = useState(true);
  const [fileInfo, setFileInfo] = useState(null);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const cropperRef = useRef(null);


  const inputPut = (e) => {
  console.log('Cropper options inputPut:', props.options);
    const _file = e.target?.files[0];
    getFileContext(_file);

  };

  const getFileContext = (e) => {
    const _file = e;
    if (!_file) {
      return;
    }

    if (_file.size > (props.size || 10) * 1000000) {
      dispatch(setMessage({
        message: `Максимальний розмір до ${props.size || 10}МБ`,
      })).then(ress=>{
        return;
      })
    }
      console.log(_file);
      setEdit(true);
      let src = URL.createObjectURL(_file);
      setFileInfo(_file);
      setFile(src);
  };

  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const croppedImage = new File([blob], 'new-image.jpg', { type: 'image/jpeg', lastModified: new Date().getTime() });
      setCroppedImage(croppedImage);
    }, 'image/jpeg');
  };

  const saveCropChange = () => {

    if (!croppedImage) {
      dispatch(setMessage({
        message: 'Будь ласка, оберіть область фото для обрізки',
      })).then(ress=>{
        return;
      })
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      const maxDimension = 1600;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxDimension) {
          height *= maxDimension / width;
          width = maxDimension;
        }
      } else {
        if (height > maxDimension) {
          width *= maxDimension / height;
          height = maxDimension;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        if (!blob) {
          dispatch(setMessage({
            message: 'Помилка при створенні Blob зображення',
          })).then(ress=>{
            return;
          })
        }

        let compressedImage = new File([blob], croppedImage.name, {
          type: 'image/jpeg',
          lastModified: new Date().getTime()
        });

        let formData = new FormData();
        formData.append("originalFileName", compressedImage.name);
        formData.append("size", compressedImage.size);
        formData.append("chunkIndex", 1);
        formData.append("totalChunks", 1);
        formData.append("file", compressedImage);
        formData.append("uploadId", `${compressedImage.name}_${compressedImage.size}`);

        setLoading(true);
        dispatch(uploadFile(formData)).then(response => {
          const data = response.data;
          if(data.message=='Ready'){
            let res = { ...props.form };
            if (props.options.type === 'avatarImg') {
              res.avatarImg = data.url;
            } else if (props.options.type === 'coverImg') {
              res.coverImg = data.url;
            }
            console.log(res);
            props.saveEdit(res);
            props.setForm({ ...res });
            setLoading(false);
            setEdit(false);
          }
        });
      }, 'image/jpeg', 1);
    };

    const objectURL = URL.createObjectURL(croppedImage);
    img.src = objectURL;
  };

  return (
    <>
      
        <div className="profilePhotoImageAndChangeBlock">
          {edit && (
            <div className="overlay">
              <div className="sidebar">
                <h3 className="prFlexRow">
                  <h2 className="">
                    Повідомлення
                    <div className="infoHelp">
                      <Hint align='center' item={hint[53]}/>
                    </div>
                  </h2>
                  <div className="prFlexRow sidebarFilterButtons"> 
                    <div onClick={() => { setEdit(false); }}><Icon type='close'></Icon></div>
                  </div>
                        </h3>
                <div className="sidebarBody">
                  {!loading ? (
                    <Cropper
                      src={file}
                      style={{ height: props.options?.height || "176px", width: props.options?.width || "176px" }}
                      initialAspectRatio={props.options.initialAspectRatio}
                      aspectRatio={props.options.aspectRatio}
                      autoCropArea={props.options.autoCropArea}
                      guides={false}
                      viewMode={1}
                      dragMode="move"
                      scalable={resize}
                      cropBoxResizable={resize}
                      cropBoxMovable={resize}
                      crop={onCrop}
                      ref={cropperRef}
                    />
                  ) : <>Loading</>}
                  <div className="userEditButtons" style={{marginTop:'40px'}}>
                    <button onClick={() => { setEdit(false); }} className="prButton prButtonSecond">Відхилити</button>
                    <button onClick={saveCropChange} className="prButton prButtonMain" >Зберегти</button>
                  </div>
                </div>
              </div>
            </div>
          )}
            <>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .gif"
                id={props.options.type}
                style={{ display: "none" }}
                onChange={inputPut}
                tabIndex="0"
              />
                <label
                  htmlFor={props.options.type}
                  className="secondaryButtonIconTextDefault label"
                  tabIndex="0"
                >
                  <Icon type='photoUpload'></Icon>
                </label>
            </>
          
        </div>
      

    </>
  );
}

export default FileUploadAndCrop;
