import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CreateRoom from "../../../components/CreateRoom/CreateRoom";
import Filter from "../../../components/Filter/Filter";
import { Icon } from "../../../components/Icon";
import TimerPR from "../../../components/TimerPR/TimerPR";
import checkRole from "../../../helpers/checkRole";
import getDate from "../../../helpers/getDate";
import getWordEnding from "../../../helpers/getWordEnding";
import { getRoomsList } from "../../../redux/actions/room";
import { socket } from "../../../socket";
import Sort from "../../../components/Sort/Sort";

const sortOptions = [
    { id: "createdAt", value: "За датою створення" },
];

const RoomsGameItemPage=(props)=>{
    const { t } = useTranslation();
    const location = useLocation();
    const { userConsent } = location.state || {};
    const roomsList=useSelector(state=>state?.room?.roomsList);
    const user = useSelector((state) => state.currentUser?.user);
    const list=useSelector(state=>state?.list)
    const navigate=useNavigate();
    const dispatch = useDispatch();
    const [isOpenCreate, setIsOpenCreate] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [sort,setSort]=useState('desc');
    const [sortValue,setSortValue]=useState('createdAt');
    const [ready,setReady]=useState(false);
    const [filterCreate, setFilterCreate] = useState({});
    const [filter, setFilter] = useState({});
    const [consent, setConsent] = useState(userConsent);
    const [filterApplying, setFilterApplying] = useState(false);


    const resetFilter=()=>{
        setFilter({});
        setFilterApplying(true);
    }

    const applyFilter = () => {
        setFilterApplying(true);
    }
    
    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilterCreate = { ...filter};

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilterCreate[e.target.accessKey] = value;
        }
        else if (value.length<1) {
            delete updatedFilterCreate[id];
        }
        else {
            updatedFilterCreate[id] = value;
        }
        setFilter(updatedFilterCreate);
    };

    const initFunc = () => {
        const sortData = {
            sortField: sortValue,
            sortOrder: sort,
        }

        dispatch(getRoomsList({gameId:props.game.id, status:'20ba716e-3597-4751-84b3-8fdf453cd001',...filter, ...sortData})).then(res=>{
        })
    };

    const onCreateRoom = () => {
        if (!consent) {
            navigate('/conditions', { state: { fallback: location.pathname } })
        } else {
            setIsOpenCreate(true)
        }
    }

    const onSortValueChange= (sortOption) => {
        setSortValue(sortOption.id);
        setSort(sortOption.sort);
        localStorage.setItem('gameRoomPageSort', JSON.stringify({id: sortOption.id, sort: sortOption.sort}));
    }

    const selectClick=(e)=>{
        e.preventDefault();
        let arr = [];

        if( filterCreate.platform?.length>0){
            arr=[...filterCreate.platform]
        }
        let el = e.target.id;
        if (arr.indexOf(el) !== -1) {
            arr = arr.filter(item => item !== el);
        } else {
            arr.push(el);
        }
        setFilterCreate({
            ...filterCreate,
            platform: arr
        });
    }
    const wasCreated=(value)=>{
        navigate('/room/'+value);
    }

    socket.on('wasCreated',wasCreated);

    useEffect(() => {
        initFunc();
        const intervalId = setInterval(initFunc, 10000);
        return () => clearInterval(intervalId);
    }, [ready]);

    useEffect(() => {
        if (filterApplying) {
            initFunc();
            setFilterApplying(false);
        }
    }, [filterApplying])

    useEffect(() => {
        initFunc()
    }, [sort])

    useEffect(() => {
        const { openSidebar } = location.state || {};

        if (openSidebar) {
            setIsOpenCreate(true);

            navigate(location.pathname, { replace: true, state: {} });
        }
    }, [])


    return(<>
    <div className="prFlexRow gamePage" style={{marginBottom:'40px'}}>
        <div className="gameListInfo">
            На цій сторінці ви бачите лише кімнати, створені в межах гри <strong>“{props.game.name}”</strong>.  Тут відображаються лише кімнати зі статусом “<strong>Пошук учасників</strong>”. Кімнати із іншими статусами, де ви є учасникм чи організатором можна знайти в особистому кабінеті в розділі <a onClick={()=>navigate('/profile/rooms')}>Кімнати</a>
        </div>
        <div className="gameListSeparator" />
        <button
            className="prButton prButtonMain"
            style={{gap:'5px', fill:"white"}}
            disabled={(user && checkRole(user, 'arbitr'))}
            onClick={onCreateRoom}
        >
            {t('create_room')}
        </button>
    </div>
    <div className="filterBlock prFlexRow" style={{width:'100%'}}>
        <div className="prFlexRow">
            <div className="prFlexRow"> 
                <div>Знайдено {roomsList?.length} {getWordEnding(roomsList?.length)}</div>
                <div className="filterBlockSort prFlexRow">
                    <div className="filterBlockSortText">{t("filter_sort")}:</div>
                    <Sort 
                        sort={sort}
                        sortOptions={sortOptions}
                        sortOption={sortValue}
                        page="gameRoomPageSort"
                        onChange={onSortValueChange}
                    />
                </div>
            </div>
        </div>
        <div>
            <button className="prButton prButtonMain prButtonSecond prFlexRow roomFilterButton" onClick={() => setIsOpen(true)}>
                <Icon type="filter" /> {t("filter_show")}
            </button>
        </div>
    </div>
    <div className="profileSection">
    <div className="roomList">
        {roomsList?.length>0&& roomsList.map(el=>{
            return(
                <div className="roomItem prFlexRow actualRoomsBlock">
                    <div className="roomItemTabletBlockHeader prFlexRow">
                        <div className="roomItemLink" style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+el.id)}>{el.id}</div>
                        <div className="prFlexRow">
                            <div className="roomItemBlockValue"><Icon type='time'></Icon>   {getDate(el.createdAt.replace('T',' '),'hh:mm',":")}</div>
                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon> {getDate(el.createdAt.replace('T',' '),'dd/mm/yyyy',".")}</div>
                            <div className="roomItemBlockValue">{list[el.status]}</div>
                        </div>
                    </div>
                    <div className="roomItemMobileBlockHeader">
                        <div>
                            <div className="roomItemBlockHeader">ID кімнати</div>
                            <div className="roomItemLink" style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+el.id)}>{el.id}</div>
                        </div>
                        <div className="roomItemMoney">
                            <div className="roomItemMoneyValue prFlexRow">{el.price} <Icon type='fTocken'></Icon></div>
                        </div>
                    </div>
                    <div className="roomItemLink" style={{cursor:'pointer'}} onClick={()=>navigate('/room/'+el.id)}>{el.id}</div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('game')}</div>
                        <div className="roomItemBlockValue">{el.game?.name}</div>
                    </div>
                    <div className="roomItemMobileTimeBlock">
                        <div className="roomItemIcon">
                            <Icon type='time'></Icon>
                        </div>
                        <div className="roomItemTime">
                            {<TimerPR initialSeconds={el.termExistence}></TimerPR>}
                        </div>
                    </div>
                    <div className="roomItemTime prFlexRow">
                        <div className="roomItemIcon">
                            <Icon type='time'></Icon>
                        </div>
                        <TimerPR initialSeconds={el.termExistence} />
                    </div>

                    <div className="roomItemMoney">
                        <div className="roomItemBlockHeader">{t('input_money')}</div>
                        <div className="roomItemMoneyValue prFlexRow">{el.price} <Icon type='fTocken'></Icon></div>
                    </div>
                    <div className="roomItemMobileBlock">
                        <div className="roomItemBlockHeader">{t('type')}</div>
                        <div className="roomItemBlockValue">{el.isPrivate?'Приватна':'Публічна'}</div>
                    </div>
                    <div className="roomItemIcon roomItemBlock dFlex platformIcon" style={{gap:'10px'}}>
                        <div className="roomItemBlockHeader">Платформа</div>
                        {el.platform.map(elem=>{
                            return(<> 
                                        {elem=='09ba716e-3597-4751-84b3-8fdf453cd072'&&
                                            <Icon type='pc'></Icon>
                                        }
                                        {elem=='09ba716e-3597-4751-84b3-8fdf453cd073' &&
                                        <Icon type='ps'></Icon>
                                        }
                                        {elem=='09ba716e-3597-4751-84b3-8fdf453cd071' &&
                                        <Icon type='xbox'></Icon>
                                        }
                            </>)
                        })}
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('format')}</div>
                        <div className="roomItemBlockValue">{el.format+' x '+el.format}</div>
                    </div>
                    <div className="roomItemTabletTimeBlock">
                        <div className="roomItemIcon">
                            <Icon type='time'></Icon>
                        </div>
                        <div className="roomItemTime">
                            {<TimerPR initialSeconds={el.termExistence}></TimerPR>}
                        </div>
                    </div>
                    <div className="roomItemMoney roomItemTabletMoney">
                        <div className="roomItemBlockHeader">{t('input_money')}</div>
                        <div className="roomItemMoneyValue prFlexRow">{el.price} <Icon type='fTocken'></Icon></div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('status')}</div>
                        <div className="roomItemBlockValue">{list[el.status]}</div>
                    </div>
                    <div className="roomItemBlock">
                        <div className="roomItemBlockHeader">{t('time_created')}</div>
                        <div className="prFlexRow"> 
                            <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(el.updatedAt.replace('T',' '),'hh:mm',':')}</div>
                            <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.updatedAt.replace('T',' '),'dd/mm/yyyy','.')}</div>
                        </div>
                    </div>
                </div>  
            )
        })}
        
    </div>
    </div>
    {isOpenCreate && <CreateRoom game={props.game} isOpen={isOpenCreate} setIsOpen={setIsOpenCreate} ></CreateRoom>}
    {isOpen && <Filter type='roomsFilter' selectClick={selectClick} game={props.game} needCheckedC={true} resetFilter={resetFilter} accept={applyFilter} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen} />}

    </>)
}

export default RoomsGameItemPage;