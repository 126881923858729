import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';

const getBlacklist = (body) => {
  const offset = body.offset || 0;
  const limit = body.limit || 10;

  let filter = `?offset=${offset}&limit=${limit}`;

  return axios
  .get(constants.URL + "black-list" + filter, { headers: authHeader()})
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return Promise.reject(e.response.data);
  });
};

const addBlacklistItem = (body) => {
  return axios
  .post(constants.URL + "black-list", body, { headers: authHeader() })
  .then((response) => {
    return response.data;
  }).catch((e)=>{
    return  Promise.reject(e.response.data);
  });
}

const deleteBlacklistItem = (body) => {
  return axios
  .delete(constants.URL + `black-list/${body.id}`, { headers: authHeader() })
  .then((response) => {
    return response.data;
  }).catch((e)=>{
    return  Promise.reject(e.response.data);
  });
}

export default {
  getBlacklist,
  addBlacklistItem,
  deleteBlacklistItem,
}