import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import getDate from "../../../helpers/getDate";
import { getSupportsList, editSupport } from "../../../redux/actions/support";
import { setMessage } from '../../../redux/actions/auth';

import Filter from "../../../components/Filter/Filter";
import { Icon } from "../../../components/Icon";
import Hint from "../../../components/Hint/Hint";
import checkRole from "../../../helpers/checkRole";
import { getUsersList } from "../../../redux/actions/user";

const mockAdmins = [
    {name: 'John Adminsky', id: '24234234234'},
    {name: 'Edward SuperAdminsky', id: '4554667567'},
    {name: 'Ivan Arbitrovich', id: '758678678678'},
]


const SupportListPage =()=>{
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const support = useSelector((state) => state.support);
    const [ready,setReady]=useState(false);
    const list=useSelector(state=>state?.list)
    const hint=useSelector(state=>state?.hints)
    const [filterApplying, setFilterApplying] = useState(false);
    const { user } = useSelector((state) => state.currentUser);
    
    const [isOpen, setIsOpen] = useState(false);
    const [isResponsibleOpen, setIsResponsibleOpen] = useState('');
    const [page,setPage]=useState(1);
    const [filter, setFilter] = useState(() => {
        const savedFilter = localStorage.getItem("supportPage");
        return savedFilter ? JSON.parse(savedFilter) : {};
    });

    const initFunc=(page)=>{
        let data = {};
        data.limit = 20;
        data.offset = (page - 1) * data.limit;
        data.filter = filter;
        data.sort={};
        dispatch(getSupportsList(data)).then(res=>{
            setReady(true);
        })

        if (user && checkRole(user, 'arbitr')) {
            let userRoles = {
                filter: {
                    role: [
                        '0b95c2cb-9ade-493a-999a-6aff5930c001',
                        '0b95c2cb-9ade-493a-999a-6aff5930c002',
                        '0b95c2cb-9ade-493a-999a-6aff5930c003'
                    ],
                }
            }

            dispatch(getUsersList(userRoles))
                .then(res => {
                })
        }
    }
    const createClick = () => {
        navigate(`/supportCreate`);
    };

    useEffect(() => {
        if(!ready && user){
            initFunc();
        }
        
    }, [ready, user]);

    const resetFilter=()=>{
        setFilter({});
        setFilterApplying(true);
    }

    const applyFilter = () => {
        localStorage.setItem("supportPage", JSON.stringify(filter));
        setFilterApplying(true);
    }

    const onSetResponsible = (req) => {
        const data = {
            id: req.id,
            responsible: user.id,
        }

        dispatch(editSupport(data))
            .then( res => {
                dispatch(setMessage({
                    type: 'success',
                    message: 'Відповідального успішно змінено',
                }))

                initFunc();
            })
    }

    const onDone = (req) => {
        const data = {
            id: req.id,
            status: "09ba716e-3597-4751-84b3-8fdf453cd013",
        }

        dispatch(editSupport(data))
            .then( res => {
                dispatch(setMessage({
                    message: 'Статус успішно змінено',
                    type: 'success',
                }));

                initFunc();
            })
    }

    const onResponsibleOpen = (id) => {
        isResponsibleOpen.includes(id) ? setIsResponsibleOpen('') : setIsResponsibleOpen(id);
    }

    const changeHandler = (e) => {
        const { id, value } = e.target;
        let updatedFilter = { ...filter };

        if (e.target.tagName === 'SELECT' && e.target.accessKey) {
            updatedFilter[e.target.accessKey] = value;
        }
        else if (id === "dateFrom" || id === "dateTo") {
            updatedFilter[id] = getDate(value, "yyyy/mm/dd", "-");
        } 
        else if (id === "all" && value.length<3 && value.length>0) {
           
        } 
        else if (value.length<1) {
            delete updatedFilter[id];
        } 
        else {
            updatedFilter[id] = value;
        }

        setFilter(updatedFilter);
    };

    useEffect(() => {
        if (filterApplying) {
            initFunc();
            setFilterApplying(false);
        }
    }, [filterApplying])

    return(<>
        <div className="profileSection supportListPage" style={{marginBottom:'0px'}}>
            <div className="prFlexRow profileSectionHeader" style={{marginBottom:'40px'}}>
                <h2 >{t('support')}
                    <div className="infoHelp">
                        <Hint align='left' item={hint[43]}/>
                    </div>
                </h2>
                <div className="prFlexRow gameSection" style={{gap:'15px'}}>
                    <button className="prButton prButtonMain" onClick={createClick}>
                            {t("support_create")}
                    </button>
                    <button className="prButton prButtonMain prButtonSecond prFlexRow roomFilterButton" onClick={() => setIsOpen(true)}>
                        <Icon type="filter" /> {t("filter_show")}
                    </button>
                </div>
            </div>

            <button className="prButton prButtonMain createRequestMobileButton" onClick={createClick}>
                            {t("support_create")}
            </button>

            <div className="roomList">
                {support?.list?.length>0 &&
                support?.list.map((el,index)=>{
                    return(
                    <div key={index} className="roomItem roomItemSupport prFlexRow">
                        <div className="roomItemLink2" style={{cursor:'pointer'}} onClick={()=>navigate('/supportRoom/'+el.id)}>
                            <div className="roomItemBlockHeader croppedText">{el.id}</div>
                            <div className="roomItemBlockValue croppedText">{el.message.substring(0, 30)}</div>
                        </div>
                        {checkRole(user, 'arbitr') && (
                            <>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">Відповідальний</div>
                                    <div className="roomItemBlockValue relative">
                                        {el?.responsibleUser?.nickname ? (
                                            <div
                                                className="flex pointer"
                                                style={{ gap: '10px', alignItems: 'center'}}
                                            >
                                                {el.responsibleUser.nickname}
                                                {(el.status !== "09ba716e-3597-4751-84b3-8fdf453cd013") && (
                                                <div onClick={() => onResponsibleOpen(el.id)}>
                                                    <Icon type="selectArrow" />
                                                </div>
                                                )}
                                                {isResponsibleOpen === el.id && (
                                                    <ul className={`multiselectOptions`}>
                                                    {
                                                        mockAdmins.length > 0 && mockAdmins.map(option => (
                                                        <li
                                                            key={option.id}
                                                            className="multiselectOption"
                                                            onClick={() => onSetResponsible(option)}
                                                        >
                                                            {option.name}
                                                        </li>
                                                        ))
                                                    }
                                                    </ul>
                                                )}
                                            </div>
                                        ) : (
                                            <button
                                            className="prButton responsible"
                                            onClick={() => onSetResponsible(el)}
                                            >
                                            {t('Взяти в роботу')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="roomItemBlock">
                                    <div className="roomItemBlockHeader">Користувач</div>
                                    <div className="roomItemBlockValue">{el.notificationUser?.nickname || ''}</div>
                                </div>
                            </>
                        )}
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">Статус запиту</div>
                            <div
                                className="flex pointer"
                                style={{ alignItems: 'center', gap: '10px' }}
                                onClick={() => onDone(el)}
                            >
                                <div className="roomItemBlockValue">
                                    {list[el.status]}
                                </div>
                                {el.status !== "09ba716e-3597-4751-84b3-8fdf453cd013" && (
                                <Icon
                                    type="accept"
                                />
                                )}
                            </div>
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">Відділ</div>
                            <div className="roomItemBlockValue">{list[el.department]}</div>
                        </div>
                        <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">Час оновлення</div>
                            <div className="prFlexRow dateTimeBlock"> 
                                <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(el.updatedAt?.replace('T',' '),'hh:mm',':')}</div>
                                <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.updatedAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                            </div>
                        </div>
                         <div className="roomItemBlock">
                            <div className="roomItemBlockHeader">{t('time_created')}</div>
                            <div className="prFlexRow dateTimeBlock"> 
                                <div className="roomItemBlockValue"><Icon type='time'></Icon> {getDate(el.createdAt?.replace('T',' '),'hh:mm',':')}</div>
                                <div style={{marginLeft:'18px'}} className="roomItemBlockValue"><Icon type='calendar'></Icon>{getDate(el.createdAt?.replace('T',' '),'dd/mm/yyyy','.')}</div>
                            </div>
                        </div>
                    </div>  )

                })
                }
            </div>
            {isOpen && 
                <Filter type='supportPage' resetFilter={resetFilter} accept={applyFilter} filter={filter} setFilter={setFilter} onChange={changeHandler} isOpen={isOpen} setIsOpen={setIsOpen}/>
            }
        </div>
    </>);
}

export default SupportListPage;