import React from "react";

const Switch = ({ id, onChange, checked = false }) => {
  return (
    <label class="switch">
      <input id={id} type="checkbox" checked={checked} onChange={onChange} className="switchCheckbox" />
      <span class="slider round" />
      <span className="switchLabel">Ні</span>
      <span className="switchLabel checked">Так</span>
    </label>
  )
};

export default Switch;