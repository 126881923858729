import React, { useEffect, useState, useRef } from "react";

import { Icon } from "../Icon";

const SearchBox =({
  id = '',
  filterId = '',
  placeholder = 'Почніть писати...',
  options = [],
  value = '',
  valueField = '',
  titleField = '',
  onChange = () => {},
  onSearch = () => {},
  returnWholeObj = false,
}) => {
  const [isOpen, setIsOpen] = useState();
  const inputRef = useRef(null);

  const toggleSelection = (option) => {
    const e = {
      target: {
        id: filterId,
        value: option[valueField],
        option: returnWholeObj && option,
      }
    };
    setIsOpen(prev => !prev);

    onChange(e);
  }

  const onInput = (e) => {
    const data = {
      id: id,
      value: e.target.value
    };

    onSearch(data);
  }

  const onClearValue = () => {
    const data = {
      target: {
        id: filterId,
        value: '',
      }
    }

    inputRef.current.value = '';

    onChange(data)
  }

  useEffect(() => {
    const handleClick = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        inputRef.current.value = '';
      }

      if (inputRef.current.value.length >= 3) {

        setIsOpen(true);
      }

      if (inputRef.current.value.length < 3) {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleClick);

    return () => {
      document.removeEventListener('keydown', handleClick);
    }
  }, [inputRef])

  return (
    <div className={`multiselect ${isOpen && 'focused'}`} tabIndex={0}>
      <div className="prInputInput">
        <div className="flex" style={{ gap: '10px', alignItems: 'center' }}>
          <Icon type="search"/>
          <input
            type="text"
            className="baseInput"
            ref={inputRef} 
            placeholder={placeholder}
            onChange={(e) => onInput(e)}
            value={value && options.find( option => option[valueField] === value)[titleField]}
          />
        </div>
        <Icon type="close" width="10px" onClick={onClearValue} />
      </div>
      {isOpen && options.length > 0 && (
        <ul className="multiselectOptions">
          {
            options.length > 0 && options.map(option => (
              <li
                key={option.id}
                className="multiselectOption"
                onClick={() => toggleSelection(option)}
              >
                {option[titleField] || ''}
              </li>
            ))
          }
        </ul>
      )}
    </div>
  )
}

export default SearchBox;