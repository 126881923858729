import axios from "axios";
import authHeader from "./auth-header";
import constants from '../../constants/constant.json';
import { socket } from "../../socket";



const getUserDetail=(body)=>{
  console.log( authHeader());
  return axios
  .get(constants.URL + "users/"+body.id,{ headers: authHeader()})
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return Promise.reject(e.response.data);
  });
}

const getUsersList=(body)=>{
  const offset = body.offset || 0;
  const limit = body.limit || 10;

  let filter = `?offset=${offset}&limit=${limit}`;

  if(body.filter&& Object.keys(body.filter).length ){
    for (let [key, value] of Object.entries(body.filter)) {
      if (Array.isArray(value)) {
        filter+=`&filters[${key}]=${JSON.stringify(value)}`
        // value.forEach( (elem, index) => {
        //   filter+=`&filters[${key}]=${elem}`
        // })
      } else {
        filter+=`&filters[${key}]=${value}`
      }
    }
  }
  if(body.sort&& Object.keys(body.sort).length ){
    for (let [key, value] of Object.entries(body.sort)) {
      filter+=`&sort[${key}]=${value}`
    }
  }
  return axios
  .get(`${constants.URL}users${filter}`, { headers: authHeader() })
  .then((response) => {
    return response;
  }).catch((e)=>{    
    return  Promise.reject(e.response.data);
  });
}

const getUsersListSearch = (data, url) => {
  let filterKey = Object.keys(data)[0];

  let filter = `?filters[${filterKey}]=${data[filterKey]}`

  return axios.get(constants.URL + `notification/${url}${filter}`, {headers: authHeader()})
  .then( res => {
    return res.data
  })
  .catch( e => {
    return Promise.reject(e.response.data)
  })
}

const createUser = () => {
  return axios
    .post(constants.URL + "users", {}, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};

const userEvent = (data) => {
  return axios
    .post(constants.URL + "user/"+data.id+'/'+data.type, data, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};



const editUser = (body) => {

  return axios
    .put(constants.URL + "users/"+body.id, body, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};

const getUserReputation = (form) => {
  return axios
    .post(constants.URL + "reputation", form, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};


const getNicknames = (body) => {
  const offset = body.offset || 0;
  const limit = body.limit || 10;
  let filter = `?offset=${offset}&limit=${limit}`;
  filter+='&filters[nickname]='+body.nickname;
  filter+='&filters[gameId]='+body.gameId;


  return axios
    .get(constants.URL + "nickname-platform"+filter, { headers: authHeader() })
    .then((response) => {
      
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};

const getReputation = (body) => {
  const offset = body.offset || 0;
  const limit = body.limit || 10;
  let filter = `?offset=${offset}&limit=${limit}`;

  return axios
    .get(constants.URL + "reputation/"+body.id+filter, { headers: authHeader() })
    .then((response) => {

      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};

const editReputation = (form) => {
  return axios
    .post(constants.URL + "reputation/"+form.id+'/'+form.type, form, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};



const sendVerification = (form) => {
  return axios
    .post(constants.URL + "verification", form, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};


const getVerification = (body) => {
  const offset = body.offset || 0;
  const limit = body.limit || 10;
  let filter = `?offset=${offset}&limit=${limit}`;

  return axios
    .get(constants.URL + "verification"+filter, { headers: authHeader() })
    .then((response) => {
      return response.data;
    }).catch((e)=>{
      return  Promise.reject(e.response.data);
    });
};

const changePassword = (body) => {
  return axios
    .post(constants.URL + 'auth/restore-password', body, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return Promise.reject(e.response.data);
    })
}

export default {
  getUserDetail,
  getUsersList,
  createUser,
  editUser,
  getUserReputation,
  getNicknames,
  getReputation,
  sendVerification,
  getVerification,
  userEvent,
  editReputation,
  changePassword,
  getUsersListSearch,
};








